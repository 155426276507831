import React from "react";
import messages from "utils/messages";
import { connect } from "react-redux";
import { update } from "actions/users";

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.user.name,
      errorsName: [],
      nameSaved: false,
      errorsPassword: [],
      passwordSaved: false,
    };
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  submitNameChange(event) {
    event.preventDefault();

    this.setState({ errorsName: [] });

    if (this.state.name.length < 3 || this.state.name.length > 50) {
      this.setState((prevState) => ({
        errorsName: [...prevState.errorsName, messages.E_NAME_LENGTH],
      }));
      return;
    }

    this.setState({ errorsName: [], nameSaved: true });

    this.props.dispatch(update({ name: this.state.name }));
  }

  submitPasswordChange(event) {
    event.preventDefault();
    this.setState({ errorsPassword: [] });

    if (this.passInput.value !== this.confirmpassInput.value) {
      this.setState((prevState) => ({
        errorsPassword: [...prevState.errorsPassword, messages.E_PW_MATCH],
      }));
      return;
    }
    if (this.passInput.value.length < 8) {
      this.setState((prevState) => ({
        errorsPassword: [
          ...prevState.errorsPassword,
          messages.E_PASSWORD_LENGTH,
        ],
      }));
      return;
    }

    this.setState({ errorsPassword: [], passwordSaved: true });

    this.props.dispatch(update({ password: this.passInput.value }));
  }

  render() {
    return (
      <div className="account">
        <div className="block">
          <i className="ion-ios-person-outline" />
          <h3>{messages.S_ACCOUNT}</h3>
          <a className="text">{messages.S_ACCOUNT_TEXT}</a>
          <div className="line" />
        </div>
        <div className="block">
          <div className="actions">
            <form
              className="change-name-form"
              onSubmit={this.submitNameChange.bind(this)}
            >
              <div className="form-row">
                <h4>{messages.S_CHANGE_NAME}</h4>
                {this.state.errorsName.length ? (
                  <ul className="errors">
                    {this.state.errorsName.map((error) => (
                      <li key={error}> {error} </li>
                    ))}
                  </ul>
                ) : null}
                <label>{messages.SET_NAME}</label>
                <input
                  ref={(input) => {
                    this.nameInput = input;
                  }}
                  className="name name-input"
                  type="name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleNameChange.bind(this)}
                />
                <button type="submit">{messages.S_SAVE}</button>
                {this.state.nameSaved ? (
                  <a className="saved-changes">{messages.S_SAVED}</a>
                ) : null}
              </div>
            </form>
            <form
              className="change-password-form"
              onSubmit={this.submitPasswordChange.bind(this)}
            >
              <div className="form-row">
                <h4>{messages.S_CHANGE_PASSWORD}</h4>
                {this.state.errorsPassword.length ? (
                  <ul className="errors">
                    {this.state.errorsPassword.map((error) => (
                      <li key={error}> {error} </li>
                    ))}
                  </ul>
                ) : null}
                <label>{messages.SET_PASSWORD}</label>
                <input
                  ref={(input) => {
                    this.passInput = input;
                  }}
                  className="password pass-input"
                  type="password"
                  name="password"
                />
                <label>{messages.S_CONFIRM_PASSWORD}</label>
                <input
                  ref={(input) => {
                    this.confirmpassInput = input;
                  }}
                  className="password confirmpass-input"
                  type="password"
                  name="password"
                />
                <button type="submit">{messages.S_SAVE}</button>
                {this.state.passwordSaved ? (
                  <a className="saved-changes">{messages.S_SAVED}</a>
                ) : null}
              </div>
            </form>
          </div>
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default connect()(Account);
