import React from "react";
import ReactSVG from "react-svg";
import Activity from "images/icons/activity.svg";
import Bell from "images/icons/bell.svg";
import Brightness from "images/icons/brightness.svg";
import Camera from "images/icons/camera.svg";
import Chart from "images/icons/chart.svg";
import ChatAlt from "images/icons/chat-alt.svg";
import ChevronLeft from "images/icons/chevron-left.svg";
import ChevronRight from "images/icons/chevron-right.svg";
import Circle from "images/icons/circle.svg";
import CircleOk from "images/icons/circle-ok.svg";
import Close from "images/icons/close.svg";
import Copy from "images/icons/copy.svg";
import Cup from "images/icons/cup.svg";
import Dashboard from "images/icons/dashboard.svg";
import Download from "images/icons/download.svg";
import Drop from "images/icons/drop.svg";
import Edit from "images/icons/edit.svg";
import EnvelopeAlt from "images/icons/envelope-alt.svg";
import Exit from "images/icons/exit.svg";
import Explore from "images/icons/explore.svg";
import Eye from "images/icons/eye.svg";
import Favourite from "images/icons/favourite.svg";
import File from "images/icons/file.svg";
import Folder from "images/icons/folder.svg";
import GridSmall from "images/icons/grid-small.svg";
import Grid from "images/icons/grid.svg";
import HappyFace from "images/icons/happy-face.svg";
import Horn from "images/icons/horn.svg";
import Justify from "images/icons/justify.svg";
import Lightbulb from "images/icons/lightbulb.svg";
import Link from "images/icons/link.svg";
import Map from "images/icons/map.svg";
import Moon from "images/icons/moon.svg";
import Notebook from "images/icons/notebook.svg";
import Ok from "images/icons/ok.svg";
import People from "images/icons/people.svg";
import Person from "images/icons/person.svg";
import Plus from "images/icons/plus.svg";
import Rocket from "images/icons/rocket.svg";
import Rotate from "images/icons/rotate.svg";
import Send from "images/icons/send.svg";
import Settings from "images/icons/settings.svg";
import ShareAndroid from "images/icons/share-android.svg";
import Shield from "images/icons/shield.svg";
import Sounds from "images/icons/sounds.svg";
import Star from "images/icons/star.svg";
import ThumbUp from "images/icons/thumb-up.svg";
import User from "images/icons/user.svg";
import Video from "images/icons/film.svg";

const icons = {
  Activity,
  Bell,
  Brightness,
  Camera,
  Chart,
  ChatAlt,
  ChevronLeft,
  ChevronRight,
  Circle,
  CircleOk,
  Close,
  Copy,
  Cup,
  Dashboard,
  Download,
  Drop,
  Edit,
  EnvelopeAlt,
  Exit,
  Explore,
  Eye,
  Favourite,
  File,
  Folder,
  GridSmall,
  Grid,
  HappyFace,
  Horn,
  Justify,
  Lightbulb,
  Link,
  Map,
  Moon,
  Notebook,
  Ok,
  People,
  Person,
  Plus,
  Rocket,
  Rotate,
  Send,
  Settings,
  ShareAndroid,
  Shield,
  Sounds,
  Star,
  ThumbUp,
  User,
  Video,
};

const Icon = ({
  variables, // eslint-disable-line
  name, // eslint-disable-line
  width, // eslint-disable-line
  height, // eslint-disable-line
  size = 25,
  color, // eslint-disable-line
  fill = "none", // eslint-disable-line
  className,
  onClick, // eslint-disable-line
}) => {
  const renderIcon = () => (
    <ReactSVG
      className={className}
      beforeInjection={(svg) => {
        const svgWidth = width || size;
        const svgHeight = height || size;

        svg.setAttribute("stroke", color);
        svg.setAttribute("height", svgHeight);
        svg.setAttribute("width", svgWidth);
      }}
      src={icons[name]}
    />
  );

  if (onClick) {
    return (
      <a className="icon-clickable" onClick={onClick}>
        {renderIcon()}
      </a>
    );
  }

  return renderIcon();
};

export default Icon;
