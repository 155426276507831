const constants = {
  numDays: 365,
  scales: {
    green: [
      "#B0FD9B",
      "#71FC4A",
      "#2FCF03",
      "#2BB305",
      "#249c03",
      "#1F8104",
      "#196804",
      "#144f03",
      "#0E3C01",
      "#092801"
    ],
    blue: [
      "#B0EDFF",
      "#71DCFF",
      "#3FCFFF",
      "#3BB3FF",
      "#249cFF",
      "#1F81FF",
      "#1968FF",
      "#144fFF",
      "#0E3CFF",
      "#0908FF"
    ],
    orange: [
      "#fff69B",
      "#ffe54a",
      "#ffd803",
      "#FFBC05",
      "#ffa503",
      "#ff8a04",
      "#ff7104",
      "#ff5803",
      "#ff3101",
      "#ff0000"
    ],
    red: ["#ffb2b2", "#ff4c4c", "#ff0000", "#4c0000"],
    purple: [
      "#d8b2d8",
      "#cc99cc",
      "#bf7fbf",
      "#b266b2",
      "#a64ca6",
      "#993299",
      "#800080",
      "#730073",
      "#660066",
      "#4c004c"
    ],
    grey: [
      "#ddd",
      "#ccc",
      "#aaa",
      "#999",
      "#888",
      "#777",
      "#666",
      "#555",
      "#333",
      "#000"
    ],
    teal: [
      "#b2d8d8",
      "#99cccc",
      "#66b2b2",
      "#4ca6a6",
      "#329999",
      "#008080",
      "#007373",
      "#006666",
      "#004c4c",
      "#003333"
    ]
  }
};

export default constants;
