import React from "react";
import * as Sentry from "@sentry/browser";
import * as Components from "components";
import { syncData } from "actions/users";
import { connect } from "react-redux";
import { withSize } from "react-sizeme";
import constants from "common/constants";
import { showModal } from "actions/modal";
import messages from "utils/messages";
import { connect as connectToSocket } from "actions/sync";
import { format } from "date-fns";
import { fetchHabits } from "actions/habits";
import { withRouter } from "react-router";

class Board extends React.Component {
  constructor(props) {
    super(props);

    let margins = 330;
    if (this.props.size.width < 768) margins = 310;

    this.state = {
      currentDay: format(new Date(), "YYYY-MM-DD"),
      width: null,
      numDaysView: parseInt((this.props.size.width - margins) / 60, 10),
      offset:
        constants.numDays -
        parseInt((this.props.size.width - margins) / 60, 10) +
        1,
      statsType: 1,
    };
  }

  componentDidMount() {
    const user = this.props.user;
    if (user) {
      this.props.dispatch(syncData());
      connectToSocket(this.props.user.id);
      Sentry.configureScope((scope) => {
        scope.setUser(user);
      });
    }

    // AUTOREFRESH
    this.interval = setInterval(async () => {
      const currentDay = format(new Date(), "YYYY-MM-DD");

      if (currentDay !== this.state.currentDay) {
        await this.setState(() => ({
          currentDay,
        }));
        this.props.dispatch(fetchHabits());
      }
    }, 1 * 5 * 1000);

    const currentLocation = this.props.location.pathname;
    if (currentLocation === "/subscribe")
      this.props.dispatch(
        showModal("subscription", { showBrightModal: true, class: "premium" })
      );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let margins = 330;
    if (nextProps.size.width < 768) margins = 310;

    if (prevState.width !== nextProps.size.width) {
      return {
        width: nextProps.size.width,
        numDaysView: parseInt((nextProps.size.width - margins) / 60, 10),
        offset:
          constants.numDays -
          parseInt((nextProps.size.width - margins) / 60, 10) +
          1,
      };
    }

    return null;
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  nextDates() {
    let newOffset = this.state.offset + (this.state.numDaysView - 1);
    if (newOffset > constants.numDays - this.state.numDaysView + 1) {
      newOffset = constants.numDays - this.state.numDaysView + 1;
    }
    this.setState(() => ({
      offset: newOffset,
    }));
  }

  prevDates() {
    let newOffset = this.state.offset - (this.state.numDaysView - 1);
    if (newOffset < 0) newOffset = 0;
    this.setState(() => ({
      offset: newOffset,
    }));
  }

  showNewHabitModal() {
    this.props.dispatch(showModal("newHabit", { title: messages.NEW_HABIT }));
  }

  showPremiumFeatureModal() {
    this.props.dispatch(
      showModal("premiumFeature", { class: "premiumfeature" })
    );
  }

  showEditModal(habit) {
    this.props.dispatch(
      showModal("editHabit", { title: messages.EDIT_HABIT, habit })
    );
  }

  showStats(statsType) {
    this.setState({ statsType });
  }

  render() {
    return (
      <div className="applayout">
        {!this.props.showSpinner ? ( // eslint-disable-line
          this.props.habits.length ? (
            <div className="app-wrapper not-select">
              <Components.Header
                numDaysView={this.state.numDaysView}
                offset={this.state.offset}
                baseDate={this.props.baseDate}
                currentDay={this.state.currentDay}
                nextDates={this.nextDates.bind(this)}
                prevDates={this.prevDates.bind(this)}
                statsType={this.state.statsType}
              />
              <div className="clear" />
              <Components.Menu
                habits={this.props.habits}
                user={this.props.user}
                showEditModal={this.showEditModal.bind(this)}
                dispatch={this.props.dispatch}
              />
              <div className="board-page">
                <Components.Board
                  habitsDates={this.props.habitsDates}
                  currentDay={this.state.currentDay}
                  numDaysView={this.state.numDaysView}
                  offset={this.state.offset}
                  habits={this.props.habits}
                  statsType={this.state.statsType}
                  user={this.props.user}
                  showPremiumFeatureModal={this.showPremiumFeatureModal.bind(
                    this
                  )}
                />
              </div>
              <div className="clear" />
              <Components.BoardFooter
                numDaysView={this.state.numDaysView}
                offset={this.state.offset}
                currentDay={this.state.currentDay}
                hideNewHabit={this.props.isArchive}
                habitsDates={this.props.habitsDates}
                showNewHabitModal={this.showNewHabitModal.bind(this)}
                showPremiumFeatureModal={this.showPremiumFeatureModal.bind(
                  this
                )}
                showStats={this.showStats.bind(this)}
                numHabits={this.props.habits.length}
                user={this.props.user}
              />
            </div>
          ) : (
            <Components.NoHabits
              isArchive={this.props.isArchive}
              showNewHabitModal={this.showNewHabitModal.bind(this)}
            />
          )
        ) : (
          <div className="spinner" />
        )}
        <div className="clear" />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const currentLocation = props.location.pathname;
  const isArchive = currentLocation === "/archive";

  let habits;
  if (isArchive) {
    habits = state.archive.habits.map((habit) => state.habitsEntities[habit]);
  } else {
    habits = state.habits.habits.map((habit) => state.habitsEntities[habit]);
  }

  return {
    habits,
    isArchive,
    habitsDates: habits.map((habit) => state.dates[habit.id] || {}),
    baseDate: state.basedate,
    showSpinner: state.queue.fetchingHabits && state.queue.initialFetch,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps)(withSize()(Board)));
