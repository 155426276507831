export default function login(state = [], action) {
  switch (action.type) {
    case "SIGNUP_ERROR":
    case "LOGIN_ERROR":
      return [...action.errors];
    case "LOGIN_SUCCESS":
      return [];
    case "CLEAR_ERRORS":
      return [];
    default:
      return state;
  }
}
