import { combineReducers } from "redux";

import habits from "./habits";
import archive from "./archive";
import habitsEntities from "./habitsEntities";
import user from "./user";
import dates from "./dates";
import basedate from "./basedate";
import modal from "./modal";
import banner from "./banner";
import promo from "./promo";
import errors from "./errors";
import queue from "./queue";
import variables from "./variables";

const allReducers = combineReducers({
  archive,
  habits,
  habitsEntities,
  user,
  dates,
  basedate,
  modal,
  banner,
  promo,
  errors,
  queue,
  variables,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    state = action.state;
  }

  return allReducers(state, action);
};

export default rootReducer;
