import { isSafariPopupExtension, isExtension } from "utils/extension";
import { isSafari } from "utils/browser";
import config from "config";

export default function GoogleButton() {
  const appleLink = `https://appleid.apple.com/auth/authorize?client_id=app.everyday.web&use_popup=true&redirect_uri=${config.appleRedirectUri}&response_type=code&scope=email name&response_mode=form_post`;

  function signInWithApple() {
    if (isSafari() && !isExtension())
      window.location.assign(appleLink); // Open in the same window
    else window.open(appleLink, "_blank", "width=500,height=600"); // Open in a new window
  }

  // if (isSafariPopupExtension()) return null;

  return (
    <div className="apple-button" onClick={signInWithApple}>
      <span className="apple-logo"></span>
      <span className="apple-sign-in-text">Sign in with Apple</span>
    </div>
  );
}
