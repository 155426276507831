export default function modal(state = {}, action) {
  switch (action.type) {
    case "SHOW_MODAL":
      return {
        modal: action.modal,
        props: action.props,
        showBrightOpacity: action.showBrightOpacity,
      };
    case "LOGIN_SUCCESS":
    case "SIGNUP_SUCCESS":
    case "LOGOUT":
    case "HIDE_MODAL":
      return {};
    default:
      return state;
  }
}
