/* Validator functions to be used both in the API and in the frontend */

// function isEmail(email) {
//   return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
//   .test(email);
// }

const blacklistedEmails = [
  "mailinator.com",
  "getnada.com",
  "mvrht.net",
  "sharklasers.com",
  "bobmail.com",
  "h4ck.me",
  "oalsp.com",
  "mailhero.io",
  "yopmail.com",
  "sjuaq.com",
  "grr.la",
  "spam4.me",
  "vmani.com",
  "guerrillamail.com",
  "nwytg.com",
  "nwytg.net"
];

const errorList = [
  "The name must be between",
  "Please, use a valid email provider",
  "The email address is invalid",
  "The password must be at least 8 characters long",
  "Invalid email or password",
  "Invalid or expired token",
  "Network request failed",
  "dates_habit_id_date_unique"
];

exports.isControlledError = function isControlledError(error) {
  return errorList.some(e => {
    return error.includes(e);
  });
};

exports.signup = function signup(params) {
  const name = params.name;
  const email = params.email;
  const password = params.password;
  const errors = [];

  if (!name || name.length < 1 || name.length > 50) {
    errors.push(
      new Error("The name must be between 1 and 50 characters long.")
    );
  }

  // if (!/^[A-z -]+$/.test(name)) {
  //   errors.push('The name can only contain letters and whitespaces.');
  // }

  // if (!isEmail(email)) {
  //   errors.push('The email address is invalid.');
  // }
  if (blacklistedEmails.indexOf(email.split("@")[1]) !== -1) {
    errors.push(new Error("Please, use a valid email provider."));
  }

  if (password.length < 8) {
    errors.push(new Error("The password must be at least 8 characters long."));
  }

  return errors.length ? errors : false;
};

exports.habits = function habits(params) {
  const name = params.name;
  const errors = [];

  if (!name || name.length < 1 || name.length > 50) {
    const error = new Error(
      "The name must be between 1 and 50 characters long."
    );
    error.skipSentry = true;
    errors.push(error);
  }

  return errors.length ? errors : false;
};
