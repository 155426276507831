import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import messages from "utils/messages";
import { signup } from "actions/auth";
import { isPopupExtension } from "utils/extension";
import config from "config";
import GoogleButton from "components/GoogleButton/GoogleButton";
import AppleButton from "components/AppleButton/AppleButton";

export default function SignUp({ appleSignIn }) {
  const [errors, setErrors] = useState([]);
  const [message] = useState("");
  const dispatch = useDispatch();
  const nameInput = useRef();
  const emailInput = useRef();
  const passInput = useRef();
  const history = useHistory();
  const appleLink = `https://appleid.apple.com/auth/authorize?client_id=app.everyday.web&redirect_uri=${config.appleRedirectUri}&response_type=code&scope=email name&response_mode=form_post`;

  function signInWithApple() {
    // window.location.replace(appleLink);
    window.open(appleLink, "_blank", "width=500,height=600"); // Open in a new window
  }

  function onSubmit(event) {
    event.preventDefault();
    dispatch(
      signup({
        name: nameInput.current.value,
        email: emailInput.current.value,
        password: passInput.current.value,
        signup_device: "web",
      })
    ).then(
      () => history.replace("/"),
      (errors) => setErrors(errors)
    );
  }

  return (
    <div className="signup">
      <h2>{messages.SIGNUP_TITLE}</h2>
      {message.length ? (
        <div className="success-message">{message}</div>
      ) : (
        <div className="signup-form-wrapper">
          <div className="price-message">
            <div className="trial">{messages.FREE}</div>
            <div>{messages.FOREVER}</div>
          </div>
          {errors.length ? (
            <ul className="errors">
              {errors.map((error) => (
                <li key={error}> {error} </li>
              ))}
            </ul>
          ) : null}
          <form className="signup-form" onSubmit={onSubmit}>
            <div className="form-row">
              <input
                ref={nameInput}
                className="name signup-input"
                autoComplete="name"
                type="name"
                name="name"
              />
              <label className="text-label">{messages.S_NAME}</label>
            </div>
            <div className="form-row">
              <input
                ref={emailInput}
                className="email signup-input"
                autoComplete="username"
                type="email"
                name="email"
              />
              <label className="text-label">{messages.S_EMAIL}</label>
            </div>
            <div className="form-row">
              <input
                ref={passInput}
                className="password signup-input"
                autoComplete="new-password"
                type="password"
                name="password"
              />
              <label className="text-label">{messages.S_PASSWORD}</label>
            </div>
            <div className="terms">
              <a>
                <p>{messages.CONDITIONS}</p>
                <a
                  href="https://everyday.app/terms"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  {messages.CONDITIONS_0}
                </a>
                {messages.CONDITIONS_1}
                <a
                  href="https://everyday.app/privacy"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  {messages.CONDITIONS_2}
                </a>
              </a>
            </div>
            <button type="submit" className="signup-button">
              {messages.SIGNUP}
            </button>
          </form>
          <div className="social-buttons">
            <div className="or">
              <a>{messages.OR}</a>
            </div>

            <AppleButton />
            <GoogleButton />
          </div>
        </div>
      )}
    </div>
  );
}
