// DEPRECATED

import React from "react";
import messages from "utils/messages";
import constants from "common/constants";
import moment from "moment";

export default class SubHeader extends React.Component {
  renderRow() {
    const row = [];

    let numDaysView = this.props.numDaysView;
    let offset = this.props.offset;
    if (constants.numDays - numDaysView + 1 === offset) {
      offset++;
      numDaysView--;
    }

    for (let i = 0; i < numDaysView; i++, offset++) {
      const date = moment(this.props.baseDate).add(offset, "days").format("dd");
      let className = "";

      if (date === "Sa" || date === "Su") {
        className += " weekend";
      }
      if (offset === constants.numDays) {
        className += " today";
        row.push(
          <td key={i} className={className}>
            {date}
          </td>
        );
      } else
        row.push(
          <td key={i} className={className}>
            {date}
          </td>
        );
    }

    return <tr>{row}</tr>;
  }

  render() {
    return (
      <div className="subheader">
        <div className="habitsheader">
          <a>{messages.HABITS}</a>
        </div>
        <div className="weekdays">
          <table>
            <tbody>{this.renderRow()}</tbody>
          </table>
        </div>
        <div className="titles">
          <a>{messages.CURRENT_STREAK}</a>
          <a>{messages.LONGEST_STREAK}</a>
        </div>
      </div>
    );
  }
}
