import React from "react";
import { connect } from "react-redux";
import { deleteAccount } from "actions/users";
import messages from "utils/messages";

class ConfirmDeactivateModal extends React.Component {
  confirm() {
    this.props.dispatch(deleteAccount());
    this.props.onHide();
  }

  render() {
    return (
      <div className="confirm-deactivate-modal">
        <div className="modal-body">
          <p>{messages.CONFIRM_DELETE_ACCOUNT_TEXT}</p>
          <p>{messages.CONFIRM_DELETE_ACCOUNT_TEXT_2}</p>
          <p>
            {messages.CONFIRM_DELETE_ACCOUNT_TEXT_3}
            <a href="mailto:joan@everyday.app" target="_blank" rel="noreferrer">
              {messages.CDA_EMAIL}
            </a>
          </p>
        </div>
        <div className="modal-footer">
          <a className="modal-button delete" onClick={this.confirm.bind(this)}>
            {messages.DELETE}
          </a>
          <a className="modal-button cancel" onClick={this.props.onHide}>
            {messages.CANCEL}
          </a>
        </div>
      </div>
    );
  }
}

export default connect()(ConfirmDeactivateModal);
