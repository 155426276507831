import React from "react";
import messages from "utils/messages";
import moment from "moment";
import { Link } from "react-router-dom";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <ul className="info">
          <li>
            <Link to="/tips">
              <i className="ion-ios-lightbulb-outline" /> {messages.F_TIPS}
            </Link>
          </li>
          <li>
            <Link to="/web-extensions">
              <i className="ion-ios-albums-outline" />{" "}
              {messages.F_WEBEXTENSIONS}
            </Link>
          </li>
          <li>
            <a
              href="https://everyday.app/devices"
              target="_blank"
              rel="noreferrer"
            >
              <i className="ion-iphone" /> {messages.F_DEVICES}
            </a>
          </li>
        </ul>
        <ul className="links">
          <li>
            <a href="mailto:joan@everyday.app" target="_blank" rel="noreferrer">
              {messages.F_FEEDBACK}
            </a>
          </li>
          <li>
            <a href="mailto:joan@everyday.app" target="_blank" rel="noreferrer">
              {messages.F_CONTACT}
            </a>
          </li>
          <li>
            <span>
              {messages.EVERYDAYCHECK} {moment().format("YYYY")}
            </span>
          </li>
          <li>
            <span>
              {messages.F_BY}&nbsp;
              <a
                href="http://twitter.com/mezood"
                target="_blank"
                rel="noreferrer"
              >
                Joan Boixadós
              </a>
            </span>
          </li>
        </ul>
      </div>
    );
  }
}
