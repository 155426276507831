import update from "react-addons-update";

function habits(state = [], action) {
  switch (action.type) {
    case "FETCH_HABITS_SUCCESS":
      return [
        ...action.habits
          .filter((habit) => !habit.archived)
          .map((habit) => habit.id),
      ];
    case "ADD_HABIT_SUCCESS":
      if (state.includes(action.habit.id)) return state;
      return [...state, action.habit.id];
    case "UPDATE_HABIT_AFTER_REQUEST":
      return [
        ...state.map((habitId) =>
          habitId === action.oldHabit.id ? action.newHabit.id : habitId
        ),
      ];
    case "DELETE_HABIT_SUCCESS":
      return state.filter((habit) => habit !== action.habit_id);
    case "MOVE_HABIT":
      return update(state, {
        $splice: [
          [action.dragIndex, 1],
          [action.hoverIndex, 0, action.dragHabit.id],
        ],
      });
    case "ARCHIVE":
      return state.filter((habitId) => habitId !== action.id);
    case "UNARCHIVE":
      return [...state, action.id];
    default:
      return state;
  }
}

const initialState = {
  habits: [],
};

export default function habitsReducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_HABITS_SUCCESS":
    case "ADD_HABIT_SUCCESS":
    case "DELETE_HABIT_SUCCESS":
    case "UPDATE_HABIT_AFTER_REQUEST":
    case "ARCHIVE":
    case "UNARCHIVE":
    case "MOVE_HABIT":
      return { ...state, habits: habits(state.habits, action) };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
}
