import React from "react";
import { CardElement } from "react-stripe-elements";
import { connect } from "react-redux";

class CheckoutForm extends React.Component {
  render() {
    const { isDarkMode } = this.props;
    return (
      <div className="checkout-form">
        <CardElement
          hidePostalCode
          style={{
            base: {
              color: isDarkMode ? "#fff" : "#6F7A80",
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: isDarkMode ? "#A8AFB3" : "#6F7A80",
              },
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a",
            },
          }}
        />
      </div>
    );
  }
}

export default connect((state) => ({
  isDarkMode: state.variables.theme === "dark",
}))(CheckoutForm);
