import { compose, createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import * as storage from "utils/storage";
import config from "config";
import throttle from "lodash/throttle";
import root from "../reducers/root";

// Retrieve localStorage state to init the app with it
// The app should still update from backend
let persistedState = localStorage.getItem("edc_state")
  ? JSON.parse(localStorage.getItem("edc_state"))
  : {};

const version = storage.get("edc_state_version");

if (
  persistedState.user &&
  persistedState.user.id &&
  version !== config.storageVersion
) {
  persistedState = {
    user: persistedState.user,
    variables: persistedState.variables,
  };
}

const finalCreateStore = compose(
  applyMiddleware(thunkMiddleware),
  window.devToolsExtension ? window.devToolsExtension() : (func) => func
)(createStore);
// const store = finalCreateStore(root);
const store = finalCreateStore(root, persistedState);

store.subscribe(
  throttle(() => {
    const state = store.getState();
    state.queue.fetchingHabits = false;

    storage.set({
      key: "edc_state",
      value: state,
      persist: true,
    });
  }, 1000)
);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept("../reducers/root", () => {
    // eslint-disable-next-line
    const nextRootReducer = require("../reducers/root");
    store.replaceReducer(nextRootReducer);
  });
}

// export { persistor };
export default store;
