import React from "react";
import messages from "utils/messages";

export default class WebExtensions extends React.Component {
  render() {
    return (
      <div className="web-extensions">
        <div className="wrapper">
          <div className="left">
            <i className="ion-android-warning" />
            <h3>{messages.WEBEXTENSIONS_TITLE}</h3>
            <p className="text">{messages.WEBEXTENSIONS_TEXT}</p>
            <p className="text">
              {messages.WEBEXTENSIONS_TEXT_2}
              <a
                className="link"
                href="https://www.youtube.com/watch?v=OMbsGBlpP30"
                target="_blank"
                rel="noreferrer"
              >
                {messages.WEBEXTENSIONS_TEXT_3}
              </a>
              {messages.WEBEXTENSIONS_TEXT_4}
            </p>
            <div className="line" />
          </div>
          <div className="right">
            <div className="browsers">
              <div className="chrome">
                <a className="title">{messages.WEBEXTENSIONS_FOR_CHROME}</a>
                <a
                  className="button"
                  href="https://chrome.google.com/webstore/detail/everydaycheck-do-it-every/kpcjjapfjdgjlmoehhliomplgjnbdcpc" // eslint-disable-line
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.WEBEXTENSIONS_CHROME}
                </a>
              </div>
              <div className="firefox">
                <a className="title">{messages.WEBEXTENSIONS_FOR_FIREFOX}</a>
                <a
                  className="button"
                  href="https://addons.mozilla.org/en-US/firefox/addon/everydaycheck-do-it-every-day/?src=ss" // eslint-disable-line
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.WEBEXTENSIONS_FIREFOX}
                </a>
              </div>
            </div>
          </div>
          <div className="clear" />
        </div>
      </div>
    );
  }
}
