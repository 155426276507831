import moment from "moment";

// Initializing state to a date so that the UI doesn't display
// invalid while fetching Dates
const initialState = moment().toISOString();

export default function basedate(state = initialState, action) {
  switch (action.type) {
    case "FETCH_DATES_SUCCESS":
      return action.baseDate;
    default:
      return state;
  }
}
