const messages = {
  EVERYDAYCHECK: "everyday",
  LOGIN: "Log in",
  SIGNUP: "Sign up",
  LOGOUT: "Log out",
  BOARD: "Board",
  ARCHIVE: "Archive",
  SETTINGS: "Settings",
  WEBEXTENSIONS: "Web Extensions",
  EMAIL: "email",
  PASSWORD: "password",
  OR: "or",

  // Onboarding
  SKIP: "Skip",
  NEXT: "Next",
  PREVIOUS: "Previous",

  SN_BOARD: "Board",
  SN_YEARLY_VIEW: "Yearly view",
  SN_ARCHIVE: "Archive",
  SN_CONNECT: "Connect",
  SN_INVITE: "Invite",

  HABITS: "Habits",
  NEW_HABIT: "New Habit",

  TODAY: "today",
  CURRENT_STREAK: "current streak",
  LONGEST_STREAK: "longest streak",
  TOTAL_COUNT: "total count",
  COMPLETION_RATE: "completion rate",
  ST_WEEK_COUNT: "week count",
  ST_MONTH_COUNT: "month count",
  ST_YEAR_COUNT: "year count",
  ST_WEEK_COMPL_RATE: "week compl. rate",
  ST_MONTH_COMPL_RATE: "month compl. rate",
  ST_YEAR_COMPL_RATE: "year compl. rate",

  YV_SHARE: "Share",
  YV_DOWNLOAD: "Download this image",
  YV_DOWNLOADING:
    "Generating image. Please Wait... It might take a couple seconds :-)",

  HABIT_NAME: "Habit",
  HABIT_NAME_PH: "e.g. walk the dog",
  ADD_HABIT: "Add Habit",
  EDIT_HABIT: "Edit Habit",
  CONFIRM_DELETE_HABIT: "Confirm Delete",
  CONFIRM_DELETE_TEXT: "Are you sure you want to delete this habit?",
  DELETE: "delete",
  CANCEL: "Cancel",
  ADVANCED_OPTIONS: "Advanced options",
  AO_FREQUENCY: "Frequency",
  AO_FREQUENCY_EVERYDAY: "Every day!",
  AO_FREQUENCY_EXP: "The app will automatically skip the days you specify.",
  AO_BREAK_HABIT: "Break habit",
  AO_BREAK_HABIT_EXP: "The colourful scale will be descending.",
  AO_NO_WEEKENDS: "No Weekends",
  AO_NO_WEEKENDS_EXP:
    "Weekends will be automatically skipped. Do it every weekday!",
  AO_ARCHIVED: "Archived",
  AO_ARCHIVED_EXP:
    "Archive your habits to pause them or to keep your data safely away from the board :)",
  F_TIPS: "Tips",
  F_WEBEXTENSIONS: "Web extensions",
  F_DEVICES: "Devices",
  F_CONTACT: "contact",
  F_BY: "by",
  F_FEEDBACK: "give me feedback please! :-)",

  SIGNUP_TITLE: "Start doing it every day!",
  CHECK_EMAIL: `Yaaay! You should soon receive an email to confirm the account and
  start doing things EVERY DAY!`,
  S_NAME: "Name",
  S_EMAIL: "Email",
  S_PASSWORD: "Password",
  LOGIN_TITLE: "Log in",
  L_FORGOT: "Forgot password?",
  L_SIGNUP: "Sign up",

  EMPTY_TITLE_1: "Looks like you aren't tracking any habits yet, so...",
  EMPTY_TITLE_2: "what do you want to start doing every day? :)",
  EMPTY_ARCHIVE_1: "Your archived habits will appear here.",
  EMPTY_ARCHIVE_2:
    "Archive is the perfect place to store your paused habits or habits you've successfully formed and whose data you want to store for posterity :)",
  EMPTY_BUTTON: "Add New Habit",

  // BANNER
  DEADLINE: "Hi ",
  DEADLINE_0: "! Great to see you marking today's habits, good job! :-)",
  DEADLINE_1: `Just wanted to let
             you know that from May 10th, everyday will require of a paid account.
             Since you've been helping me make this a better tool for all of us, I've
             set a discounted price of $12/year for everyone subscribing before such date.
             I detailed the reasons for charging below, just click the 'Why' button!`,
  DEADLINE_2: `If the app has been helping you get disciplined, please, consider helping me
               improving it even further! I already have some improvements on the way!`,
  DEADLINE_3: "Feel free to",
  DEADLINE_4: " email me",
  DEADLINE_5: "in any case!",
  SIGNATURE: "Joan",
  WHY: "Why?",
  WHYNOTFREE: "Why isn't everyday free?",
  BECAUSE: "A project has to be sustainable, or shut down.",
  BECAUSE_2: `Paying $12/year ensures that the costs to run everyday are covered,
              that the app will be there helping us EVERY DAY, forever, and motivates me
              to keep improving it with your feedback.`,
  BECAUSE_3: "It shows you care.",
  BECAUSE_4: "Not convinced? Read ",
  BECAUSE_5: "what Maciej from Pinboard wrote on the topic",
  BECAUSE_6: " or shoot me an ",
  BECAUSE_7: "email!",

  STRIPE_PROBLEMS: `For those who tried subscribing yesterday and experienced some problems
                    (and still see this banner), please try again when you find a moment,
                    I have fixed the issue now :p`,
  STRIPE_PROBLEMS_2: "Sorry for the inconveniences!!! 0:)",

  MOD_PREMIUM_FEATURE: "Premium",
  MOD_PREMIUM_3HABITS:
    "You have reached the limit of 3 habits in your free account.",
  MOD_PREMIUM_3HABITS_PREMIUM:
    "Create an unlimited number of habits upgrading to a Premium account! :-)",
  MOD_PREMIUM_ARCHIVE: "Archive is a premium feature.",
  MOD_PREMIUM_ARCHIVE_PREMIUM:
    "Archive your habits to pause them or to keep your data safely away from the board :)",
  MOD_PREMIUM_DARKMODE: "Dark Mode is a premium feature.",
  MOD_PREMIUM_DARKMODE_PREMIUM:
    "You can preview Dark Mode but it will reset to default in the next app launch. Enjoy Dark Mode and other premium features by upgrading!",
  MOD_PREMIUM_EXPORT: "Backing up your data is a premium feature.",
  MOD_PREMIUM_EXPORT_PREMIUM: "blablablablalb",

  MOD_LATER: "Later",
  MOD_LEARN: "Learn more about Premium",
  MODAL_SKIP_1: 'The triangle represents a "skip".',
  MODAL_SKIP_2:
    "Skips are neutral. They do not break your streak, but they do not add to it either.",
  MODAL_SKIP_3:
    "You can use them in days where you couldn't perform the habit or to give you a second chance to maintain the streak :)",
  MODAL_SKIP_4: "But remember, don't skip twice!",
  REVIEW: "Review",
  MODAL_REVIEW:
    "If you enjoy using Everyday, would you mind taking a moment and rate us? Thanks for your support!",
  MOD_UPGRADE: "Upgrade Now",
  TRIAL_BANNER_MESSAGE:
    "You have {months} months of premium access. Happy habit forming!",
  TRIAL_EXPIRED: "The trial period has expired :-(",
  TRIAL_EXPIRED_MSG:
    "If the app helped you, please consider subscribing for only $12/year!",
  SUBSCRIBE: "Upgrade",
  UPGRADE_APPLY: "Get ",
  UPGRADE_PROMO: "discount!",
  THIRD_PARTIES: "Payments are handled by ",
  THIRD_PARTIES_2: ` Your credit card details go straight from your browser to their secure
                    payment system. I never see them!`,
  TOSETTINGS: "or... Go to Settings",
  PRE_PAYMENT_FAILED: "Payment Failed. Please, try another card",
  PRE_NO_REDEEMS_LEFT: "No redeems left for this promo",
  PRE_TITLE: "Unlock your every day growth with ",
  PRE_TITLE2: " everyday Premium",
  PRE_FEAT1: "Unlimited habits",
  PRE_FEAT2: "Dark Mode",
  PRE_FEAT3: "Archive habits",
  PRE_FEAT4: "Multiplatform synchronisation (Web, iOS, Android)",
  PRE_FEAT5: "All habit history access and habit insights",
  PRE_FEAT6: "Priority support",
  PRE_FEAT7:
    "App in constant active development improving with your feedback to help us all grow a little bit every day ;)",
  PRE_MONTH: "Monthly billing",
  PRE_MONTH_PRICE: "$7.49 ",
  PRE_MONTH_PRICE_TEXT: " / month",
  PRE_MONTH_PROMO: "Most flexible",
  PRE_YEAR: "Annual billing",
  PRE_YEAR_NOTE: "That's $2.5/month - ",
  PRE_YEAR_SAVE: "Save 50%!",
  PRE_YEAR_PRICE: "$29.99 ",
  PRE_YEAR_PRICE_TEXT: " / year",
  PRE_YEAR_PROMO: "Most popular",
  PRE_YEAR_PRICE_GRANDFATHER: "$12 ",
  PRE_LIFE: "One time purchase",
  PRE_LIFE_PRICE: "$99",
  PRE_LIFE_SAVE: "Save 90% forever",
  PRE_LIFE_PROMO: "Limited offer",
  PRE_CONTINUE: "Continue",
  PRE_CANCEL: "Cancel anytime.",
  PRE_GRANDFATHER:
    "Hello! If you are reading this, it means you are one of the very early customers who helped everyday.app start on the right foot :)",
  PRE_GRANDFATHER2: "Thank you!",
  PRE_GRANDFATHER3:
    "As a symbol of appreciation, I've made sure that you'll always be able to renew the yearly plan at the original offer of $12/year.",
  PRE_GRANDFATHER4: "Let's keep growing a little bit every day! ;)",
  PRE_SIGNATURE: "Joan Boixadós",
  PRE_OFF_BY: "off by",
  PRE_FOR: "for",
  PRE_MONTHS: "months!",
  PRE_FIRST_YEAR: "for the first year!",
  PRE_FIRST_MONTH: "for the first month!",
  PRE_TOTAL: "TOTAL",

  S_ACCOUNT: "Account",
  S_ACCOUNT_TEXT: "Manage your account",
  S_CHANGE_NAME: "Edit name",
  SET_NAME: "Name:",
  S_CHANGE_PASSWORD: "Change your password",
  SET_PASSWORD: "New password:",
  S_CONFIRM_PASSWORD: "Confirm password:",
  S_SAVE: "Save",
  S_SAVED: "Changes have been saved.",
  E_PW_MATCH: "Passwords don't match",
  E_NAME_LENGTH: "The name must be between 3 and 50 characters long.",
  E_PASSWORD_LENGTH: "The password must be at least 8 characters long.",

  S_MAILING_LIST: "Mailing List",
  S_ML_TEXT: "Manage your subscription to the mailing list",
  S_SUBS_TEXT: "You are subscribed.",
  S_UNSUBS_TEXT: "You are unsubscribed.",
  S_CONFIRMATION: "Subscription confirmed! Happy habit forming :)",
  S_SUBSCRIBE: "Upgrade",
  S_SUBSCRIBE2: "Subscribe",
  S_UNSUBSCRIBE: "Unsubscribe",
  S_UNSUBSCRIBE_ON_APPLE:
    "You subscribed using the ios app. Please, go to the App Store if you want to unsubscribe",
  S_UNSUBSCRIBE_ON_ANDROID:
    "You subscribed using the android app. Please, go to Google Play if you want to unsubscribe",
  UNSUBSCRIBED_MAILING:
    "You have successfully unsubscribed from the mailing list!",

  S_EMAIL_REMINDERS: "Email Reminders",
  S_ER_TEXT: "Manage emails to help you build the habit to engage with the app",
  S_ER_DAILY: "Daily reminder",
  S_ER_DAILY_TEXT: "Receive an email reminder every day",
  S_ER_WEEKLY: "Weekly Reminder",
  S_ER_WEEKLY_TEXT: "Receive an email reminder every week",
  S_ER_DS2: "Don't Skip Twice Reminder",
  S_ER_DS2_TEXT:
    "Receive an email when you don't mark any habit for 2 days in a row",
  S_ER_DR_TURNED_ON: "Daily reminders are turned on.",
  S_ER_DR_TURNED_OFF: "Daily reminders are turned off.",
  S_ER_WR_TURNED_ON: "Weekly reminders are turned on.",
  S_ER_WR_TURNED_OFF: "Weekly reminders are turned off.",
  S_ER_DS2_TURNED_ON: "Don't Skip Twice reminders are turned on.",
  S_ER_DS2_TURNED_OFF: "Don't Skip Twice reminders are turned off.",
  S_ER_TURN_ON: "Turn on",
  S_ER_TURN_OFF: "Turn off",
  UNSUBSCRIBED_REMINDERS:
    "You have successfully unsubscribed from email reminders!",

  S_DARK_MODE: "Dark Mode",
  S_DARK_MODE_TEXT: "Relax your eyes :)",
  S_DARK_MODE_ACTIVATE: "Activate",
  S_DARK_MODE_DEACTIVATE: "Deactivate",

  S_SUBSCRIPTION: "Subscription",
  S_SUBSC_TEXT: "Manage your subscription",
  S_SUBSCRIBED_UNTIL: "You are subscribed until:",
  S_TRIAL_UNTIL: "Premium expires:",
  S_SUBS_EXPIRED: `Your subscription has expired. Click subscribe to re-activate your
                   account and get back to doing things EVERY DAY! :)`,
  S_RENEWAL_NOTIFICATION:
    "Don't worry, you will be notified via email before renewal!",
  S_TRIAL_EXPIRED: `Your trial period has expired. Subscribe to get back to doing things
                    EVERY DAY! :)`,
  S_SUBS_WILL_END: `You canceled your subscription :(. Consider resubscribing to keep
                    doing things EVERY DAY! :D`,
  S_TRIAL_PERIOD: "If the app is helping you, consider upgrading to Premium :)",
  S_LIFETIME: "This is a lifetime account :)",

  S_EXPORT_DATA: "Backup your data",
  S_EXPORT_DATA_TEXT: "Download a backup of your data",
  S_EXPORT: "Backup",

  S_DEACTIVATE: "Delete",
  S_DEACT_TEXT: "Delete your account",
  S_DEACT_BUTTON: "Delete your account ( ;( )",
  CONFIRM_DELETE_ACCOUNT_TEXT: `Are you sure you want to delete your account and stop
                                doing things EVERY DAY?`,
  CONFIRM_DELETE_ACCOUNT_TEXT_2: `Deleting your account will also delete all your
                                  habit tracking data.`,
  CONFIRM_DELETE_ACCOUNT_TEXT_3: `In any case, I'd really appreciate if you could share
                                  with me what led you to this point. Let me know how
                                  can I make the app better for everyone by shooting me an `,
  CDA_EMAIL: "email.",

  TIPS: "Tips",
  TIPS_DESCRIPTION:
    "Tips you may want to consider to get the most out of everyday",
  TIPS_WEBEXTENSIONS:
    "Set everyday as your default new tab page with the web extensions",
  TIPS_HOMEPAGE: "Set everyday as your browser's homepage",
  TIPS_BOOKMARK: "Bookmark app.everyday.app",
  TIPS_PINNEDTAB: "Set everyday as a pinned tab",
  TIPS_DESKSHORTCUT: "Create a desktop shortcut",
  TIPS_MOBSHORTCUT:
    "Create a shortcut to everyday from your phone/tablet's homescreen",

  WEBEXTENSIONS_CHROME: "Add to Chrome",
  WEBEXTENSIONS_FIREFOX: "Add to Firefox",
  WEBEXTENSIONS_TITLE: "Benefit from everyday's Web Extensions",
  WEBEXTENSIONS_TEXT: `Web extensions set everyday as the default website when opening a
                       new tab in your browser.`,
  WEBEXTENSIONS_TEXT_2: `Seeing your board several times every day will
                       serve as the `,
  WEBEXTENSIONS_TEXT_3: "cue ",
  WEBEXTENSIONS_TEXT_4:
    "to remind you and get you motivated about what you want to do EVERY DAY!",
  WEBEXTENSIONS_FOR_CHROME: "For Google Chrome:",
  WEBEXTENSIONS_FOR_FIREFOX: "For Mozilla Firefox:",
  WEBEXTENSIONS_FOR_CHROME_ALT: "Or install it ",
  WEBEXTENSIONS_FOR_CHROME_ALT_2: "from the Google WebStore.",
  WEBEXTENSIONS_FIREFOX_57: `IMPORTANT: Due to a Firefox 56.0 bug, the web extension might
                             interfere with your workflow of immediately searching after opening
                             a new tab. This will be fixed with Firefox 57.0 due to be released
                             on November 14th. You might want to wait until then. 
                             Sorry for the inconveniences!`,
  WEBEXTENSIONS_FAQ_CHROME_INSTALL: `How to install everyday's web extension in
                                     Google Chrome?`,
  WEBEXTENSIONS_FAQ_CHROME_UNINSTALL: `How to uninstall everyday's web extension
                                       from Google Chrome?`,
  WEBEXTENSIONS_FAQ_FIREFOX_INSTALL: `How to install everyday's web extension in
                                      Mozilla Firefox?`,
  WEBEXTENSIONS_FAQ_FIREFOX_UNINSTALL: `How to uninstall everyday's web extension
                                        from Mozilla Firefox?`,
  TOOLTIP_TOTALCOUNT: "Total count of marked habits! Great job!",

  SHARE_TWITTER_1: "Check out the yearly board for my habit ",
  SHARE_TWITTER_2:
    " in everyday.app!! It's a simple and beautiful habit tracker!",
  SHARE_IMAGE_STEP_1:
    "Step 1: Right click and save the image to your computer!",
  SHARE_IMAGE_STEP_2: "Step 2: Share on your favourite social network :)",
  SHARE_LINK: "You can also link to: ",
  SHARE_IMAGE_NOTE: `Note that by generating the image, the habit becomes public.
    You can make it private anytime from the switch at the top right corner!`,
  WANTYOURS: "Want your own board? :)",
  IMAGEBY: "image by ",
  GOBACK: "← Go back",
};

export default messages;
