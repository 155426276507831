import React from "react";
import { confirmEmail } from "actions/auth";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export default function EntryPoint() {
  const dispatch = useDispatch();
  const { token } = useParams();

  if (token) {
    dispatch(confirmEmail({ token }));
  }

  return <div className="entrypoint-page" />;
}
