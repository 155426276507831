import React from "react";
import messages from "utils/messages";
import { connect } from "react-redux";
import { changeTheme } from "actions/theme";
import Icon from "components/Icon/Icon";
import { showModal } from "actions/modal";

class DarkModeSwitch extends React.Component {
  toggleDarkMode() {
    if (!this.props.user.isPremium && this.props.variables.theme !== "dark") {
      this.props.dispatch(
        showModal("premiumFeature", {
          class: "premiumfeature",
          type: "darkmode",
        })
      );
    }
    this.props.dispatch(changeTheme());
  }

  render() {
    const { variables } = this.props;
    return (
      <div className="dark-mode-switch">
        <div className="block">
          <Icon
            className="dark-mode-icon"
            name="Brightness"
            size={50}
            color="var(--main-dark)"
          />
          <h3>{messages.S_DARK_MODE}</h3>
          <a className="text">{messages.S_DARK_MODE_TEXT}</a>
          <div className="line" />
        </div>
        <div className="block">
          {variables.theme === "dark" ? (
            <div className="dark-mode-switch-actions">
              <button type="button" onClick={this.toggleDarkMode.bind(this)}>
                {messages.S_DARK_MODE_DEACTIVATE}
              </button>
            </div>
          ) : (
            <div className="dark-mode-switch-actions">
              <button
                type="button"
                className="activate"
                onClick={this.toggleDarkMode.bind(this)}
              >
                {messages.S_DARK_MODE_ACTIVATE}
              </button>
            </div>
          )}
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default connect((state) => ({ variables: state.variables }))(
  DarkModeSwitch
);
