import fetch from "isomorphic-fetch";
import * as storage from "utils/storage";
import config from "config";
import { getId } from "actions/sync";
import { logout } from "actions/auth";
import * as qs from "query-string";
import { preRequest, postRequest, setOffline } from "../utils/network";
import { getDevice, getBrowser } from "utils/browser";
import store from "../stores";

export default async function request(params) {
  const parseJson = async (response) => {
    const text = await response.text();
    try {
      const json = JSON.parse(text);
      return json;
    } catch (err) {
      console.log(
        "Did not receive JSON, instead received: " +
          text +
          " " +
          JSON.stringify(params)
      );
    }
  };

  const requestParams = preRequest(params);
  if (!requestParams) return;

  const user = storage.get("edc_user");
  const body = {
    ...params.body,
    os: await getDevice(),
    device_model: getBrowser(),
    app_version: config.version,
  };
  const headers = {
    "Content-Type": "application/json",
  };
  const queryParams = qs.parse(window.location.search);

  // wololo = user GOD to be able to make habit screenshots
  if (queryParams.wololo) {
    headers.Authorization = "Basic " + btoa(`wololo:${queryParams.wololo}`);
  } else if (user) {
    if (user.jwtToken) headers.Authorization = `${user.jwtToken}`;
    else if (user.token) headers.Authorization = `Basic ${user.token}`;
  }
  if (params.body) {
    body.socketId = getId();
  }

  let res;

  try {
    console.log("performing request... ", requestParams);
    res = await fetch(`${config.apiUrl}/${params.url}`, {
      method: params.method || "GET",
      body: ["PUT", "POST"].includes(params.method)
        ? JSON.stringify(body)
        : null,
      headers,
    });
  } catch (e) {
    setOffline(requestParams);

    throw e;
  }

  try {
    if (res.status === 502) return setOffline(requestParams);

    const data = await parseJson(res);

    if (requestParams.action) {
      postRequest({ request: requestParams, response: data });
    }

    if (res.status === 401 && requestParams.url !== "login") {
      return store.dispatch(logout());
    }

    if (res.status >= 400) {
      throw data.errors;
    }

    return data;
  } catch (e) {
    console.log(e);
    if (requestParams.action) {
      postRequest({ request: requestParams, response: {} });
    }

    if (res.status !== 200) throw e;
  }
}
