import React from "react";
import messages from "utils/messages";
import { connect } from "react-redux";
import { updateSubscription } from "actions/subscriptions";
import moment from "moment";
import { showModal } from "actions/modal";

class Subscription extends React.Component {
  handleSubscriptionChange(subscribed) {
    this.props.dispatch(updateSubscription({ subscribed }));
  }

  subscribe() {
    this.props.dispatch(
      showModal("subscription", { showBrightModal: true, class: "premium" })
    );
  }

  renderUnsubscribe() {
    if (!this.props.user.subscribed)
      return (
        <div>
          <a className="renewal">{messages.S_SUBS_WILL_END}</a>
          <button
            type="button"
            className="subscribe"
            onClick={this.handleSubscriptionChange.bind(this, true)}
          >
            {messages.S_SUBSCRIBE2}
          </button>
        </div>
      );

    if (this.props.user.androidSubscription)
      return (
        <div>
          <a className="renewal">{messages.S_UNSUBSCRIBE_ON_ANDROID}</a>
        </div>
      );

    if (this.props.user.iosSubscription)
      return (
        <div>
          <a className="renewal">{messages.S_UNSUBSCRIBE_ON_APPLE}</a>
        </div>
      );

    if (this.props.user.plan === "year")
      return (
        <div>
          <button
            type="button"
            onClick={this.handleSubscriptionChange.bind(this, false)}
          >
            {messages.S_UNSUBSCRIBE}
          </button>
        </div>
      );

    return (
      <div>
        <button
          type="button"
          onClick={this.handleSubscriptionChange.bind(this, false)}
        >
          {messages.S_UNSUBSCRIBE}
        </button>
      </div>
    );
  }

  render() {
    const paid_until = moment(this.props.user.paid_until).format(
      "DD MMMM YYYY"
    );
    const premium_access = moment(this.props.user.premium_access).format(
      "DD MMMM YYYY"
    );
    const expired = moment(this.props.user.paid_until).isBefore();
    const isTrial =
      this.props.user.premium_access &&
      !this.props.user.paid_until &&
      !moment(this.props.user.premium_access).isBefore();
    const lifetime = moment("3000-01-01").isBefore(this.props.user.paid_until);
    return (
      <div className="subscription">
        <div className="block">
          <i className="ion-card" />
          <h3>{messages.S_SUBSCRIPTION}</h3>
          <a className="text">{messages.S_SUBSC_TEXT}</a>
          <div className="line" />
        </div>
        <div className="block">
          <div className="actions">
            {isTrial && (
              <a className="paid_until">
                {messages.S_TRIAL_UNTIL} {premium_access}
              </a>
            )}
            {this.props.user.paid_until ? (
              <div>
                {!lifetime ? (
                  <div>
                    <a className="paid_until">
                      {messages.S_SUBSCRIBED_UNTIL} {paid_until}
                    </a>
                    {expired ? (
                      <div>
                        <a className="expired">{messages.S_SUBS_EXPIRED}</a>
                        <button
                          type="button"
                          className="subscribe"
                          onClick={this.subscribe.bind(this)}
                        >
                          {messages.S_SUBSCRIBE2}
                        </button>
                      </div>
                    ) : (
                      <div>{this.renderUnsubscribe()}</div>
                    )}
                  </div>
                ) : (
                  <div className="lifetime">
                    <a>{messages.S_LIFETIME}</a>
                  </div>
                )}
              </div>
            ) : (
              !isTrial && (
                <div>
                  <a>{messages.S_TRIAL_PERIOD}</a>
                  <button
                    type="button"
                    className="subscribe"
                    onClick={this.subscribe.bind(this)}
                  >
                    {messages.S_SUBSCRIBE2}
                  </button>
                </div>
              )
            )}
          </div>
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default connect()(Subscription);
