const initState = {
  promoInfo: null,
  showPromoTip: {}
};

export default function promo(state = initState, action) {
  switch (action.type) {
    case "SET_PROMO":
      return { ...state, promoInfo: action.promo };
    case "SET_SHOW_PROMO_TIP":
      return {
        ...state,
        showPromoTip: {
          ...state.showPromoTip,
          [state.promoInfo.id]: true
        }
      };
    case "REMOVE_PROMO":
      return { ...state, promoInfo: null };
    case "LOGOUT":
      return initState;
    default:
      return state;
  }
}
