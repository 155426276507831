const initialState = {
  fetchingHabits: false,
  initialFetch: true,
  actionsQueue: []
};

export default function queue(state = initialState, action) {
  switch (action.type) {
    case "FETCHING_HABITS":
      return { ...state, fetchingHabits: true };
    case "FETCH_HABIT_SUCCESS":
    case "FETCH_HABITS_SUCCESS":
      return { ...state, fetchingHabits: false, initialFetch: false };
    case "ADD_TO_QUEUE":
      return { ...state, actionsQueue: [...state.actionsQueue, action.action] };
    case "CLEAR_QUEUE":
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
}
