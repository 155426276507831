import React from "react";
import { connect } from "react-redux";
import constants from "common/constants";
import messages from "utils/messages";
import Switch from "react-switch";
import Icon from "components/Icon/Icon";
import { changeTheme } from "actions/theme";
import { showModal } from "actions/modal";
import { subDays, format } from "date-fns";

class Header extends React.Component {
  changeTheme() {
    if (!this.props.user.isPremium && this.props.variables.theme !== "dark") {
      this.props.dispatch(
        showModal("premiumFeature", {
          class: "premiumfeature",
          type: "darkmode",
        })
      );
    }
    this.props.dispatch(changeTheme());
  }

  renderRow() {
    const row = [];

    let numDaysView = this.props.numDaysView;
    let offset = this.props.offset;
    if (constants.numDays - numDaysView + 1 === offset) {
      offset++;
      numDaysView--;
    }

    for (let i = 1; i <= numDaysView; i++, offset++) {
      const currentDate = subDays(
        this.props.currentDay,
        constants.numDays - offset
      );
      const month = format(currentDate, "MMM");
      const date = format(currentDate, "D");
      const day = format(currentDate, "ddd");
      let className = "";
      if (day === "Sat" || day === "Sun") {
        className += " weekend";
      }
      if (offset === constants.numDays) {
        className += " today";
        row.push(
          <td key={i} className={className}>
            <div className="circle">
              <div className="month">{month}</div>
              <div className="date">
                <div>{date}</div>
              </div>
              <div className="day">{day}</div>
            </div>
          </td>
        );
      } else {
        row.push(
          <td key={i} className={className}>
            <div>
              <div className="month">{month}</div>
              <div className="date">{date}</div>
              <div className="day">{day}</div>
            </div>
          </td>
        );
      }
    }

    return <tr>{row}</tr>;
  }

  render() {
    return (
      <div className="header">
        <div className="left">
          <div className="prev" onClick={this.props.prevDates}>
            <i className="ion-ios-arrow-back" />
          </div>
          <div className="habitsheader">
            <a>{messages.HABITS}</a>
          </div>
        </div>
        <div className="dates">
          <table>
            <tbody>{this.renderRow()}</tbody>
          </table>
        </div>
        <div className="right">
          <div className="next" onClick={this.props.nextDates}>
            <i className="ion-ios-arrow-forward" />
            <div className="dark-mode-switch-board">
              <Icon
                className="dark-mode-icon-board"
                name="Brightness"
                color="var(--main-dark)"
              />
              <Switch
                className="da"
                height={15}
                width={30}
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={this.changeTheme.bind(this)}
                checked={this.props.variables.theme === "dark"}
              />
            </div>
          </div>
          {this.props.statsType === 1 ? (
            <div className="titles">
              <a>{messages.CURRENT_STREAK}</a>
              <a>{messages.LONGEST_STREAK}</a>
              <a>{messages.TOTAL_COUNT}</a>
            </div>
          ) : null}
          {this.props.statsType === 2 ? (
            <div className="titles">
              <a>{messages.ST_WEEK_COUNT}</a>
              <a>{messages.ST_MONTH_COUNT}</a>
              <a>{messages.ST_YEAR_COUNT}</a>
            </div>
          ) : null}
          {this.props.statsType === 3 ? (
            <div className="titles extraline">
              <a>{messages.ST_WEEK_COMPL_RATE}</a>
              <a>{messages.ST_MONTH_COMPL_RATE}</a>
              <a>{messages.ST_YEAR_COMPL_RATE}</a>
            </div>
          ) : null}
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default connect((state) => ({
  variables: state.variables,
  user: state.user,
}))(Header);
