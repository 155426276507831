import React from "react";
import { connect } from "react-redux";
import messages from "utils/messages";
import * as Components from "components";

class Settings extends React.Component {
  render() {
    return (
      <div className="settings-page">
        <div className="settings-header">
          <a className="settings-title">
            <i className="ion-ios-gear-outline" />
            {messages.SETTINGS}
          </a>
        </div>
        <div className="settings-list">
          <Components.Account user={this.props.user} />
          <Components.DarkModeSwitch user={this.props.user} />
          <Components.MailingList user={this.props.user} />
          <Components.EmailReminders user={this.props.user} />
          <Components.Subscription user={this.props.user} />
          <Components.ExportData user={this.props.user} />
          <Components.DeleteAccount user={this.props.user} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(Settings);
