import React from "react";
import { connect } from "react-redux";
import messages from "utils/messages";
import { sendEvent } from "utils/events";
import config from "config";
import * as Components from "components";
import { isBefore } from "date-fns";
import { makeSubscription, confirmSubscription } from "actions/subscriptions";
import { Elements, injectStripe } from "react-stripe-elements";
import { hideModal } from "actions/modal";
import { isPopupExtension } from "utils/extension";
import { isOnline } from "utils/network";
import { getDevice } from "utils/browser";
import stripeLogo from "images/powered_by_stripe.png";
import multiplatform from "images/Multiplatform.png";
import * as Sentry from "@sentry/browser";

class SubscriptionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plan: props.promo && props.promo.price_month ? "month" : "year",
    };
  }

  handleChange(plan) {
    this.setState({ plan });
  }

  async onSubscribe() {
    try {
      if (!isOnline())
        throw new Error(
          "You are offline. You need network connectivity to subscribe"
        );
      const cardElement = this.props.elements.getElement("card");

      this.setState({ subscribing: true, subscribeError: null });

      const paymentInfo = await this.props.stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (paymentInfo.error)
        return this.setState({
          subscribeError: paymentInfo.error.message,
          subscribing: false,
        });

      let planId;
      let promo;

      switch (this.state.plan) {
        case "month":
          planId = config.stripeEDMonthPlanId;
          if (this.props.promo && this.props.promo.price_month)
            promo = this.props.promo;
          break;
        case "year":
          if (this.isGrandfathered()) planId = config.stripeYearPlanId;
          else planId = config.stripeEDYearPlanId;
          if (this.props.promo && this.props.promo.price_year)
            promo = this.props.promo;
          break;
        case "lifetime":
          planId = config.stripeLifetimePlanId;
          break;
        default:
          break;
      }

      const res = await this.props.dispatch(
        makeSubscription({
          paymentInfo,
          plan: this.state.plan,
          planId,
          promo,
          origin: this.props.origin,
        })
      );

      if (res.noRedeemsLeft) {
        this.setState({ subscribing: false });
        this.setState({
          subscribeError: messages.PRE_NO_REDEEMS_LEFT,
        });
        this.props.dispatch({
          type: "REMOVE_PROMO",
        });
      } else if (res.requiresPaymentMethod) {
        this.setState({ subscribing: false });
        this.setState({
          subscribeError: messages.PRE_PAYMENT_FAILED,
        });
      } else if (res.requiresAction) {
        const confirmResponse = await this.props.stripe.confirmCardPayment(
          res.clientSecret
        );
        if (confirmResponse.error) {
          this.setState({ subscribing: false });
          this.setState({
            subscribeError: confirmResponse.error.message,
          });
        } else if (confirmResponse.paymentIntent.status === "succeeded") {
          await this.props.dispatch(
            confirmSubscription({
              plan: this.state.plan,
              stripe_customer_id: res.stripeCustomerId,
              origin: this.props.origin,
            })
          );
          this.props.dispatch(hideModal());
        }
      } else this.props.dispatch(hideModal());
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      this.setState({ subscribing: false });
      this.setState({
        subscribeError: e[0] || e.message || "Interval Server Error",
      });
    }

    return null;
  }

  getCurrentPrice() {
    const promo = this.props.promo;
    const plan = this.state.plan;

    if (plan === "year") {
      if (this.isGrandfathered()) return messages.PRE_YEAR_PRICE_GRANDFATHER;
      if (promo && promo.price_year) return `$${promo.price_year}`;
      return messages.PRE_YEAR_PRICE;
    }
    if (plan === "month") {
      if (promo && promo.price_month) return `$${promo.price_month}`;
      return messages.PRE_MONTH_PRICE;
    }
    if (plan === "lifetime") {
      if (promo && promo.price_lifetime) return `$${promo.price_lifetime}`;
      return messages.PRE_LIFE_PRICE;
    }

    return null;
  }

  isGrandfathered() {
    // checks if user should see old plan
    // to see old plan it must have signed up and before october 1st 2019
    let isGrandfathered = false;
    if (
      isBefore(this.props.user.created_at, new Date("2019-10-01")) &&
      this.props.user.paid_until
    )
      isGrandfathered = true;
    return isGrandfathered;
  }

  renderYearNote() {
    if (this.isGrandfathered()) return null;
    const noteClass = isPopupExtension() ? "note note-popup-extension" : "note";
    const promo = this.props.promo;
    if (promo && promo.price_year) {
      return (
        <a className={noteClass}>
          <a className="save">
            {promo.discount}% {messages.PRE_OFF_BY} {promo.promoter}{" "}
            {messages.PRE_FIRST_YEAR}
          </a>
        </a>
      );
    }

    return (
      <a className={noteClass}>
        {messages.PRE_YEAR_NOTE}
        <a className="save">{messages.PRE_YEAR_SAVE}</a>
      </a>
    );
  }

  renderMonthNote() {
    const promo = this.props.promo;
    const noteClass = isPopupExtension() ? "note note-popup-extension" : "note";
    if (promo && promo.price_month) {
      return (
        <a className={noteClass}>
          <a className="save">
            {promo.discount}% {messages.PRE_OFF_BY} {promo.promoter}{" "}
            {promo.discounted_months > 1
              ? `${messages.PRE_FOR} ${promo.discounted_months} ${messages.PRE_MONTHS}`
              : messages.PRE_FIRST_MONTH}
          </a>
        </a>
      );
    }

    return null;
  }

  renderTotalPromo() {
    const promo = this.props.promo;
    if (!promo || this.isGrandfathered()) return null;

    if (this.state.plan === "year" && promo.price_year)
      return (
        <div className="total-promo">
          <a>
            {promo.discount}% {messages.PRE_OFF_BY} {promo.promoter}
          </a>
          <a className="no-promo-price">{messages.PRE_YEAR_PRICE}</a>
        </div>
      );

    if (this.state.plan === "month" && promo.price_month)
      return (
        <div className="total-promo">
          <a>
            {promo.discount}% {messages.PRE_OFF_BY} {promo.promoter}
          </a>
          <a className="no-promo-price">{messages.PRE_MONTH_PRICE}</a>
        </div>
      );

    return null;
  }

  render() {
    const device = getDevice();
    let optionClassMonth = "option ";
    let optionClassYear = "option ";
    let optionClassLife = "option ";
    if (this.state.plan === "month") optionClassMonth += "selected";
    if (this.state.plan === "year") optionClassYear += "selected";
    if (this.state.plan === "lifetime") optionClassLife += "selected";

    const titleClass = isPopupExtension()
      ? "title title-popup-extension"
      : "title";
    const noteClass = isPopupExtension() ? "note note-popup-extension" : "note";

    return (
      <div className="subscriptionModal">
        <div className="right">
          {this.isGrandfathered() ? (
            <div className="grandfathered">
              <a>{messages.PRE_GRANDFATHER}</a>
              <a className="thank">{messages.PRE_GRANDFATHER2}</a>
              <a>{messages.PRE_GRANDFATHER3}</a>
              <a>{messages.PRE_GRANDFATHER4}</a>
              <a className="signature">{messages.PRE_SIGNATURE}</a>
              <div className="clear" />
            </div>
          ) : (
            <img
              className="multiplatform"
              alt="presentation"
              src={multiplatform}
            />
          )}
          {device !== "we-safari" ? (
            <div>
              <div
                className={optionClassMonth}
                onClick={this.handleChange.bind(this, "month")}
              >
                <div className="left-column">
                  <a className="billing">{messages.PRE_MONTH}</a>
                  {this.renderMonthNote()}
                </div>
                <div className="center-column">
                  <a className="price">{messages.PRE_MONTH_PRICE}</a>
                  {messages.PRE_MONTH_PRICE_TEXT}
                </div>
                <div className="right-column">
                  <a className="promo">{messages.PRE_MONTH_PROMO}</a>
                </div>
              </div>
              <div
                className={optionClassYear}
                onClick={this.handleChange.bind(this, "year")}
              >
                <div className="left-column">
                  <a className="billing">{messages.PRE_YEAR}</a>
                  {this.renderYearNote()}
                </div>
                <div className="center-column">
                  <a className="price">
                    {!this.isGrandfathered()
                      ? messages.PRE_YEAR_PRICE
                      : messages.PRE_YEAR_PRICE_GRANDFATHER}
                  </a>
                  {messages.PRE_YEAR_PRICE_TEXT}
                </div>
                <div className="right-column">
                  <a className="promo">{messages.PRE_YEAR_PROMO}</a>
                </div>
              </div>
              <div
                className={optionClassLife}
                onClick={this.handleChange.bind(this, "lifetime")}
              >
                <div className="left-column">
                  <a className="billing">{messages.PRE_LIFE}</a>
                  <a className={noteClass}>
                    {messages.PRE_LIFE_NOTE}
                    <a className="save">{messages.PRE_LIFE_SAVE}</a>
                  </a>
                </div>
                <div className="center-column">
                  <a className="price">{messages.PRE_LIFE_PRICE}</a>
                  {messages.PRE_LIFE_PRICE_TEXT}
                </div>
                <div className="right-column">
                  <a className="promo">{messages.PRE_LIFE_PROMO}</a>
                </div>
              </div>
              <div className="total">
                {this.renderTotalPromo()}
                <a className="total-no-promo">
                  {messages.PRE_TOTAL}:
                  <a className="total-price">{this.getCurrentPrice()}</a>
                </a>
              </div>
              <Components.CheckoutForm
                ref={(ref) => (this.checkoutForm = ref)}
              />
              {this.state.subscribeError && (
                <div className="subscribe-error">
                  {this.state.subscribeError}
                </div>
              )}
              <div className="buttons">
                {this.state.subscribing ? (
                  <div className="spinner" />
                ) : (
                  <button
                    onClick={this.onSubscribe.bind(this)}
                    type="submit"
                    className="subscribe-button"
                  >
                    {messages.PRE_CONTINUE}
                  </button>
                )}
                <a className="cancel">{messages.PRE_CANCEL}</a>
              </div>
            </div>
          ) : (
            <div>
              <a className="safari-extension-premium">
                Everyday Premium is available through Apple AppStore In-App
                Purchases.
              </a>
              <br></br>
              <a className="safari-extension-premium">
                You can purchase Everyday Premium from the macOS or iOS apps.
              </a>
              <br></br>
              <a className="safari-extension-premium">
                Premium will synchronise with the Safari Web Extension :)
              </a>
              <br></br>
            </div>
          )}
          {device !== "we-safari" && (
            <a href="https://stripe.com" target="_blank" rel="noreferrer">
              <img className="powered" alt="presentation" src={stripeLogo} />
            </a>
          )}
        </div>

        <div className="left">
          <a className={titleClass}>
            {messages.PRE_TITLE}
            <span className="brand">{messages.PRE_TITLE2}</span>
            <Components.Icon
              name="Star"
              color="#249c03"
              height="32"
              width="32"
            />
          </a>
          <a className="feature">
            <Components.Icon
              name="Drop"
              color="#249c03"
              height="26"
              width="26"
            />
            <a className="feat-text">{messages.PRE_FEAT1}</a>
          </a>
          <a className="feature">
            <Components.Icon
              name="Brightness"
              color="#249c03"
              height="26"
              width="26"
            />
            <a className="feat-text">{messages.PRE_FEAT2}</a>
          </a>
          <a className="feature">
            <Components.Icon
              name="Folder"
              color="#249c03"
              height="26"
              width="26"
            />
            <a className="feat-text">{messages.PRE_FEAT3}</a>
          </a>
          <a className="feature">
            <Components.Icon
              name="Rotate"
              color="#249c03"
              height="26"
              width="26"
            />
            <a className="feat-text">{messages.PRE_FEAT4}</a>
          </a>
          <a className="feature">
            <Components.Icon
              name="Activity"
              color="#249c03"
              height="26"
              width="26"
            />
            <a className="feat-text">{messages.PRE_FEAT5}</a>
          </a>
          <a className="feature">
            <Components.Icon
              name="HappyFace"
              color="#249c03"
              height="26"
              width="26"
            />
            <a className="feat-text">{messages.PRE_FEAT6}</a>
          </a>
          <a className="feature">
            <Components.Icon
              name="Rocket"
              color="#249c03"
              height="30"
              width="30"
            />
            <a className="feat-text">{messages.PRE_FEAT7}</a>
          </a>
        </div>

        <div className="clear" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    promo:
      state.promo.promoInfo &&
      !state.promo.promoInfo.trial_months &&
      state.promo.promoInfo,
  };
}

const SubscriptionModalWithStripe = injectStripe(
  connect(mapStateToProps)(SubscriptionModal)
);

export default class StripeWithElements extends React.Component {
  render() {
    return (
      <Elements>
        <SubscriptionModalWithStripe
          {...this.props}
          ref={(ref) => (this.form = ref)}
        />
      </Elements>
    );
  }
}
