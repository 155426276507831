import { isBefore, addDays, addSeconds } from "date-fns";

export default function user(state = {}, action) {
  const isPremium =
    action.user &&
    action.user.premium_access &&
    isBefore(new Date(), addDays(new Date(action.user.premium_access), 3));

  const isGrandfathered =
    action.user &&
    isBefore(action.user.created_at, new Date("2019-10-01")) &&
    action.user.paid_until;

  switch (action.type) {
    case "UPDATE_USER_SUCCESS":
    case "LOGIN_SUCCESS":
      return {
        ...action.user,
        isPremium,
        isGrandfathered,
        server_last_action: action.user.last_action,
        last_action: state.last_action, // not overwrite
      };
    case "MARK_SUCCESS":
    case "UNMARK_SUCCESS":
    case "SKIP_SUCCESS":
    case "ADD_HABIT_SUCCESS":
    case "EDIT_HABIT_SUCCESS":
    case "DELETE_HABIT_SUCCESS":
    case "ARCHIVE":
    case "UNARCHIVE":
    case "UPDATE_USER_LAST_ACTION":
      return { ...state, last_action: addSeconds(new Date(), 1) };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
}
