import React from "react";

export default function Stats({ habits, statsType }) {
  function calcPercentColor(percentage) {
    let className;
    if (percentage < 20) className += " twenty";
    else if (percentage < 40) className += " forty";
    else if (percentage < 60) className += " sixty";
    else if (percentage < 80) className += " eighty";
    else className += " hundred";
    return className;
  }

  function renderRow(habit, index) {
    const row = [];

    let classNameFirst = "";
    let classNameSecond = "";
    let classNameThird = "";
    let statsFirst;
    let statsSecond;
    let statsThird;
    switch (statsType) {
      case 1:
        classNameFirst += habit.color;
        classNameSecond += habit.color;

        if (habit.currentStreak && habit.currentStreak !== 0) {
          classNameFirst += " record";
          if (habit.currentStreak.toString().length > 3) {
            classNameFirst += " big-record";
          }
        }
        if (
          habit.currentStreak &&
          habit.currentStreak === habit.longestStreak &&
          habit.currentStreak !== 0
        ) {
          classNameSecond += " record";
          if (habit.currentStreak.toString().length > 3) {
            classNameSecond += " big-record";
          }
        }

        statsFirst = habit.currentStreak;
        statsSecond = habit.longestStreak;
        statsThird = habit.totalCount;
        break;
      case 2:
        statsFirst = habit.weekCount;
        statsSecond = habit.monthCount;
        statsThird = habit.yearCount;
        break;
      case 3:
        statsFirst = (habit.weekComplRate || 0) + "%";
        statsSecond = (habit.monthComplRate || 0) + "%";
        statsThird = (habit.yearComplRate || 0) + "%";
        classNameFirst += calcPercentColor(habit.weekComplRate || 0);
        classNameSecond += calcPercentColor(habit.monthComplRate || 0);
        classNameThird += calcPercentColor(habit.yearComplRate || 0);
        break;
      default:
        break;
    }

    row.push(
      <tr key={index}>
        <td>
          <h2 className={classNameFirst}>{statsFirst || 0}</h2>
        </td>
        <td>
          <h2 className={classNameSecond}>{statsSecond || 0}</h2>
        </td>
        <td>
          <h2 className={classNameThird}>{statsThird || 0}</h2>
        </td>
      </tr>
    );

    return row;
  }

  return (
    <div className="stats">
      <table>
        <tbody>{habits.map((habit, index) => renderRow(habit, index))}</tbody>
      </table>
    </div>
  );
}
