import React, { useEffect } from "react";
import * as Components from "components";
import * as Pages from "pages";
import config from "config";
import StripeScriptLoader from "react-stripe-script-loader";
import { StripeProvider } from "react-stripe-elements";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import {
  BrowserRouter,
  MemoryRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { isExtension, isPopupExtension } from "utils/extension";
import { useSelector } from "react-redux";
import store from "../../stores";
import { initNetwork, clearNetworkInterval } from "utils/network";
import { GoogleOAuthProvider } from "@react-oauth/google";

function PrivateRoute({ children, ...rest }) {
  let user = useSelector((state) => state.user);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user.id ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  useEffect(() => {
    initNetwork();
    return clearNetworkInterval;
  });

  const Router = BrowserRouter;
  return (
    <GoogleOAuthProvider clientId="924527963873-4hdkc4e7s76gn07o7l7o0ud29ng6em36.apps.googleusercontent.com">
      <Provider store={store}>
        <Router>
          <div className="app">
            <Helmet />
            <div className="wrap">
              <Components.Modal />
              <Components.Banner />
              <Components.Nav />
              <div className="content">
                <Switch>
                  <PrivateRoute exact path="/">
                    <Pages.Board />
                  </PrivateRoute>
                  <PrivateRoute path="/archive">
                    <Pages.Board />
                  </PrivateRoute>
                  <PrivateRoute path="/subscribe">
                    <Pages.Board />
                  </PrivateRoute>
                  <Route path="/googleSignIn">
                    <Pages.GoogleSignIn />
                  </Route>
                  <Route path="/login">
                    <Pages.LogIn />
                  </Route>
                  <Route path="/signup">
                    <Pages.SignUp />
                  </Route>
                  <Route path="/confirm/:token">
                    <Pages.EntryPoint />
                  </Route>
                  <Route path="/unsubscribe-mailing/:token">
                    <Pages.UnsubscribeMailing />
                  </Route>
                  <Route path="/unsubscribe-daily-reminders/:token">
                    <Pages.UnsubscribeReminders />
                  </Route>
                  <Route path="/unsubscribe-weekly-reminders/:token">
                    <Pages.UnsubscribeReminders />
                  </Route>
                  <Route path="/unsubscribe-ds2-reminders/:token">
                    <Pages.UnsubscribeReminders />
                  </Route>
                  <Route path="/unsubscribe-all-reminders/:token">
                    <Pages.UnsubscribeReminders />
                  </Route>
                  <Route path="/unsubscribe-challenge-reminders/:token">
                    <Pages.UnsubscribeReminders />
                  </Route>
                  <PrivateRoute path="/settings">
                    <Pages.Settings />
                  </PrivateRoute>
                  <Route path="/tips">
                    <Pages.Tips />
                  </Route>
                  <Route path="/web-extensions">
                    <Pages.WebExtensions />
                  </Route>
                  <Route path="/:userId/:habitId">
                    <Pages.Habit />
                  </Route>
                </Switch>
              </div>
            </div>
            <Components.Footer />
            <div className="clear" />
          </div>
        </Router>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default function AppWithProviders() {
  const content = (
    <StripeProvider apiKey={config.stripePublishableKey}>
      <App />
    </StripeProvider>
  );

  return (
    <StripeScriptLoader
      uniqueId="myUniqueId"
      script="https://js.stripe.com/v3/"
      loader=""
    >
      {content}
    </StripeScriptLoader>
  );
}
