export function hex2color(hex) {
  let color;
  switch (hex) {
    case "#249c03":
      color = "green";
      break;
    case "#249cff":
      color = "blue";
      break;
    case "#ffa503":
      color = "orange";
      break;
    case "#ff0000":
      color = "red";
      break;
    case "#a64ca6":
      color = "purple";
      break;
    case "#888888":
      color = "grey";
      break;
    case "#329999":
      color = "teal";
      break;
    default:
      color = "green";
  }
  return color;
}

export function color2hex(color) {
  let hex;
  switch (color) {
    case "green":
      hex = "#249c03";
      break;
    case "blue":
      hex = "#249cFF";
      break;
    case "orange":
      hex = "#ffa503";
      break;
    case "red":
      hex = "#ff0000";
      break;
    case "purple":
      hex = "#a64ca6";
      break;
    case "grey":
      hex = "#888888";
      break;
    case "teal":
      hex = "#329999";
      break;
    default:
      hex = "#249c03";
  }
  return hex;
}
