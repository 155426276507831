import mixpanel from "mixpanel-browser";

export async function initMixpanel() {
  // mixpanel.init("8453e714b8eb99c640d0e2e37af0e320");
}

export function setUser(user) {
  if (!user?.id) return;
  //mixpanel.identify(user?.id?.toString());
  // mixpanel.getPeople().set('$email', user?.email);
  // mixpanel.people.set("isPremium", user?.isPremium);
  // mixpanel.getPeople().set('name', user?.name);
}

export default mixpanel;
