import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TwitterPicker } from "react-color";
import { color2hex, hex2color } from "utils/colors";
import { editHabit } from "actions/habits";
import { showModal } from "actions/modal";
import { isChromeExtension, isPopupExtension } from "utils/extension";
import messages from "utils/messages";

export default function MenuItem({ id }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const habit = useSelector((state) => state.habitsEntities[id]);
  const [color, setColor] = useState(color2hex(habit.color));
  const [multiline, setMultiline] = useState(false);
  const history = useHistory();
  const span = useRef();
  const container = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    const spanRef = span.current;
    const isOverflowing = spanRef.offsetWidth < spanRef.scrollWidth;
    if (isOverflowing && spanRef.textContent.indexOf(" ") > 0)
      setMultiline(true);
    else setMultiline(false);
  }, [habit.name]);

  function handleClick() {
    setDisplayColorPicker(!displayColorPicker);
  }

  function handleClose() {
    setDisplayColorPicker(false);
  }

  function handleChange(color) {
    setColor(color.hex);
    const newHabit = { ...habit, color: hex2color(color.hex) };
    dispatch(editHabit(newHabit));
    setDisplayColorPicker(false);
  }

  function showEditModal() {
    dispatch(showModal("editHabit", { title: messages.EDIT_HABIT, habit }));
  }

  function goToHabitPage() {
    if (!isPopupExtension()) history.push(`${user.id}/${habit.id}`);
  }

  const colors = [
    "#249c03",
    "#249cFF",
    "#ffa503",
    "#ff0000",
    "#a64ca6",
    "#888888",
    "#329999",
  ];

  const html = (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      key={id}
      className={"menuItemLI " + (!isChromeExtension() && "not-extension")}
    >
      <div className="menuItem" id="controls">
        <i className="ion-edit" onClick={showEditModal} />
      </div>
      <div className="color-picker-wrapper" id="controls" onClick={handleClick}>
        <div className="color-picker" style={{ backgroundColor: color }} />
      </div>
      {displayColorPicker ? (
        <div className="popover">
          <div className="cover" onClick={handleClose} />
          <div className="picker-wrapper">
            <TwitterPicker
              className="twitter-picker"
              colors={colors}
              onChange={handleChange}
            />
          </div>
        </div>
      ) : null}
      <a className="draggable" onClick={goToHabitPage}>
        {multiline ? (
          <div ref={container}>
            <span className="long" ref={span}>
              {habit.name}
            </span>
            <div className="above" />
          </div>
        ) : (
          <div ref={container}>
            <span ref={span}>{habit.name}</span>
            <div className="above" />
          </div>
        )}
      </a>
    </li>
  );

  return html;
}
