import React from "react";
import messages from "utils/messages";
import { connect } from "react-redux";
import { exportData } from "actions/users";
import { showModal } from "actions/modal";

class ExportData extends React.Component {
  export() {
    if (!this.props.user.isPremium) {
      this.props.dispatch(
        showModal("premiumFeature", {
          class: "premiumfeature",
          type: "export",
        })
      );
    }
    this.props.dispatch(exportData());
  }

  render() {
    return (
      <div className="export-data">
        <div className="block">
          <i className="ion-ios-download-outline" />
          <h3>{messages.S_EXPORT_DATA}</h3>
          <a className="text">{messages.S_EXPORT_DATA_TEXT}</a>
          <div className="line" />
        </div>
        <div className="block">
          <div className="export-data-actions">
            <button type="button" onClick={this.export.bind(this)}>
              {messages.S_EXPORT}
            </button>
          </div>
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default connect((state) => ({ variables: state.variables }))(ExportData);
