import {
  addDays,
  subDays,
  format,
  isBefore,
  isAfter,
  isSameDay,
} from "date-fns";
import constants from "common/constants";
import { stringToLocaltimeDate } from "utils/dates";
import { editHabitSuccess } from "./habits";

export function calculateNewStreaks({
  dates,
  habit,
  baseDate,
  isNewHabit = false,
}) {
  const newHabit = { ...habit };
  if (!isNewHabit) {
    // Calculating longestStreak (longest in frontend (longest than 365...?))
    // Calculating counts and completion rates
    let currentStreak = 0;
    let longestStreak = 0;
    let totalCount = 0;
    let weekCount = 0;
    let monthCount = 0;
    let yearCount = 0;
    let skipWeekCount = 0;
    let skipMonthCount = 0;
    let skipYearCount = 0;
    let archivedYearCount = 0;
    let monthBase = 0;
    let yearBase = 0;
    let weekBase = 0;
    const numDays = constants.numDays;

    const today = new Date();
    // let currentDate = new Date(baseDate);

    let currentDate = stringToLocaltimeDate(baseDate);
    const weekBaseDate = subDays(today, 7);
    const monthBaseDate = subDays(today, 30);
    const yearBaseDate = subDays(today, 365);
    let initialDate = habit.initialDate
      ? stringToLocaltimeDate(habit.initialDate)
      : null;

    for (
      let i = 0, streak = habit.baseCurrentStreak;
      isBefore(currentDate, today);
      currentDate = addDays(currentDate, 1), i++
    ) {
      const currentDateStr = format(currentDate, "YYYY-MM-DD");
      // console.error({ currentDate, currentDateStr, yearBaseDate });
      // LONGEST STREAK
      if (dates && dates[currentDateStr]) {
        // if date marked increment streak
        if (dates[currentDateStr].isMarked) {
          streak++;
          totalCount++;
        }

        if (!initialDate) {
          initialDate = currentDate;
        }
      } else if (!(i === numDays)) {
        streak = 0;
      }

      currentStreak = streak;
      if (currentStreak > longestStreak) longestStreak = currentStreak;

      // WEEK COUNT
      // Miro que data sigui posterior als últims 7 dies
      // i a partir de la primera data marcada
      if (
        isAfter(currentDate, weekBaseDate) &&
        initialDate &&
        (isSameDay(currentDate, initialDate) ||
          isAfter(currentDate, initialDate))
      ) {
        if (dates && dates[currentDateStr] && dates[currentDateStr].isSkip)
          skipWeekCount++;
        if (dates && dates[currentDateStr] && dates[currentDateStr].isMarked)
          weekCount++;
        weekBase++;
      }

      // MONTH COUNT
      if (
        isAfter(currentDate, monthBaseDate) &&
        initialDate &&
        (isSameDay(currentDate, initialDate) ||
          isAfter(currentDate, initialDate))
      ) {
        if (dates && dates[currentDateStr] && dates[currentDateStr].isSkip)
          skipMonthCount++;
        if (dates && dates[currentDateStr] && dates[currentDateStr].isMarked)
          monthCount++;
        monthBase++;
      }

      // YEAR COUNT
      if (
        isAfter(currentDate, yearBaseDate) &&
        initialDate &&
        (isSameDay(currentDate, initialDate) ||
          isAfter(currentDate, initialDate))
      ) {
        if (
          habit.archived &&
          (isSameDay(currentDate, new Date(habit.archived)) ||
            isAfter(currentDate, new Date(habit.archived)))
        ) {
          archivedYearCount++;
        }

        if (dates && dates[currentDateStr] && dates[currentDateStr].isSkip)
          skipYearCount++;
        if (dates && dates[currentDateStr] && dates[currentDateStr].isMarked)
          yearCount++;
        yearBase++;
      }
    }

    newHabit.totalCount = (habit.baseTotalCount || 0) + totalCount;
    newHabit.currentStreak = currentStreak;
    newHabit.longestStreak =
      habit.baseLongestStreak > longestStreak
        ? habit.baseLongestStreak
        : longestStreak;
    newHabit.weekCount = weekCount;
    newHabit.monthCount = (habit.baseMonthCount || 0) + monthCount;
    newHabit.yearCount = (habit.baseYearCount || 0) + yearCount;
    const newWeekBase = weekBase - skipWeekCount;
    const newMonthBase = monthBase - (habit.skipMonthCount + skipMonthCount);
    const newYearBase =
      yearBase - (habit.skipYearCount + skipYearCount) - archivedYearCount;

    newHabit.weekComplRate = Math.round((weekCount / newWeekBase) * 100) || 0;
    newHabit.monthComplRate = newMonthBase
      ? Math.round((newHabit.monthCount / newMonthBase) * 100)
      : 0;
    newHabit.yearComplRate = newYearBase
      ? Math.round((newHabit.yearCount / newYearBase) * 100)
      : 0;
  } else {
    // If is a new habit / no dates
    newHabit.currentStreak = 0;
    newHabit.longestStreak = 0;
    newHabit.weekCount = 0;
    newHabit.monthCount = 0;
    newHabit.yearCount = 0;
    newHabit.weekComplRate = 0;
    newHabit.monthComplRate = 0;
    newHabit.yearComplRate = 0;
    newHabit.totalCount = 0;
    newHabit.baseLongestStreak = 0;
    newHabit.baseCurrentStreak = 0;
    newHabit.baseYearCount = 0;
    newHabit.baseMonthCount = 0;
    newHabit.baseTotalCount = 0;
    newHabit.skipMonthCount = 0;
    newHabit.skipYearCount = 0;
    newHabit.monthBase = 0;
    newHabit.yearBase = 0;
  }
  return newHabit;
}

export function updateStreak(habitId) {
  return (dispatch, getState) => {
    const state = getState();
    const dates = state.dates[habitId];
    const baseDate = state.basedate;
    const habit = state.habitsEntities[habitId];
    const newHabit = calculateNewStreaks({ habit, dates, baseDate });
    dispatch(editHabitSuccess(newHabit));
  };
}
