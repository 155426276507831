import request from "utils/request";
import { subDays, format, differenceInCalendarDays } from "date-fns";
import {
  fetchDatesSuccess,
  autoskip,
  getPendingActions,
  resetPendingActions,
  markSuccess,
  unmarkSuccess,
  skipSuccess,
} from "actions/dates";
import { calculateNewStreaks, updateStreak } from "actions/stats";
import constants from "common/constants";
import history from "utils/history";
import { hasPendingDataToSync } from "utils/network";
import { sendEvent } from "utils/events";

export function fetchHabitsSuccess(habits, habitsHash) {
  return {
    type: "FETCH_HABITS_SUCCESS",
    habits,
    habitsHash,
  };
}

export function fetchHabitSuccess(habit) {
  return {
    type: "FETCH_HABIT_SUCCESS",
    habit,
  };
}

export function addHabitSuccess(habit) {
  return {
    type: "ADD_HABIT_SUCCESS",
    habit,
  };
}

export function editHabitSuccess(habit) {
  return {
    type: "EDIT_HABIT_SUCCESS",
    habit,
  };
}

export function deleteHabitSuccess(habit_id) {
  return {
    type: "DELETE_HABIT_SUCCESS",
    habit_id,
  };
}

export function moveHabit(dragIndex, hoverIndex, dragHabit) {
  return {
    type: "MOVE_HABIT",
    dragIndex,
    hoverIndex,
    dragHabit,
  };
}

// Actions to handle queueing to avoid overwriting when dates get fetched
export function fetchingHabits() {
  return {
    type: "FETCHING_HABITS",
  };
}

export function notFetchingHabits() {
  return {
    type: "NOT_FETCHING_HABITS",
  };
}

export function clearQueue() {
  return {
    type: "CLEAR_QUEUE",
  };
}

export function initHabit(habit) {
  const newHabit = { ...habit };

  newHabit.currentStreak = 0;
  newHabit.longestStreak = 0;
  newHabit.weekCount = 0;
  newHabit.monthCount = 0;
  newHabit.yearCount = 0;
  newHabit.weekComplRate = 0;
  newHabit.monthComplRate = 0;
  newHabit.yearComplRate = 0;
  newHabit.totalCount = 0;
  newHabit.baseLongestStreak = 0;
  newHabit.baseCurrentStreak = 0;
  newHabit.baseYearCount = 0;
  newHabit.baseMonthCount = 0;
  newHabit.baseTotalCount = 0;
  newHabit.skipMonthCount = 0;
  newHabit.skipYearCount = 0;
  newHabit.monthBase = 0;
  newHabit.yearBase = 0;

  return newHabit;
}
//
export function createAutoSkips(habitId) {
  return async (dispatch, getState) => {
    const state = getState();
    const habits = state.habits.habits
      .filter((id) => !habitId || id === habitId)
      .map((id) => state.habitsEntities[id]);

    const lastAction = new Date(state.user.last_action);
    let numDays = differenceInCalendarDays(new Date(), lastAction);
    const today = new Date();
    while (numDays >= 0) {
      const currentDate = subDays(today, numDays);
      const dateStr = format(currentDate, "YYYY-MM-DD");
      const dayName = format(currentDate, "dddd").toLowerCase();

      for (const habit of habits) {
        if (
          !habit[dayName] &&
          !(state.dates[habit.id] && state.dates[habit.id][dateStr])
        ) {
          dispatch(
            autoskip({
              date: dateStr,
              habit_id: habit.id,
              device: "web",
            })
          );
        }
      }

      numDays--;
    }
  };
}

export function fetchHabit({ userId, habitId }) {
  const baseDate = subDays(new Date(), constants.numDays);
  const formattedBaseDate = format(baseDate, "YYYY-MM-DD");

  return async (dispatch) => {
    try {
      dispatch(fetchingHabits());
      const res = await request({
        url: `habitsV2?&base_date=${formattedBaseDate}&user_id=${userId}&habit_id=${habitId}`,
        method: "GET",
        preventSpam: true,
      });

      if (!res) return;

      const { habitsArray, datesHash } = res;

      const habit = habitsArray[0];
      const habitWithStats = calculateNewStreaks({
        dates: datesHash[habit.id],
        habit,
        baseDate: formattedBaseDate,
      });

      dispatch(
        fetchDatesSuccess({
          dates: datesHash,
          baseDate: formattedBaseDate,
          overwrite: false,
        })
      );
      dispatch(fetchHabitSuccess(habitWithStats));
    } catch (e) {
      history.replace("/");
    }
  };
}

export function fetchHabits() {
  return async (dispatch, getState) => {
    try {
      sendEvent("debug:fetchingstart", {
        pendingData: hasPendingDataToSync(),
      });
      if (hasPendingDataToSync()) return;
      dispatch(fetchingHabits());

      const baseDate = subDays(new Date(), constants.numDays);
      const formattedBaseDate = format(baseDate, "YYYY-MM-DD");

      const stateb = getState();
      console.log("FH:", stateb.queue.fetchingHabits);

      const res = await request({
        url: `habitsV2?&base_date=${formattedBaseDate}`,
        method: "GET",
        action: "FETCH_HABITS",
        requiresAuth: true,
      });

      if (!res) {
        dispatch({ type: "NOT_FETCHING_HABITS" });
        return;
      }

      sendEvent("debug:fetchingend");

      const { habitsArray, habitsHash, datesHash } = res;

      dispatch(
        fetchDatesSuccess({ dates: datesHash, baseDate: formattedBaseDate })
      );

      const habits = habitsArray.map((habit) => {
        const habitWithStats = calculateNewStreaks({
          dates: datesHash[habit.id],
          habit,
          baseDate: formattedBaseDate,
        });
        habitsHash[habit.id] = habitWithStats;
        return habitWithStats;
      });

      console.log("fetching habits success");
      dispatch(fetchHabitsSuccess(habits, habitsHash));

      const state = getState();

      const pendingActions = getPendingActions();

      console.log("pendingActions", pendingActions);

      // user actions while fetching habits
      for (const pendingAction of pendingActions) {
        const { action, date, habit_id } = pendingAction;
        const habit = state.habitsEntities[habit_id];
        const habitDates = state.dates[habit_id];

        if (action === "mark") {
          dispatch(markSuccess(date, habit));
        }
        if (action === "unmark") {
          dispatch(unmarkSuccess(date, habit));
        }
        if (action === "skip") {
          dispatch(skipSuccess(date, habit, habitDates));
        }
        if (action === "autoskip") {
          dispatch(skipSuccess(date, habit, habitDates));
        }

        dispatch(updateStreak(habit.id));
      }

      resetPendingActions();

      dispatch(createAutoSkips());
      dispatch({ type: "UPDATE_USER_LAST_ACTION" });
    } catch (e) {
      console.log(e);
    }
  };
}

export function addHabit(habit) {
  return async (dispatch, getState) => {
    try {
      const newHabit = initHabit(habit);

      newHabit.id = Math.floor(1000000 + Math.random() * 9000000); // random tmp id
      newHabit.tmp = true;

      dispatch(addHabitSuccess(newHabit));

      sendEvent("action:newhabit");

      await request({
        url: "habits",
        method: "POST",
        action: "ADD_HABIT",
        body: newHabit,
        habitId: newHabit.id,
      });

      dispatch(createAutoSkips(newHabit.id));
    } catch (e) {}
  };
}

export function sortHabits() {
  return async (dispatch, getState) => {
    const state = getState();

    sendEvent("action:sort");

    await request({
      url: "sortHabits",
      method: "PUT",
      action: "SORT_HABITS",
      body: state.habits.habits,
    });
  };
}

export function editHabit(habit) {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      state = getState();

      const oldHabit = state.habitsEntities[habit.id];

      if (oldHabit.archived && !habit.archived) {
        dispatch({
          type: "UNARCHIVE",
          id: habit.id,
        });
      }

      if (!oldHabit.archived && habit.archived) {
        dispatch({
          type: "ARCHIVE",
          id: habit.id,
        });
      }

      sendEvent("action:edithabit", {
        habitId: habit.id,
      });

      dispatch(editHabitSuccess(habit));
      dispatch(createAutoSkips(habit.id));

      if (
        oldHabit.color !== habit.color ||
        oldHabit.break_habit !== habit.break_habit
      )
        dispatch({
          type: "UPDATE_HABIT_COLOR",
          habit: habit,
        });

      await request({
        action: "EDIT_HABIT",
        url: "habits/" + habit.id,
        method: "PUT",
        body: habit,
        habitId: habit.id,
      });
    } catch (errors) {
      console.log("edit habit failed", errors);
      if (errors instanceof Error) {
        // sentry.captureException(errors);
      }
    }
  };
}

export function deleteHabit(habit_id) {
  return async (dispatch, getState) => {
    try {
      dispatch(deleteHabitSuccess(habit_id));
      sendEvent("action:deletehabit", {
        habitId: habit_id,
      });
      await request({
        action: "DELETE_HABIT",
        url: "habits/" + habit_id,
        method: "DELETE",
        habitId: habit_id,
      });
    } catch (e) {
      if (e instanceof Error) {
        console.log("delete habit failed", e);
        // sentry.captureException(errors);
      }
    }
  };
}
