import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchHabit, editHabit } from "actions/habits";
import YearView from "components/YearView/YearView";
import SignUp from "components/SignUp/SignUp";
import messages from "utils/messages";
import config from "config";
import request from "utils/request";

export default function Habit() {
  const user = useSelector((state) => state.user);
  const { habitId, userId: userParam } = useParams();

  const userId = user.id || userParam;
  const baseDate = useSelector((state) => state.basedate);
  const habit = useSelector((state) => state.habitsEntities[habitId]);
  const habitDates = useSelector((state) => state.dates[habitId] || {});
  const fetchingHabits = useSelector((state) => state.queue.fetchingHabits);
  const dispatch = useDispatch();

  console.log("params", useParams());

  useEffect(() => {
    if (!habit && habitId) dispatch(fetchHabit({ habitId, userId }));
  }, [habitId, dispatch, habit, userId]);

  function generateScreenshot() {
    return request({
      url: "habits/generateYearViewScreenshot",
      method: "POST",
      body: {
        habitId,
        userId,
      },
    });
  }

  function changeVisibility() {
    return dispatch(editHabit({ ...habit, is_public: !habit.is_public }));
  }

  const isGuest = parseInt(userId, 10) !== user.id;

  if (!habit) return null;

  return (
    <div className="habit-page">
      {!fetchingHabits ? (
        <div>
          <YearView
            changeVisibility={changeVisibility}
            twitterShareUrl={`${config.appUrl}/${userId}/${habitId}`}
            downloadImgUrl={`${config.s3Url}/${userId}/${habitId}.png`}
            isGuest={isGuest}
            generateScreenshot={generateScreenshot}
            habit={habit}
            habitDates={habitDates}
            baseDate={baseDate}
          />
        </div>
      ) : (
        <div className="spinner" />
      )}
      {!user.id ? (
        <div className="wantyourboard">
          <h2 className="wantyours">{messages.WANTYOURS}</h2>
          <SignUp />
        </div>
      ) : null}
    </div>
  );
}
