import request from "utils/request";
import { updateStreak } from "actions/stats";
import { showModal } from "actions/modal";
import { isExtension } from "utils/extension";
import { getDevice } from "utils/browser";
import * as storage from "utils/storage";
import { isBefore, addDays, format } from "date-fns";
import { sendEvent } from "utils/events";

let pendingActions = [];

export function getPendingActions() {
  return pendingActions;
}

export function resetPendingActions() {
  pendingActions = [];
}

export function fetchDatesSuccess({ dates, baseDate, overwrite = true }) {
  return {
    type: "FETCH_DATES_SUCCESS",
    dates,
    baseDate,
    overwrite,
  };
}

export function markSuccess(date, habit) {
  return {
    type: "MARK_SUCCESS",
    date,
    habit,
  };
}

export function unmarkSuccess(date, habit) {
  return {
    type: "UNMARK_SUCCESS",
    date,
    habit,
  };
}

export function skipSuccess(date, habit) {
  return {
    type: "SKIP_SUCCESS",
    date,
    habit,
  };
}

export function autoskip({ date, habit_id }) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const device = await getDevice();

      dispatch(
        skipSuccess(date, state.habitsEntities[habit_id], state.dates[habit_id])
      );
      dispatch(updateStreak(habit_id));
      await request({
        url: "autoskip",
        method: "POST",
        action: "AUTOSKIP",
        body: {
          date,
          habit_id,
          device,
        },
        habitId: habit_id,
      });
    } catch (e) {
      console.error(e);
      console.log("autoskip failed", e);
      // sentry.captureException(new Error(e));
    }
  };
}

export function mark(date, habit_id, os) {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      const dateObject = {
        date,
        habit_id,
        device: await getDevice(),
      };

      sendEvent("action:mark", {
        habitId: habit_id,
        date,
        device: "web",
      });

      if (state.queue.fetchingHabits) {
        pendingActions.push({
          action: "mark",
          ...dateObject,
        });
      }

      if (
        state.dates[habit_id] &&
        state.dates[habit_id][date] &&
        state.dates[habit_id][date].isMarked
      )
        return null;
      // Optimistic update mark a habit
      dispatch(markSuccess(dateObject.date, state.habitsEntities[habit_id]));
      dispatch(updateStreak(habit_id));

      // server request. It will be captured if offline
      request({
        url: "mark",
        method: "POST",
        action: "MARK",
        body: dateObject,
        habitId: habit_id,
      });

      const habit = state.habitsEntities[habit_id];
      const review = storage.get("edc_review");

      if (habit.currentStreak >= 5) {
        if (isExtension()) {
          if (
            !review ||
            (!review.hasReviewed &&
              isBefore(
                addDays(new Date(review.lastReviewRequest), 7),
                new Date()
              ))
          ) {
            dispatch(showModal("review"));
            storage.set({
              key: "edc_review",
              persist: true,
              value: { lastReviewRequest: format(new Date(), "YYYY-MM-DD") },
            });
          }
        }
      }
    } catch (e) {
      console.log("mark failed", e);
      // sentry.captureException(new Error(e));
    }
  };
}

export function unmark(date, habit_id) {
  const dateObject = {
    date,
    habit_id,
  };
  return async (dispatch, getState) => {
    const state = getState();

    if (state.queue.fetchingHabits) {
      pendingActions.push({
        action: "unmark",
        ...dateObject,
      });
    }

    // Optimistic update unmark a habit
    dispatch(unmarkSuccess(dateObject.date, state.habitsEntities[habit_id]));
    dispatch(updateStreak(habit_id));

    sendEvent("action:unmark", {
      habitId: habit_id,
      date,
    });

    request({
      url: "unmark",
      method: "POST",
      action: "UNMARK",
      habitId: habit_id,
      body: dateObject,
    });
  };
}

export function skip(date, habit_id) {
  const dateObject = {
    date,
    habit_id,
  };
  return async (dispatch, getState) => {
    const state = getState();

    const infoModals = storage.get("edc_info_modals");

    if (!infoModals || !infoModals.skip) {
      dispatch(showModal("skip", { delay: 500 }));
      storage.set({
        key: "edc_info_modals",
        persist: true,
        value: { ...infoModals, skip: true },
      });
    }

    if (state.queue.fetchingHabits) {
      pendingActions.push({
        action: "skip",
        ...dateObject,
      });
    }

    // Optimistic update skip a habit
    dispatch(
      skipSuccess(
        dateObject.date,
        state.habitsEntities[habit_id],
        state.dates[habit_id]
      )
    );

    sendEvent("action:skip", {
      habitId: habit_id,
      date,
    });

    // Retrieving new current and longest Streaks from state
    dispatch(updateStreak(habit_id));

    request({
      url: "skip",
      method: "POST",
      action: "SKIP",
      habitId: habit_id,
      body: dateObject,
    });
  };
}
