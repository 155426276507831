import React from "react";
import messages from "utils/messages";
import Icon from "components/Icon/Icon";
import * as storage from "utils/storage";
import { getBrowser } from "../../utils/browser";

export default class ReviewModal extends React.Component {
  review() {
    storage.set({
      key: "edc_review",
      persist: true,
      value: {
        hasReviewed: true,
      },
    });

    const browser = getBrowser();
    if (browser === "safari") return;

    if (window.chrome) {
      window.open(
        "https://chrome.google.com/webstore/detail/everydayapp-do-it-every-d/kpcjjapfjdgjlmoehhliomplgjnbdcpc"
      );
    } else {
      window.open(
        "https://addons.mozilla.org/en-US/firefox/addon/everydaycheck-do-it-every-day"
      );
    }
    this.props.onHide();
  }

  render() {
    return (
      <div className="reviewModal">
        <div className="stars">
          <Icon name="Star" fill="var(--main-dark)" color="var(--main-dark)" />
          <Icon name="Star" fill="var(--main-dark)" color="var(--main-dark)" />
          <Icon name="Star" fill="var(--main-dark)" color="var(--main-dark)" />
          <Icon name="Star" fill="var(--main-dark)" color="var(--main-dark)" />
          <Icon name="Star" fill="var(--main-dark)" color="var(--main-dark)" />
        </div>
        <a className="review-modal-text">{messages.MODAL_REVIEW}</a>
        <div className="review-buttons">
          <a onClick={this.review.bind(this)} className="modal-button">
            {messages.REVIEW}
          </a>
          <a onClick={this.props.onHide} className="modal-button cancel">
            {messages.CANCEL}
          </a>
        </div>
      </div>
    );
  }
}
