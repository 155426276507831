export function showModal(modal, props) {
  return {
    type: "SHOW_MODAL",
    modal,
    props,
  };
}

export function hideModal(modal, props) {
  return {
    type: "HIDE_MODAL",
    modal,
    props
  };
}

export function clearErrors() {
  return {
    type: "CLEAR_ERRORS"
  };
}
