import BtModal from "react-bootstrap/lib/Modal";
import React from "react";
import { hideModal, clearErrors } from "actions/modal";
import { connect } from "react-redux";
import { isPopupExtension } from "utils/extension";

import {
  NewHabitModal,
  ConfirmDeleteModal,
  SubscriptionModal,
  ConfirmDeactivateModal,
  PremiumFeatureModal,
  OnboardingModal,
  ReviewModal,
  SkipModal,
} from "components";

class Modal extends React.Component {
  handleHide() {
    this.props.dispatch(hideModal());
    this.props.dispatch(clearErrors());
  }

  render() {
    const component = this.props.modal;
    const props = this.props.props || {};

    let Component;

    switch (component) {
      case "newHabit":
        Component = NewHabitModal;
        break;
      case "editHabit":
        Component = NewHabitModal;
        break;
      case "deleteHabit":
        Component = ConfirmDeleteModal;
        break;
      case "subscription":
        Component = SubscriptionModal;
        break;
      case "confirmDeactivate":
        Component = ConfirmDeactivateModal;
        break;
      case "premiumFeature":
        Component = PremiumFeatureModal;
        break;
      case "skip":
        Component = SkipModal;
        break;
      case "onboarding":
        Component = OnboardingModal;
        break;
      case "review":
        Component = ReviewModal;
        break;
      default:
        break;
    }

    let className = "";
    if (props.showBrightModal) className = "showBrightModal";
    let modalClass = props.class;
    if (modalClass === "premium" && isPopupExtension())
      modalClass = "premium-popup-extension";

    return Component ? (
      <BtModal
        show
        onHide={this.handleHide.bind(this)}
        className={modalClass}
        backdropClassName={className}
      >
        {props.title ? (
          <BtModal.Header closeButton>
            <a className="modal-title">{props && props.title}</a>
          </BtModal.Header>
        ) : (
          <BtModal.Header className="noborder" closeButton />
        )}
        <Component {...props} onHide={this.handleHide.bind(this)} />
      </BtModal>
    ) : null;
  }
}

function mapStateToProps(state) {
  return state.modal;
}

export default connect(mapStateToProps)(Modal);
