import React, { useState } from "react";
import { TwitterShareButton } from "react-share";
import messages from "utils/messages";
import { getBrowser } from "utils/browser";
import moment from "moment";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import { isWeekend } from "date-fns";

export default function YearView({
  changeVisibility,
  generateScreenshot,
  habit,
  habitDates,
  baseDate,
  twitterShareUrl,
  downloadImgUrl,
  isGuest,
}) {
  const [downloading, setDownloading] = useState(false);
  const [showDownloadInfo, setShowDownloadInfo] = useState(false);
  const browser = getBrowser();

  function calcPercentColor(percentage) {
    let className = "";
    if (percentage < 20) className += " twenty";
    else if (percentage < 40) className += " forty";
    else if (percentage < 60) className += " sixty";
    else if (percentage < 80) className += " eighty";
    else className += " hundred";
    return className;
  }

  function getHeaderSpan(offset) {
    let currentOffset = offset;
    let currentDay = moment(baseDate).add(offset, "days");
    let currentMonth = currentDay.format("MMM");
    let colspan = 0;
    const month = currentMonth;

    while (month === currentMonth) {
      colspan++;
      currentOffset += 7;
      currentDay = moment(baseDate).add(currentOffset, "days");
      currentMonth = currentDay.format("MMM");
    }

    return colspan;
  }

  async function download() {
    setDownloading(true);
    await generateScreenshot();
    setDownloading(false);
    setShowDownloadInfo(true);
  }

  async function share() {
    if (!habit.is_public) await changeVisibility();
    generateScreenshot();
  }

  function renderBoardHeader() {
    const html = [<th key="yv-header-0" />];
    let month = "";

    for (let week = 1; week <= 53; week++) {
      const offset = (week - 1) * 7;
      const currentDay = moment(baseDate).add(offset, "days");

      const currentMonth = currentDay.format("MMM");
      if (currentMonth !== month) {
        html.push(
          <th
            className="cell month"
            key={`yv-header-${week}`}
            colSpan={getHeaderSpan(offset)}
          >
            {currentMonth}
          </th>
        );
      }
      month = currentMonth;
    }

    return html;
  }

  function renderDay(day) {
    const html = [];
    const firstMonday = moment(baseDate).subtract(
      moment(baseDate).isoWeekday() - 1, // moment.isoWeekday() -> sunday = 7
      "days"
    );

    const dayOfWeekUnformatted = moment().isoWeekday(day);
    if (
      dayOfWeekUnformatted.day() === 2 ||
      dayOfWeekUnformatted.day() === 4 ||
      dayOfWeekUnformatted.day() === 6
    ) {
      const dayOfWeek = dayOfWeekUnformatted.format("ddd");

      html.push(
        <td key={`yv-board-day-${day}`} className="cell day-of-week">
          {dayOfWeek}
        </td>
      );
    } else
      html.push(
        <td key={`yv-board-day-${day}`} className="cell day-of-week" />
      );
    for (let week = 1; week <= 54; week++) {
      const offset = day - 1 + (week - 1) * 7;
      const currentDayUnformatted = moment(firstMonday).add(offset, "days");
      const currentDay = currentDayUnformatted.format("YYYY-MM-DD");

      if (
        moment(baseDate).add(1, "year").isBefore(currentDayUnformatted) ||
        moment(baseDate).isAfter(currentDayUnformatted)
      ) {
        html.push(
          <td className="cell cell-white" key={`yv-board-${day * week}`} />
        );
        continue;
      }

      let className = `cell ${habit.color}`;
      const date = habitDates[currentDay];

      if (date) {
        if (isWeekend(new Date(currentDay))) className += " weekend";
        if (date.isMarked) className += " done";
        if (date.isSkip) className += " skip";
        if (browser === "firefox") className += " firefox";
      }

      html.push(
        <td
          key={`yv-board-${day * week}`}
          className={className}
          style={{ backgroundColor: date && date.color }}
        />
      );
    }
    return html;
  }

  function renderBoard() {
    const html = [];
    for (let day = 1; day <= 7; day++) {
      html.push(<tr key={day}>{renderDay(day)}</tr>);
    }
    return html;
  }

  let classNameStreak = habit.color + " number";
  if (
    habit.currentStreak &&
    habit.currentStreak === habit.longestStreak &&
    habit.currentStreak !== 0
  ) {
    classNameStreak += " currentlongest";
    classNameStreak += " currentlongest";
  }

  const classComplRate = "number " + calcPercentColor(habit.yearComplRate);
  const twitterTitle =
    messages.SHARE_TWITTER_1 +
    '"' +
    habit.name +
    '"' +
    messages.SHARE_TWITTER_2 +
    " @everydayCheck";

  return (
    <div className="yearview-layout">
      {!isGuest ? (
        <div className="goback">
          <Link to="/">{messages.GOBACK}</Link>
        </div>
      ) : null}
      {!isGuest ? (
        <div className="switch-visibility">
          <Switch
            height={15}
            width={35}
            uncheckedIcon={false}
            onChange={changeVisibility}
            checked={habit.is_public}
          />
          <i className={habit.is_public ? "ion-eye" : "ion-eye-disabled"} />
        </div>
      ) : null}

      <div className="yearview">
        <div className="habit-title">{habit.name}</div>
        <div className="branding">
          <span className="imageby">{messages.IMAGEBY}</span>
          <span className="logo" />
          <span className="brand">everyday.app</span>
        </div>
        <table className="yvboard">
          <thead>
            <tr>{renderBoardHeader()}</tr>
          </thead>
          <tbody>{renderBoard()}</tbody>
        </table>
        <div className="current-streak stat">
          <div className={classNameStreak}>{habit.currentStreak}</div>
          <p className="meta">{messages.CURRENT_STREAK}</p>
        </div>
        <div className="longest-streak stat">
          <div className={classNameStreak}>{habit.longestStreak}</div>
          <p className="meta">{messages.LONGEST_STREAK}</p>
        </div>
        <div className="total-count stat">
          <div className="number">{habit.totalCount}</div>
          <p className="meta">{messages.TOTAL_COUNT}</p>
        </div>
        <div className="completion-rate stat">
          <div className={classComplRate}>{habit.yearComplRate}%</div>
          <p className="meta">{messages.COMPLETION_RATE}</p>
        </div>
      </div>

      <div className="download" onClick={download} onKeyDown={download}>
        {downloading ? messages.YV_DOWNLOADING : messages.YV_DOWNLOAD}
      </div>

      <div className="share">
        <p>{messages.YV_SHARE}:</p>
        <TwitterShareButton
          className="twitter-button"
          url={twitterShareUrl}
          beforeOnClick={share}
          title={twitterTitle}
          hashtags={["everyday"]}
        >
          <i className="ion-social-twitter" />
        </TwitterShareButton>
      </div>
      {!downloading && showDownloadInfo && (
        <div className="download-info">
          <a>{messages.SHARE_IMAGE_STEP_1}</a>
          <img alt="img" src={downloadImgUrl} />
          <a>{messages.SHARE_IMAGE_STEP_2}</a>
          <a>
            {messages.SHARE_LINK}{" "}
            <a className="link" href={twitterShareUrl}>
              {twitterShareUrl}
            </a>
          </a>
        </div>
      )}
    </div>
  );
}
