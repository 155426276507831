import React from "react";
import messages from "utils/messages";
import { connect } from "react-redux";
import { showModal } from "actions/modal";

class DeleteAccount extends React.Component {
  deactivate() {
    this.props.dispatch(
      showModal("confirmDeactivate", { title: messages.S_DEACTIVATE })
    );
  }

  render() {
    return (
      <div className="delete-account">
        <div className="block">
          <i className="ion-ios-trash-outline" />
          <h3>{messages.S_DEACTIVATE}</h3>
          <a className="text">{messages.S_DEACT_TEXT}</a>
          <div className="line" />
        </div>
        <div className="block">
          <div className="actions">
            <a className="deactivate" onClick={this.deactivate.bind(this)}>
              {messages.S_DEACT_BUTTON}
            </a>
          </div>
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default connect()(DeleteAccount);
