import React from "react";
import { connect } from "react-redux";
import { deleteHabit } from "actions/habits";
import messages from "utils/messages";

class ConfirmDeleteModal extends React.Component {
  confirm() {
    this.props.dispatch(deleteHabit(this.props.habit.id));
    this.props.onHide();
  }

  render() {
    return (
      <div className="confirm-delete-modal">
        <div className="modal-body">{messages.CONFIRM_DELETE_TEXT}</div>
        <div className="modal-footer">
          <a className="modal-button" onClick={this.confirm.bind(this)}>
            {messages.DELETE}
          </a>
          <a className="modal-button cancel" onClick={this.props.onHide}>
            {messages.CANCEL}
          </a>
        </div>
      </div>
    );
  }
}

export default connect()(ConfirmDeleteModal);
