import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import messages from "utils/messages";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import { LinkContainer } from "react-router-bootstrap";
import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import { showModal } from "actions/modal";
import { logout } from "actions/auth";
import * as Components from "components";

export default function Nav() {
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  const variables = useSelector((state) => state.variables);
  const habitsEntities = useSelector((state) => state.habitsEntities);
  const promo = useSelector((state) => state.promo.promoInfo);
  const showPromoTip = useSelector(
    (state) =>
      state.user.id &&
      !state.user.isPremium &&
      !state.user.isGrandfathered &&
      state.promo.promoInfo &&
      !state.promo.showPromoTip[state.promo.promoInfo.id] &&
      !state.promo.promoInfo.trial_months &&
      state.promo.promoInfo.discount
  );
  const user = useSelector((state) => state.user);

  function handleLogout() {
    dispatch(logout());
  }

  function subscribe() {
    dispatch(
      showModal("subscription", {
        showBrightModal: true,
        class: "premium",
        origin: "NavBar",
      })
    );
  }

  function calcTotalCount() {
    let totalCount = 0;
    Object.values(habitsEntities).map(
      (habit) => (totalCount += habit.totalCount || 0)
    );
    return totalCount;
  }

  function toggleHover() {
    setHover(!hover);
  }

  const totalCount = calcTotalCount();
  const tooltip = <Tooltip id="tooltip">{messages.TOOLTIP_TOTALCOUNT}</Tooltip>;
  const subscribeIcon = (
    <Components.Icon name="Star" color="#249c03" height="18" width="18" />
  );
  const username = user.name || "user";
  return (
    <div className="nav">
      {user.id ? (
        <Link to="/" className="brand-wrapper">
          <span className="logo" />
          <span className="brand">everyday</span>
        </Link>
      ) : (
        <a href="https://everyday.app" className="brand-wrapper">
          <span className="logo" />
          <span className="brand" style={{ color: variables.black }}>
            everyday
          </span>
        </a>
      )}
      {user.id ? (
        <ul className="nav-options">
          <OverlayTrigger
            placement="left"
            delayShow={700}
            delayHide={150}
            overlay={tooltip}
          >
            <li className="total-count">
              <Components.Icon
                name="Cup"
                color="#249c03"
                height="17"
                width="17"
              />
              <span className="count">{totalCount}</span>
            </li>
          </OverlayTrigger>
          <li>
            <DropdownButton
              pullRight
              title={username.split(" ")[0]}
              onClick={(e) => e.stopPropagation()}
              id="userMenu"
              className="ion-ios-person-outline"
            >
              <LinkContainer className="menuItem" to="/">
                <MenuItem>{messages.BOARD}</MenuItem>
              </LinkContainer>
              <LinkContainer to="/archive">
                <MenuItem>{messages.ARCHIVE}</MenuItem>
              </LinkContainer>
              <MenuItem divider />
              <LinkContainer to="/settings">
                <MenuItem>{messages.SETTINGS}</MenuItem>
              </LinkContainer>
              <LinkContainer to="/web-extensions">
                <MenuItem>{messages.WEBEXTENSIONS}</MenuItem>
              </LinkContainer>
              {!user.isPremium && <MenuItem divider />}
              {!user.isPremium && (
                <MenuItem onClick={subscribe} className="upgrade">
                  {messages.SUBSCRIBE}
                </MenuItem>
              )}
              {!user.isPremium && <MenuItem divider />}
              <MenuItem onClick={handleLogout}>{messages.LOGOUT}</MenuItem>
            </DropdownButton>
          </li>
        </ul>
      ) : null}
      {showPromoTip ? (
        <a
          className="subscribe-button"
          onClick={() => {
            dispatch({ type: "SET_SHOW_PROMO_TIP" });
            subscribe();
          }}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          {subscribeIcon}
          {messages.UPGRADE_APPLY} {promo.promoter}&lsquo;s{" "}
          {messages.UPGRADE_PROMO}
        </a>
      ) : null}
      {!user.id ? (
        <ul className="nav-options guest">
          <li>
            <Link to="/signup">{messages.SIGNUP}</Link>
          </li>
          <li>
            <Link to="/login">{messages.LOGIN}</Link>
          </li>
        </ul>
      ) : null}
      <div className="clear" />
    </div>
  );
}
