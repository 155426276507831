import React from "react";
import { connect } from "react-redux";
import AnimateHeight from "react-animate-height";
import Icon from "components/Icon/Icon";

class Banner extends React.Component {
  componentDidMount() {
    if (this.props.banner.message) {
      setTimeout(this.hideBanner.bind(this), 30000);
    }
  }

  componentDidUpdate() {
    if (this.props.banner.message) {
      setTimeout(this.hideBanner.bind(this), 30000);
    }
  }

  hideBanner() {
    this.props.dispatch({ type: "HIDE_BANNER" });
  }

  render() {
    const { banner } = this.props;
    return (
      <AnimateHeight height={banner.message ? 40 : 0} duration={500}>
        <div className="banner">
          <div />
          <div>{banner.message}</div>
          <Icon
            name="Close"
            color="white"
            size="20"
            onClick={this.hideBanner.bind(this)}
          />
        </div>
      </AnimateHeight>
    );
  }
}

export default connect((state) => ({
  variables: state.variables,
  banner: state.banner,
}))(Banner);
