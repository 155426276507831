import React from "react";
import { Link } from "react-router-dom";
import messages from "utils/messages";
import Button from "react-bootstrap/lib/Button";
import Alert from "react-bootstrap/lib/Alert";

export default class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alertVisible: true,
      why: false,
    };
  }

  handleAlertDismiss() {
    this.setState({ alertVisible: false });
  }

  handleWhy() {
    this.setState((prevState) => ({ why: !prevState.why }));
  }

  render() {
    const name = this.props.user.name.split(" ")[0];
    if (this.state.alertVisible) {
      return (
        <div className="notification">
          <Alert
            bsStyle="success"
            onDismiss={this.handleAlertDismiss.bind(this)}
          >
            <p>
              {messages.DEADLINE} {name}
              {messages.DEADLINE_0}
            </p>
            <p>{messages.DEADLINE_1}</p>
            <p>{messages.DEADLINE_2}</p>
            <p>
              {messages.DEADLINE_3}
              <a
                className="link"
                href="mailto:joan@everyday.app"
                target="_blank"
                rel="noreferrer"
              >
                {messages.DEADLINE_4}
              </a>{" "}
              {messages.DEADLINE_5}
            </p>
            <p>{messages.SIGNATURE}</p>
            {this.state.why ? (
              <div className="because">
                <p className="why-title">{messages.WHYNOTFREE}</p>
                <p>{messages.BECAUSE}</p>
                <p>{messages.BECAUSE_2}</p>
                <p>{messages.BECAUSE_3}</p>
                <p>
                  {messages.BECAUSE_4}
                  <a
                    className="link"
                    href="https://blog.pinboard.in/2011/12/don_t_be_a_free_user/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.BECAUSE_5}
                  </a>
                  {messages.BECAUSE_6}
                  <a
                    className="link"
                    href="mailto:joan@everyday.app"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.BECAUSE_7}
                  </a>
                </p>
              </div>
            ) : null}
            <p>
              <Button onClick={this.handleWhy.bind(this)}>
                {messages.WHY}
              </Button>
              <Link to="/subscribe" className="subscribe">
                <Button bsStyle="success">{messages.SUBSCRIBE}</Button>
              </Link>
            </p>
          </Alert>
        </div>
      );
    }
    return null;
  }
}
