import request from "utils/request";
import * as storage from "utils/storage";
import { fetchHabits } from "actions/habits";
import { initPromo } from "actions/promos";
import { CrossStorageClient } from "cross-storage";
import { isExtension, isSafariExtension } from "utils/extension";
import config from "config";
import { setCookie, getCookie } from "utils/cookies";
import { initMixpanel, setUser as setMixpanelUser } from "utils/mixpanel";

// import { persistor } from 'stores';

export function loginSuccess(user) {
  setMixpanelUser(user);

  return {
    type: "LOGIN_SUCCESS",
    user,
  };
}

export function logoutSuccess() {
  return {
    type: "LOGOUT",
  };
}

export function loginFailed(errors) {
  return {
    type: "LOGIN_ERROR",
    errors: Array.isArray(errors) ? errors : [errors],
  };
}

export function updateUserSuccess(user) {
  return {
    type: "UPDATE_USER_SUCCESS",
    user,
  };
}

export function setLoggedUserFromQueryParam(userParam) {
  return (dispatch) => {
    try {
      let user;

      if (typeof userParam === "string") {
        user = JSON.parse(atob(userParam));
      } else {
        user = userParam;
      }
      // const user = JSON.parse(atob(userParam));
      storage.set({
        key: "edc_user",
        value: user,
        persist: true,
      });

      // tell landing app user is logged in
      if (window.location.hostname === "app.everyday.app") {
        const crossStorage = new CrossStorageClient(config.externalAppUrl);
        crossStorage
          .onConnect()
          .then(() => crossStorage.set("edc_user", JSON.stringify(user)));
      }
      dispatch(loginSuccess(user));
      dispatch(fetchHabits());
      window.location.replace("/?social=true");
    } catch (err) {
      console.log("setLoggedUserFromQueryParam failed", err);
    }
  };
}

export function login(params) {
  return (dispatch) =>
    request({
      url: `login`,
      method: "POST",
      body: { ...params, os: "web" },
    }).then(
      (res) => {
        const user = Object.assign(res);

        storage.set({
          key: "edc_user",
          value: user,
          persist: true,
        });

        // tell landing app user is logged in
        if (window.location.hostname === "app.everyday.app") {
          const crossStorage = new CrossStorageClient(config.externalAppUrl);
          crossStorage
            .onConnect()
            .then(() => crossStorage.set("edc_user", JSON.stringify(user)));
        }
        dispatch(loginSuccess(user));
        dispatch(initPromo());

        window.parent &&
          window.parent.postMessage(
            { type: "SOCIAL_LOGIN", user: JSON.stringify(user) },
            "*"
          );

        window.location.replace("/");
      },
      (err) => {
        if (err instanceof Error) {
          console.log("login failed");

          //sentry.captureException(err);
          dispatch(loginFailed(err.message));
        } else dispatch(loginFailed(err));
      }
    );
}

export function signup(params) {
  const urlParams = new URLSearchParams(window.location.search);
  const promo = urlParams.get("promo");
  const ref = urlParams.get("ref");

  const source = ref || document.referrer;
  // return console.log(promo);

  return (dispatch) =>
    request({
      url: `signup?promo=${promo}`,
      method: "POST",
      body: {
        ...params,
        device_os: isExtension() ? "webextension" : "web",
        promo,
        source,
      },
    }).then((res) => {
      const user = Object.assign(res);
      user.token = btoa(
        unescape(encodeURIComponent(`${params.email}:${params.password}`))
      );

      storage.set({
        key: "edc_user",
        value: user,
        persist: true,
      });

      dispatch(loginSuccess(user));
      dispatch(initPromo());

      window.parent &&
        window.parent.postMessage(
          { type: "SOCIAL_LOGIN", user: JSON.stringify(user) },
          "*"
        );

      window.location.replace("/");

      return user;
    });
}

export function signInWithGoogle(params) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const body = { ...params, os: "web" };

      const res = await request({
        url: "signInWithGoogle",
        method: "POST",
        body,
      });
      const user = Object.assign(res);

      console.log("sign up with google success", user);

      storage.set({
        key: "edc_user",
        value: user,
        persist: true,
      });

      dispatch(loginSuccess(user));
      dispatch(initPromo());

      window.parent &&
        window.parent.postMessage(
          { type: "SOCIAL_LOGIN", user: JSON.stringify(user) },
          "*"
        );

      window.location.replace("/");
    } catch (err) {
      if (err instanceof Error) {
        console.log("sign up with google failed");

        dispatch(loginFailed(err.message + err.stack));
      } else {
        dispatch(loginFailed(err + err.stack));
        throw err;
      }
    }
  };
}

export function logout() {
  return (dispatch) => {
    // storage.remove('edc_user');
    // remove both, edc_user and app state from localStorage
    storage.remove("edc_user");
    storage.remove("edc_state");
    storage.remove("edc_state_version");
    storage.remove("network");

    if (isSafariExtension()) {
      window.parent.postMessage({ type: "LOGOUT" }, "*");
    }

    // persistor.purge();

    // tell landing app user is logged out
    if (window.location.hostname === "app.everyday.app") {
      const crossStorage = new CrossStorageClient(config.externalAppUrl);
      crossStorage
        .onConnect()
        .then(() => crossStorage.clear())
        .then(() => {
          window.location.reload();
          dispatch(logoutSuccess());
        });
      // window.location.reload();
      // dispatch(logoutSuccess());
    } else {
      window.location.replace("login");
      dispatch(logoutSuccess());
    }
  };
}

export function restore() {
  return (dispatch) => {
    let user = storage.get("edc_user");

    if (user) {
      dispatch(loginSuccess(user));
    }
  };
}

export function confirmEmail() {
  return () => {
    const edc_user = storage.get("edc_user");
    if (!edc_user) {
      window.location.replace("/login?confirm=true");
      return;
    }
    window.location.replace("/");
  };
}
