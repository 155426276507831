import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addHabit, editHabit } from "actions/habits";
import { format, addDays } from "date-fns";
import messages from "utils/messages";
import { stringToLocaltimeDate } from "utils/dates";
import { habits as habitValidation } from "common/validations";
import { showModal } from "actions/modal";

export default function NewHabitModal({ habit = {}, onHide }) {
  const dispatch = useDispatch();
  const numNonArchivedHabits = useSelector(
    (state) => state.habits.habits.length
  );
  const user = useSelector((state) => state.user);

  let submitted = false;

  function getDayNames() {
    let date = stringToLocaltimeDate("2020-04-20");
    const res = [];
    for (let i = 0; i <= 6; i++) {
      res.push(format(date, "ddd"));
      date = addDays(date, 1);
    }
    return res;
  }

  const [state, setState] = useState({
    errors: [],
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    advanced: false,
    color: habit?.color || "green",
    ...habit,
  });

  function handleNameChange(habit) {
    setState({
      ...state,
      name: habit.target.value,
    });
  }

  function handleBreakChange() {
    setState({
      ...state,
      break_habit: !state.break_habit,
    });
  }

  function handleArchivedChange() {
    if (!user.isPremium) {
      dispatch(
        showModal("premiumFeature", {
          class: "premiumfeature",
          type: "archive",
        })
      );
    }
    setState({
      ...state,
      archived: !state.archived,
    });
  }

  function setEveryday() {
    setState({
      ...state,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    });
  }

  function setDayFrequency(day) {
    setState((state) => ({
      ...state,
      [day]: !state[day],
    }));
  }

  function checkEnter(e) {
    if (e.keyCode === 13) {
      addHabit();
    }
  }

  function advancedOptions(advanced) {
    setState({ ...state, advanced });
  }

  function isEveryday() {
    const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } =
      state;
    if (
      monday &&
      tuesday &&
      wednesday &&
      thursday &&
      friday &&
      saturday &&
      sunday
    ) {
      return true;
    }
    return false;
  }

  function confirmDeleteModal() {
    dispatch(
      showModal("deleteHabit", { title: messages.CONFIRM_DELETE_HABIT, habit })
    );
  }

  function handleAddHabit() {
    if (submitted) return;

    const oldHabit = habit || {};
    const user_id = oldHabit.user_id || user.id;

    const newHabit = { ...oldHabit, ...state };
    newHabit.user_id = user_id;

    if (newHabit.archived) newHabit.position = 99999;
    if (oldHabit.archived && !newHabit.archived)
      newHabit.position = numNonArchivedHabits;

    const validationErrors = habitValidation(newHabit);
    if (validationErrors) {
      setState({ ...state, errors: validationErrors });
      return;
    }

    submitted = true;

    if (!oldHabit.id) dispatch(addHabit(newHabit));
    else {
      dispatch(editHabit(newHabit));
    }
    onHide();
  }

  function renderFrequencies() {
    const dayLabels = getDayNames();
    const res = [];
    const dayNames = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    for (const [i, dayLabel] of dayLabels.entries()) {
      const dayName = dayNames[i];
      const className =
        "frequency-cell " + (state[dayName] ? "frequency-marked" : null);
      res.push(
        <div
          className="frequency-block"
          onClick={setDayFrequency.bind(null, dayName)}
        >
          <span className="frequency-day-name">{dayLabel}</span>
          <div className={className} />
        </div>
      );
    }

    return res;
  }

  return (
    <div className="newHabit">
      {state.errors.length ? (
        <ul className="errors">
          {state.errors.map((error) => (
            <li key={error}> {error.message} </li>
          ))}
        </ul>
      ) : null}
      <div className="field">
        <a className="label">{messages.HABIT_NAME}</a>
        <input
          className="habitname"
          type="text"
          name="habitname"
          placeholder={messages.HABIT_NAME_PH}
          value={state.name}
          onChange={handleNameChange}
          onKeyDown={checkEnter}
          maxLength="40"
          autoFocus
        />
      </div>
      <div className="field">
        {state.advanced ? (
          <div className="advanced-options">
            <a className="ao-title" onClick={advancedOptions.bind(null, false)}>
              <i className="ion-arrow-down-b" />
              <span>{messages.ADVANCED_OPTIONS}</span>
            </a>
            <div className="option">
              <input
                type="checkbox"
                id="break_habit"
                checked={state.break_habit}
                onChange={handleBreakChange}
              />
              <label htmlFor="break_habit">{messages.AO_BREAK_HABIT}</label>
              <a className="explanation">{messages.AO_BREAK_HABIT_EXP}</a>
            </div>
            <div className="option frequency">
              <div>
                <span className="frequency-title">{messages.AO_FREQUENCY}</span>
                <input
                  type="checkbox"
                  id="everyday"
                  checked={isEveryday()}
                  onChange={setEveryday}
                />
                <label htmlFor="everyday">
                  {messages.AO_FREQUENCY_EVERYDAY}
                </label>
              </div>
              <div className="frequency-blocks">{renderFrequencies()}</div>
              <span className="frequency-exp">{messages.AO_FREQUENCY_EXP}</span>
            </div>
            {habit.id && (
              <div className="option archived">
                <input
                  type="checkbox"
                  id="archived"
                  checked={state.archived}
                  onChange={handleArchivedChange}
                />
                <label htmlFor="archived">{messages.AO_ARCHIVED}</label>
                <a className="explanation">{messages.AO_ARCHIVED_EXP}</a>
              </div>
            )}
          </div>
        ) : (
          <div className="advanced-options">
            <a className="ao-title" onClick={advancedOptions.bind(null, true)}>
              <i className="ion-arrow-right-b" />
              <span>{messages.ADVANCED_OPTIONS}</span>
            </a>
          </div>
        )}
      </div>
      <div className="field">
        {habit.id ? (
          <a className="delete-button" onClick={confirmDeleteModal}>
            <i className="ion-close-round" />
            <span>{messages.DELETE}</span>
          </a>
        ) : null}
        <a className="modal-button" onClick={handleAddHabit}>
          {habit.id ? messages.EDIT_HABIT : messages.ADD_HABIT}
        </a>
        <a className="modal-button cancel" onClick={onHide}>
          {messages.CANCEL}
        </a>
      </div>
      <div className="clear" />
    </div>
  );
}
