import React from "react";
import messages from "utils/messages";

export default class SkipModal extends React.Component {
  render() {
    return (
      <div className="skipModal">
        <div
          className="triangle"
          style={{ backgroundColor: "var(--main-dark)" }}
        />
        <a className="skip-modal-text black">{messages.MODAL_SKIP_1}</a>
        <a className="skip-modal-text">{messages.MODAL_SKIP_2}</a>
        <a className="skip-modal-text">{messages.MODAL_SKIP_3}</a>
        <a className="skip-modal-text">{messages.MODAL_SKIP_4}</a>
      </div>
    );
  }
}
