import React from "react";
import messages from "utils/messages";
import { Link } from "react-router-dom";

export default class Tips extends React.Component {
  render() {
    return (
      <div className="tips">
        <div className="wrapper">
          <div className="left">
            <i className="ion-ios-lightbulb-outline" />
            <h3>{messages.TIPS}</h3>
            <a className="text">{messages.TIPS_DESCRIPTION}</a>
            <div className="line" />
          </div>
          <div className="right">
            <Link to="web-extensions" className="tip">
              {messages.TIPS_WEBEXTENSIONS}
            </Link>
            <a
              className="tip"
              href="https://everyday.app/faq#bookmark"
              target="_blank"
              rel="noreferrer"
            >
              {messages.TIPS_BOOKMARK}
            </a>
            <a
              className="tip"
              href="https://everyday.app/faq#pinnedtab"
              target="_blank"
              rel="noreferrer"
            >
              {messages.TIPS_PINNEDTAB}
            </a>
            <a
              className="tip"
              href="https://everyday.app/faq#deskshortcut"
              target="_blank"
              rel="noreferrer"
            >
              {messages.TIPS_DESKSHORTCUT}
            </a>
            <a
              className="tip"
              href="https://everyday.app/faq#mobshortcut"
              target="_blank"
              rel="noreferrer"
            >
              {messages.TIPS_MOBSHORTCUT}
            </a>
          </div>
          <div className="clear" />
        </div>
      </div>
    );
  }
}
