import React from "react";
import { connect } from "react-redux";
import { subDays } from "date-fns";
import * as Components from "components";
import constants from "common/constants";

class Row extends React.Component {
  render() {
    const { habit, user, count } = this.props;
    let { numDaysView, offset } = this.props;
    const row = [];

    const isPremium = !user.isPremium && count >= 3;

    if (constants.numDays - numDaysView + 1 === offset) {
      offset++;
      numDaysView--;
    }

    for (let i = 1; i <= numDaysView; i++, offset++) {
      const currentDay = subDays(
        this.props.currentDay,
        constants.numDays - offset
      );
      row.push(
        <Components.Cell
          date={currentDay}
          habitId={habit.id}
          isPremium={isPremium}
        />
      );
    }

    if (isPremium) {
      return (
        <tr key={habit.id} className="freelimit">
          {row}
        </tr>
      );
    }
    return <tr key={habit.id}>{row}</tr>;
  }
}

function mapStateToProps(state, props) {
  return {
    user: state.user,
    habit: state.habitsEntities[props.habitId],
  };
}

export default connect(mapStateToProps)(Row);
