import React from "react";
import messages from "utils/messages";
import constants from "common/constants";
import { subDays, format } from "date-fns";
import Slider from "rc-slider";

export default class BoardFooter extends React.Component {
  handleChange(e) {
    this.props.showStats(e);
  }

  handleNewHabit() {
    if (!this.props.user.isPremium && this.props.numHabits >= 3)
      this.props.showPremiumFeatureModal();
    else this.props.showNewHabitModal();
  }

  renderRow() {
    const row = [];

    if (!this.props.habitsDates[0]) return null;

    let numDaysView = this.props.numDaysView;
    let offset = this.props.offset;
    if (constants.numDays - numDaysView + 1 === offset) {
      offset++;
      numDaysView--;
    }

    for (let i = 1; i <= numDaysView; i++, offset++) {
      let sum = 0;
      for (let j = 0; j < this.props.habitsDates.length; j++) {
        const habitDate =
          this.props.habitsDates[j][
            format(
              subDays(this.props.currentDay, constants.numDays - offset),
              "YYYY-MM-DD"
            )
          ];
        if (habitDate && habitDate.isMarked) {
          sum++;
        }
      }
      if (offset === constants.numDays) {
        row.push(
          <td key={i} className="today">
            {sum}
          </td>
        );
      } else row.push(<td key={i}>{sum}</td>);
    }

    return (
      // eslint-disable-line
      <tr>{row}</tr>
    );
  }

  render() {
    const { hideNewHabit } = this.props;
    return (
      <div className="boardfooter">
        {hideNewHabit ? (
          <div className="newhabit" />
        ) : (
          <div
            className="newhabit newhabit-hover"
            onClick={this.handleNewHabit.bind(this)}
          >
            <i className="ion-plus" /> {messages.NEW_HABIT}
          </div>
        )}
        <div className="counts">
          <table>
            <tbody>{this.renderRow()}</tbody>
          </table>
        </div>
        <div className="stats-slider">
          <div className="slider-wrapper">
            <Slider
              min={1}
              max={3}
              defaultValue={1}
              onChange={this.handleChange.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
}
