import React from "react";
import * as Components from "components";

export default class Tips extends React.Component {
  render() {
    return (
      <div className="tips-page">
        <Components.Tips />
      </div>
    );
  }
}
