import React, { useState, useEffect } from "react";
import messages from "utils/messages";
import { login } from "actions/auth";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import config from "config";

import GoogleButton from "components/GoogleButton/GoogleButton";
import AppleButton from "components/AppleButton/AppleButton";
import { isSafariPopupExtension } from "utils/extension";
export default function LogIn() {
  const errors = useSelector((state) => state.errors || []);
  const [signUpErrors, setSignUpErrors] = useState([]);
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [password, setPassowrd] = useState();
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const confirm = urlParams.get("confirm");

    if (confirm)
      errors.push("Your account has been verified. You can now log in.");
  }, []);

  async function onSubmit(event) {
    event.preventDefault();

    await dispatch(
      login({
        email,
        password,
      })
    );

    if (!errors.length) history.push("/");
  }

  const forgotUrl = config.landingUrl + "/forgot";
  return (
    <div className="login">
      <h2>{messages.LOGIN_TITLE}</h2>
      <div className="login-form-wrapper">
        {errors.length ? (
          <ul className="errors">
            {signUpErrors.map((error) => (
              <li key={error}> {error} </li>
            ))}
          </ul>
        ) : null}
        <form className="login-form" onSubmit={onSubmit}>
          <div className="form-row">
            <input
              className="email login-input"
              autoComplete="username"
              type="email"
              name="email"
              onChange={(ev) => setEmail(ev.target.value)}
            />
            <label className="text-label">{messages.S_EMAIL}</label>
          </div>
          <div className="form-row">
            <input
              onChange={(ev) => setPassowrd(ev.target.value)}
              className="password login-input"
              autoComplete="current-password"
              type="password"
              name="password"
            />
            <label className="text-label">{messages.S_PASSWORD}</label>
          </div>
          <button type="submit" className="login-button">
            {messages.LOGIN}
          </button>
        </form>
        <a href={forgotUrl} className="forgot">
          {messages.L_FORGOT}
        </a>
        <Link to="/signup" className="signuplink">
          {messages.L_SIGNUP}
        </Link>
        {!isSafariPopupExtension() && (
          <div className="social-buttons">
            <div className="or">
              <a>{messages.OR}</a>
            </div>

            <AppleButton />
            <GoogleButton />
          </div>
        )}
      </div>
    </div>
  );
}
