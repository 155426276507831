import BrowserDtector from "browser-dtector";
import { isExtension } from "utils/extension";

let displayMode;

const getDisplayMode = new Promise((resolve) => {
  if (displayMode) return resolve(displayMode);
  window.addEventListener("DOMContentLoaded", () => {
    let displayMode = "browser";
    if (window.matchMedia("(display-mode: standalone)").matches) {
      displayMode = "pwa";
    }
    console.log("DISPLAY MODE", displayMode);
    // Log launch display mode to analytics
    resolve(displayMode);
  });
});

export function getBrowser() {
  const browserDetector = new BrowserDtector(window.navigator.userAgent);
  const browserInfo = browserDetector.getBrowserInfo();
  let browser = browserInfo.name;

  if (browser === "Google Chrome") browser = "chrome";
  if (browser === "Microsoft Edge") browser = "edge";
  if (browser === "Mozilla Firefox") browser = "firefox";
  if (browser === "Safari") browser = "safari";

  return browser;
}

export function isSafari() {
  return getBrowser() === "safari";
}

export async function getDevice() {
  const displayMode = await getDisplayMode;
  if (displayMode === "pwa") return "pwa";
  if (!isExtension()) return "web";
  let browser = getBrowser();

  browser = "we-" + browser;
  return browser;
}
