import request from "utils/request";
import { isBefore, addDays } from "date-fns";
import { updateUser } from "actions/users";
import * as storage from "utils/storage";
import messages from "utils/messages";

export function setSourcePromo() {
  return async (dispatch, getState) => {
    const promo = storage.get("promo");
    const state = getState();
    if (!promo || state.user.source_promo_id) return;
    const promoId =
      state.promo && state.promo.promoInfo && state.promo.promoInfo.id;
    await request({
      url: "users",
      method: "PUT",
      body: { source_promo_id: promoId },
    });
  };
}

export function initPromo() {
  return async (dispatch, getState) => {
    const currentState = getState();
    console.log("init promo", currentState.user);
    if (currentState.user.isPremium) return;

    const urlParams = new URLSearchParams(window.location.search);
    let promo = urlParams.get("promo");
    if (!promo) promo = storage.get("promo");

    dispatch({
      type: "REMOVE_PROMO",
    });

    console.log("init promo", promo);

    if (promo) {
      const res = await request({
        url: `promos?code=${promo}`,
        method: "GET",
      });

      if (!urlParams.get("promo")) {
        // landing with different promo (crossLocalStorage)
        promo = storage.get("promo");
        if (promo && promo !== res.code) return;
      }

      if (res) {
        if (
          (!res.date_end || isBefore(new Date(), addDays(res.date_end, 1))) &&
          (res.redeems_left === null || res.redeems_left > 0)
        ) {
          dispatch({
            type: "SET_PROMO",
            promo: res,
          });

          if (res.trial_months) {
            const state = getState();
            if (state.user.id && !state.user.premium_access) {
              const user = await request({
                url: `redeemTrial?code=${promo}`,
                method: "PUT",
              });
              if (user && user.premium_access) {
                dispatch({
                  type: "SHOW_BANNER",
                  message: messages.TRIAL_BANNER_MESSAGE.replace(
                    "{months}",
                    res.trial_months
                  ),
                });
                dispatch(updateUser(user));
              }
            }
          }
        }
        storage.remove("promo");
        storage.set({
          key: "promo",
          value: res.code,
          persist: true,
        });
      }
    }
  };
}
