import { applyTheme } from "utils/themes";

export function changeTheme() {
  return (dispatch, getState) => {
    const state = getState();
    const theme = state.variables.theme === "dark" ? "light" : "dark";
    applyTheme(theme);
    dispatch({
      type: "CHANGE_THEME",
      theme
    });
  };
}
