import io from "socket.io-client";
import config from "config";
import history from "utils/history";
import { markSuccess, unmarkSuccess, skipSuccess } from "actions/dates";
import {
  fetchHabits,
  addHabitSuccess,
  editHabitSuccess,
  deleteHabitSuccess,
} from "actions/habits";
import { updateUserSuccess } from "actions/auth";
import { updateStreak } from "./stats";
import store from "../stores";

let socket;

export const getId = () => socket && socket.id;

export const connect = (id) => {
  if (socket) return;
  socket = io(`${config.apiUrl}`);

  socket.on("registrationRequest", () => {
    socket.emit("register", id);
  });

  socket.on("mark", (data) => {
    const state = store.getState();

    store.dispatch(markSuccess(data.date, state.habitsEntities[data.habit_id]));
    store.dispatch(updateStreak(data.habit_id));
  });

  socket.on("unmark", (data) => {
    const state = store.getState();

    store.dispatch(
      unmarkSuccess(data.date, state.habitsEntities[data.habit_id])
    );
    store.dispatch(updateStreak(data.habit_id));
  });

  socket.on("skip", (data) => {
    const state = store.getState();

    store.dispatch(skipSuccess(data.date, state.habitsEntities[data.habit_id]));
    store.dispatch(updateStreak(data.habit_id));
  });

  socket.on("addHabit", (data) => {
    const state = store.getState();
    if (!state.habitsEntities[data.id]) store.dispatch(addHabitSuccess(data));
  });

  socket.on("editHabit", (habit) => {
    const state = store.getState();
    const oldHabit = state.habitsEntities[habit.id];
    if (
      oldHabit.color !== habit.color ||
      oldHabit.break_habit !== habit.break_habit
    )
      store.dispatch({
        type: "UPDATE_HABIT_COLOR",
        habit,
      });
    if (oldHabit.archived && !habit.archived) {
      store.dispatch({
        type: "UNARCHIVE",
        id: habit.id,
      });
    }

    if (!oldHabit.archived && habit.archived) {
      store.dispatch({
        type: "ARCHIVE",
        id: habit.id,
      });
    }

    store.dispatch(editHabitSuccess(habit));
  });

  socket.on("moveHabit", () => {
    store.dispatch(fetchHabits());
  });

  socket.on("deleteHabit", (habitId) => {
    store.dispatch(deleteHabitSuccess(habitId));
  });

  socket.on("updateUser", (user) => {
    store.dispatch(updateUserSuccess(user));
    history.replace("/");
  });
};
