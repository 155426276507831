function habits(state = [], action) {
  switch (action.type) {
    case "FETCH_HABITS_SUCCESS":
      return [
        ...action.habits
          .filter((habit) => habit.archived)
          .map((habit) => habit.id),
      ];
    case "DELETE_HABIT_SUCCESS":
      return state.filter((habit) => habit !== action.habit_id);
    case "UNARCHIVE":
      return state.filter((habitId) => habitId !== action.id);
    case "ARCHIVE":
      return [...state, action.id];
    default:
      return state;
  }
}

const initialState = {
  habits: [],
};

export default function archive(state = initialState, action) {
  switch (action.type) {
    case "FETCH_HABITS_SUCCESS":
    case "DELETE_HABIT_SUCCESS":
    case "ARCHIVE":
    case "UNARCHIVE":
      return { ...state, habits: habits(state.habits, action) };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
}
