import { signInWithGoogle } from "actions/auth";
import { useDispatch } from "react-redux";

import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useEffect, useRef } from "react";

export default function GoogleSignIn() {
  const dispatch = useDispatch();
  const ref = useRef();
  const googleLoginFlow = useGoogleLogin({
    onSuccess: (codeResponse) => {
      dispatch(
        signInWithGoogle({
          token: codeResponse.credential,
        })
      );
    },
    ux_mode: "redirect",
  });

  useEffect(() => {
    ref.current.click();
  }, []);

  return <button ref={ref} onClick={googleLoginFlow}></button>;
}
