import request from "utils/request";
import * as storage from "utils/storage";
import { CrossStorageClient } from "cross-storage";
import config from "config";
import { isAfter, format } from "date-fns";
import history from "utils/history";
import { isExtension } from "utils/extension";
import { fetchHabits } from "actions/habits";
import { sendEvent } from "utils/events";
import { hideModal } from "actions/modal";
import moment from "moment";
import constants from "common/constants";

export function updateUserSuccess(user) {
  return {
    type: "UPDATE_USER_SUCCESS",
    user,
  };
}

export function updateUser(remoteUser) {
  return (dispatch) => {
    dispatch(updateUserSuccess(remoteUser));

    const edc_user = storage.get("edc_user");
    const newUser = { ...edc_user, ...remoteUser };

    storage.set({
      key: "edc_user",
      value: newUser,
      persist: true,
    });

    if (!isExtension() && process.env.NODE_ENV === "production") {
      const crossStorage = new CrossStorageClient(config.externalAppUrl);

      crossStorage
        .onConnect()
        .then(() => crossStorage.set("edc_user", JSON.stringify(newUser)));
    }
  };
}

export function syncData() {
  return async (dispatch, getState) => {
    const { user } = getState();
    const remoteUser = await request({
      url: `users/${user.id}`,
      method: "GET",
    });

    const remoteUserLastAction = remoteUser.last_action;
    dispatch(updateUserSuccess(remoteUser));

    const edc_user = storage.get("edc_user");
    const newUser = { ...edc_user, ...remoteUser };

    storage.set({
      key: "edc_user",
      value: newUser,
      persist: true,
    });

    const data = { type: "SOCIAL_LOGIN", user: newUser };
    window.postMessage(data, "*");

    if (!isExtension() && process.env.NODE_ENV === "production") {
      const crossStorage = new CrossStorageClient(config.externalAppUrl);

      crossStorage
        .onConnect()
        .then(() => crossStorage.set("edc_user", JSON.stringify(newUser)));
    }

    if (user.premium_access !== remoteUser.premium_access) {
      history.replace("/");
      dispatch(hideModal());
    }

    const baseDate = moment()
      .subtract(constants.numDays, "days")
      .format("YYYY-MM-DD");

    const state = getState();
    const currentBaseDate = moment(state.basedate).format("YYYY-MM-DD");

    if (
      baseDate !== currentBaseDate ||
      !remoteUserLastAction ||
      !user.last_action ||
      isAfter(new Date(remoteUserLastAction), new Date(user.last_action))
    ) {
      dispatch(fetchHabits());
    }

    return null;
  };
}

export function exportData() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const formattedBaseDate = format(state.user.created_at, "YYYY-MM-DD");

      const res = await request({
        requiresAuth: true,
        url: `habitsV2?&base_date=${formattedBaseDate}`,
        method: "GET",
      });

      const { habitsArray, datesHash } = res;

      const dataToExport = {
        user: {
          name: state.user.name,
          email: state.user.email,
        },
        habits: [],
      };

      for (const habit of habitsArray) {
        dataToExport.habits.push({
          name: habit.name,
          description: habit.description,
          archived: habit.archived,
          time: habit.time,
          color: habit.color,
          monday: habit.monday,
          tuesday: habit.tuesday,
          wednesday: habit.wednesday,
          thursday: habit.thursday,
          friday: habit.friday,
          saturday: habit.saturday,
          sunday: habit.saturday,
          dates: datesHash[habit.id],
        });
      }

      const fileName = "everydayData";
      const json = JSON.stringify(dataToExport);
      const blob = new Blob([json], { type: "application/json" });
      const href = await URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName + ".json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      sendEvent({
        type: "EXPORT_DATA",
      });
    } catch (e) {
      console.log("export data failed", e);
      // sentry.captureException(e);
    }
  };
}

export function update(params) {
  return (dispatch) =>
    request({
      url: "users",
      method: "PUT",
      body: params,
    }).then(
      (user) => {
        const edc_user = storage.get("edc_user");
        const newUser = { ...edc_user, ...user };

        // update new password
        if (Object.keys(params)[0] === "password") {
          newUser.token = btoa(
            unescape(encodeURIComponent(`${newUser.email}:${params.password}`))
          );

          if (!isExtension() && process.env.NODE_ENV === "production") {
            const crossStorage = new CrossStorageClient(config.externalAppUrl);

            crossStorage
              .onConnect()
              .then(() =>
                crossStorage.set("edc_user", JSON.stringify(newUser))
              );
          }
        }

        // update name in localStorage
        storage.set({
          key: "edc_user",
          value: newUser,
          persist: true,
        });

        // update user in redux state
        dispatch(updateUserSuccess(user));
      },
      (err) => err
    );
}

export function updateMailingSubscription(params) {
  return (dispatch) =>
    request({
      url: "updateMailingSubscription",
      method: "POST",
      body: params,
    }).then(
      (user) => {
        // update mailing_list in localStorage
        const edc_user = storage.get("edc_user");
        if (!edc_user) {
          return;
        }
        const newUser = { ...edc_user, mailing_list: user.mailing_list };
        storage.set({
          key: "edc_user",
          value: newUser,
          persist: true,
        });

        dispatch(updateUserSuccess(user));
      },
      (err) => err
    );
}

export function unsubscribeFromMailing(params) {
  return request({
    url: "unsubscribeFromMailing",
    method: "POST",
    body: params,
  });
}

export function updateDailyReminderSubscription(params) {
  return (dispatch) =>
    request({
      url: "updateDailyReminderSubscription",
      method: "POST",
      body: params,
    }).then(
      (user) => {
        // update daily_reminder in localStorage
        const edc_user = storage.get("edc_user");
        if (!edc_user) {
          return;
        }
        const newUser = { ...edc_user, daily_reminder: user.daily_reminder };
        storage.set({
          key: "edc_user",
          value: newUser,
          persist: true,
        });

        dispatch(updateUserSuccess(user));
      },
      (err) => err
    );
}

export function updateWeeklyReminderSubscription(params) {
  return (dispatch) =>
    request({
      url: "updateWeeklyReminderSubscription",
      method: "POST",
      body: params,
    }).then(
      (user) => {
        // update weekly_reminder in localStorage
        const edc_user = storage.get("edc_user");
        if (!edc_user) {
          return;
        }
        const newUser = { ...edc_user, weekly_reminder: user.weekly_reminder };
        storage.set({
          key: "edc_user",
          value: newUser,
          persist: true,
        });

        dispatch(updateUserSuccess(user));
      },
      (err) => err
    );
}

export function updateDontSkipTwiceReminderSubscription(params) {
  return (dispatch) =>
    request({
      url: "updateDontSkipTwiceReminderSubscription",
      method: "POST",
      body: params,
    }).then(
      (user) => {
        // update ds2_reminder in localStorage
        const edc_user = storage.get("edc_user");
        if (!edc_user) {
          return;
        }
        const newUser = { ...edc_user, ds2_reminder: user.ds2_reminder };
        storage.set({
          key: "edc_user",
          value: newUser,
          persist: true,
        });

        dispatch(updateUserSuccess(user));
      },
      (err) => err
    );
}

export function unsubscribeReminders(params) {
  return request({
    url: "unsubscribeReminders",
    method: "POST",
    body: params,
  });
}

export function deleteAccount() {
  return () =>
    request({
      url: "users",
      method: "DELETE",
    }).then(() => {
      // SIMULATE LOGOUT
      storage.clear();
      if (!isExtension() && process.env.NODE_ENV === "production") {
        const crossStorage = new CrossStorageClient(config.externalAppUrl);
        crossStorage
          .onConnect()
          .then(() => crossStorage.clear())
          .then(() => {
            window.location.reload();
          });
      }
    });
}
