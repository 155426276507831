import React, { useState } from "react";
import * as Components from "components";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  DndContext,
  DragOverlay,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useDispatch, useSelector } from "react-redux";
import { useHabits } from "hooks/habits";
import { sortHabits, moveHabit } from "actions/habits";

export default function Menu() {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const dispatch = useDispatch();
  const habits = useHabits();
  const habitsEntities = useSelector((state) => state.habitsEntities);
  const [activeId, setActiveId] = useState(null);

  function getHabitKeyById(id) {
    const habit = habits.find((habit) => habit.id === id);
    return habits.indexOf(habit);
  }

  function onDragOver(event) {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      const oldIndex = getHabitKeyById(active.id);
      const newIndex = getHabitKeyById(over.id);

      dispatch(moveHabit(oldIndex, newIndex, habitsEntities[active.id]));
    }
  }

  function onDragEnd(event) {
    dispatch(sortHabits());
    setActiveId(null);
  }

  function onDragStart(event) {
    setActiveId(event.active.id);
  }

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      <SortableContext strategy={verticalListSortingStrategy} items={habits}>
        <div className="menu">
          <ul>
            {habits.map((habit) => (
              <Components.MenuItem id={habit.id} />
            ))}
          </ul>
        </div>

        <DragOverlay>
          {activeId ? (
            <div className="dragPreview">{habitsEntities[activeId].name}</div>
          ) : null}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
}
