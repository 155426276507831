import React, { useState, useEffect } from "react";
import { unsubscribeFromMailing } from "actions/users";
import messages from "utils/messages";
import { useParams } from "react-router-dom";

export default function UnsubscribeMailing() {
  const [unsubscribed, setUnsubscribed] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    unsubscribeFromMailing({ token }).then(() => setUnsubscribed(true));
  });

  return (
    <div className="unsubscribemailing-page">
      {unsubscribed ? (
        <div className="unsubscribemailing">
          {messages.UNSUBSCRIBED_MAILING}
        </div>
      ) : null}
    </div>
  );
}
