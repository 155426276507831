import chroma from "chroma-js";
import constants from "common/constants";
import { format, addDays, subDays, isEqual, isBefore } from "date-fns";

import { stringToLocaltimeDate } from "./dates";

export function findTotalStreak({ dates, index, habit }) {
  // busca quin és l'streak total de l'streak del qual aquest dia forma part
  let totalStreak = 0;
  let currentDay = stringToLocaltimeDate(dates[index].date);
  let nextDate = stringToLocaltimeDate(dates[index].date);
  let i = index;

  while (true) {
    if (!isEqual(currentDay, nextDate)) break;

    if (isEqual(currentDay, nextDate)) {
      if (!dates[index].skip) totalStreak++;

      i++;
      if (!dates[i]) break;
      if (dates[i].habit_id !== habit.id) break;
      nextDate = stringToLocaltimeDate(dates[i].date);
    }
    currentDay = addDays(currentDay, 1);
  }

  return totalStreak;
}

export function findTotalStreakFromMap({ dates, dateStr }) {
  let totalStreak = 0;
  let date = stringToLocaltimeDate(dateStr);

  for (; dates[format(date, "YYYY-MM-DD")]; date = addDays(date, 1)) {
    if (dates[format(date, "YYYY-MM-DD")].isMarked) totalStreak++;
  }
  return totalStreak;
}

export function findBeginningOfStreak({ dates, dateStr }) {
  let index = dateStr;
  let date = stringToLocaltimeDate(dateStr);

  for (; dates[format(date, "YYYY-MM-DD")]; date = subDays(date, 1)) {
    if (dates[format(date, "YYYY-MM-DD")].isMarked)
      index = format(date, "YYYY-MM-DD");
  }
  return index;
}

export function findStreakDaysFromMap({ dates, dateStr }) {
  let totalStreak = 0;
  let date = stringToLocaltimeDate(dateStr);

  for (; dates[format(date, "YYYY-MM-DD")]; date = addDays(date, 1)) {
    if (dates[format(date, "YYYY-MM-DD")]) totalStreak++;
  }
  return totalStreak;
}

export function sortDatesMapFnc(a, b) {
  if (isBefore(new Date(a), new Date(b))) return -1;
  return 1;
}

export function generateScale(colorscale, totalStreak, limit = true) {
  let scale;

  if (!limit || totalStreak > 10) {
    scale = chroma
      .scale(colorscale)
      .domain([1, totalStreak])
      .correctLightness();
  } else {
    scale = chroma.scale(colorscale).domain([1, 10]).correctLightness();
  }

  return scale;
}

export function generateColorScale({ habit }) {
  if (habit.break_habit) return constants.scales[habit.color].slice().reverse();
  return constants.scales[habit.color];
}

export function updateColorOfStreak({
  habitDates,
  habit,
  beginningOfStreak,
  totalStreak,
}) {
  let currentStreak = 0;
  const res = { ...habitDates };
  const colorscale = generateColorScale({ habit });
  const scale = generateScale(colorscale, totalStreak);
  let currentDay = stringToLocaltimeDate(beginningOfStreak);
  while (currentStreak <= totalStreak) {
    const currentDayStr = format(currentDay, "YYYY-MM-DD");
    if (!habitDates[currentDayStr]) break;
    if (habitDates[currentDayStr].isMarked) currentStreak++;
    res[currentDayStr].color = scale(currentStreak).hex();

    currentDay = addDays(currentDay, 1);
  }

  return res;
}

export function calculateHabitColors({ dates, habit }) {
  const res = { ...dates };
  const sortedDates = Object.keys(dates[habit.id]).sort(sortDatesMapFnc);
  let totalStreak = 0;
  for (let i = 0; i < sortedDates.length; i++) {
    const dateStr = sortedDates[i];
    if (totalStreak === 0) {
      totalStreak = findStreakDaysFromMap({
        dates: dates[habit.id],
        dateStr,
      });
      res[habit.id] = updateColorOfStreak({
        habitDates: res[habit.id],
        habit,
        beginningOfStreak: dateStr,
        totalStreak,
      });
    }
    totalStreak--;
  }
  return res;
}

export function getFirstMarkAfterDate({ habitDates, date }) {
  const sortedDates = Object.keys(habitDates).sort(sortDatesMapFnc);
  const firstMarkedDate = sortedDates.find((dateStr) => {
    if (dateStr !== date && habitDates[dateStr] && habitDates[dateStr].isMarked)
      return true;
    return false;
  });
  return firstMarkedDate;
}

export function updateDatesAfterMark({ dates, habit, dateToMark }) {
  const res = { ...dates };
  if (!dates[habit.id]) {
    res[habit.id] = {
      [dateToMark]: {
        isMarked: true,
      },
    };
  } else {
    res[habit.id][dateToMark] = { isMarked: true };
  }

  const beginningOfStreak = findBeginningOfStreak({
    dates: res[habit.id],
    dateStr: dateToMark,
  });
  const totalStreak = findTotalStreakFromMap({
    dates: res[habit.id],
    dateStr: beginningOfStreak,
  });
  res[habit.id] = updateColorOfStreak({
    habitDates: res[habit.id],
    habit,
    beginningOfStreak,
    totalStreak,
  });

  return res;
}

export function updateDatesAfterUnmark({ dates, habit, dateToUnmark }) {
  const res = { ...dates };

  delete res[habit.id][dateToUnmark];

  const date = new Date(dateToUnmark);

  const dateStrBefore = format(subDays(date, 1), "YYYY-MM-DD");
  const dateStrAfter = format(addDays(date, 1), "YYYY-MM-DD");

  if (res[habit.id][dateStrBefore]) {
    const beginningOfStreak = findBeginningOfStreak({
      dates: res[habit.id],
      dateStr: dateStrBefore,
    });
    const totalStreak = findTotalStreakFromMap({
      dates: res[habit.id],
      dateStr: beginningOfStreak,
    });

    res[habit.id] = updateColorOfStreak({
      habitDates: res[habit.id],
      habit,
      beginningOfStreak,
      totalStreak,
    });
  }
  if (res[habit.id][dateStrAfter]) {
    const totalStreak = findTotalStreakFromMap({
      dates: res[habit.id],
      dateStr: dateStrAfter,
    });

    res[habit.id] = updateColorOfStreak({
      habitDates: res[habit.id],
      habit,
      beginningOfStreak: dateStrAfter,
      totalStreak,
    });
  }
  return res;
}

export function updateDatesAfterSkip({ dates, habit, dateToSkip }) {
  const res = { ...dates };
  if (!res[habit.id]) res[habit.id] = {};
  if (!res[habit.id][dateToSkip]) res[habit.id][dateToSkip] = {};
  res[habit.id][dateToSkip].isMarked = false;
  res[habit.id][dateToSkip].isSkip = true;

  const beginningOfStreak = findBeginningOfStreak({
    dates: res[habit.id],
    dateStr: dateToSkip,
  });
  const totalStreak = findTotalStreakFromMap({
    dates: res[habit.id],
    dateStr: beginningOfStreak,
  });

  res[habit.id] = updateColorOfStreak({
    habitDates: res[habit.id],
    habit,
    beginningOfStreak,
    totalStreak,
  });

  return res;
}

export function calculateDatesMap({ dates, habits }) {
  const dateMap = {};

  let counter;
  let totalStreak;
  let habit;
  let scale;
  let colorscale;
  let currentTotalStreak;

  dates.forEach((dateObj, index) => {
    const date = stringToLocaltimeDate(dateObj.date);
    const today = format(date, "YYYY-MM-DD");
    const yesterday = format(subDays(date, 1), "YYYY-MM-DD");

    if (!habit || habit.id !== dateObj.habit_id) {
      habit = habits[dateObj.habit_id];
      colorscale = generateColorScale({ habit });
      counter = 0;
      totalStreak = findTotalStreak({ dates, index, habit });
      scale = generateScale(colorscale, totalStreak);
    }

    if (!dateMap[habit.id]) dateMap[habit.id] = {};
    dateMap[habit.id][today] = {};

    if (dateObj.skip) dateMap[habit.id][today].isSkip = true;
    else dateMap[habit.id][today].isMarked = true;

    if (dateMap[habit.id][yesterday]) {
      // Ahir MARK/SKIP i avui SKIP
      if (!dateObj.skip) counter++;
    } else if (dateObj.skip) {
      counter = 0;
      currentTotalStreak = findTotalStreak({ dates, index, habit });
      scale = generateScale(colorscale, currentTotalStreak);
      // Ahir UNMARK i avui MARK
    } else {
      currentTotalStreak = findTotalStreak({ dates, index, habit });
      scale = generateScale(colorscale, currentTotalStreak);
      counter = 1;
    }

    dateMap[habit.id][today].color = scale(counter).hex();
  });

  return dateMap;
}

export function findInitialScalePointer(dates, day) {
  let scalePointer = 1;
  for (; dates[day.format("YYYY-MM-DD")]; day.subtract(1, "days")) {
    if (dates[day.format("YYYY-MM-DD")] > 0) {
      scalePointer = dates[day.format("YYYY-MM-DD")];
      break;
    }
  }
  return scalePointer;
}
