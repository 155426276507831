import React from "react";
import { connect } from "react-redux";
import { format, isToday, isWeekend } from "date-fns";
import { showModal } from "actions/modal";
import { mark, unmark, skip } from "actions/dates";
import { isChromeExtension } from "utils/extension";

class Cell extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.isMarked !== this.props.isMarked) return true;
    if (nextProps.isSkip !== this.props.isSkip) return true;
    if (nextProps.habitColor !== this.props.habitColor) return true;
    if (nextProps.habitName !== this.props.habitName) return true;
    if (nextProps.habitId !== this.props.habitId) return true;
    if (nextProps.color !== this.props.color) return true;
    if (nextProps.dateStr !== this.props.dateStr) return true;
    if (nextProps.isPremium !== this.props.isPremium) return true;
    return false;
  }

  showPremiumFeatureModal() {
    this.props.dispatch(
      showModal("premiumFeature", { class: "premiumfeature" })
    );
  }

  mark(date, habit_id) {
    const device = "web";
    this.props.dispatch(mark(date, habit_id, device));
  }

  unmark(date, habit_id) {
    this.props.dispatch(unmark(date, habit_id));
  }

  skip(date, habit_id) {
    this.props.dispatch(skip(date, habit_id));
  }

  render() {
    const {
      isMarked,
      isSkip,
      habitColor,
      habitName,
      habitId,
      color,
      dateStr,
      today,
      isPremium,
    } = this.props;
    let className = "" + habitColor;
    let dataDesc = habitName;
    let onClick = this.mark.bind(this, dateStr, habitId);
    const isSafari = navigator.vendor.indexOf("Apple") > -1;
    if (!isSafari) className += " not-safari";
    if (isChromeExtension()) className += " popup-extension";
    if (today) className += " today";

    if (isWeekend(dateStr)) {
      className += " weekend";
    }
    if (isMarked) {
      onClick = this.skip.bind(this, dateStr, habitId);
      className += " done";
      dataDesc = "skip";
    }
    if (isSkip) {
      onClick = this.unmark.bind(this, dateStr, habitId);
      className += " skip";
      dataDesc = "unmark";
    }

    if (isPremium) onClick = this.showPremiumFeatureModal.bind(this);

    return (
      <td
        key={`${habitId} - ${dateStr}`}
        className={className}
        style={{ backgroundColor: color }}
        data-descr-done={dataDesc}
        onClick={onClick}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const dateStr = format(props.date, "YYYY-MM-DD");
  const habit = state.habitsEntities[props.habitId] || {};
  const habitDates = state.dates[props.habitId] || {};
  const habitDate = habitDates[dateStr] || {};
  const today = isToday(props.date);
  const { isMarked, isSkip, color } = habitDate;
  const { color: habitColor, name: habitName, id: habitId } = habit;
  return {
    habit,
    isMarked,
    isSkip,
    color,
    dateStr,
    habitColor,
    habitName,
    habitId,
    today,
  };
}

export default connect(mapStateToProps)(Cell);
