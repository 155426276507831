import store from "../stores";
import { sendEvent } from "./events";
import config from "config";
import request from "./request";
import debounce from "lodash/debounce";
import fetch from "isomorphic-fetch";
import { isAfter, isBefore, addSeconds } from "date-fns";
import { fetchHabits } from "actions/habits";
import * as storage from "./storage";
import Ping from "ping.js";

let networkInterval;
let _isOnline = true;
let fetchData = false;
let newHabitQueue = [];
let emptyingQueue = false;
let queue = [];
let lastRequestByAction = {};

const storeNetwork = debounce(() => {
  storage.set({
    key: "network",
    persist: true,
    value: {
      queue,
      newHabitQueue,
    },
  });
}, 1500);

const p = new Ping();

export async function pingUrl(url) {
  try {
    const res = await p.ping(url);
    console.log("ping...", url, res);
    if (res) return true;
    return false;
  } catch (e) {
    console.log("ping...", url, "NO RESPONSE", e);
    return false;
  }
}

export async function pingAPI() {
  try {
    const res = await fetch(`${config.apiUrl}/ping`, {
      method: "HEAD",
    });
    console.log("ping api...", res.status);
    if (res.status === 200) return true;
    return false;
  } catch (e) {
    console.log("ping api...", "NO RESPONSE");
    return false;
  }
}

let connectivityPromise = null;
let isCheckingConnectivity = false;

export async function checkConnectivity() {
  if (isCheckingConnectivity) return connectivityPromise;
  connectivityPromise = new Promise(async (resolve, reject) => {
    try {
      isCheckingConnectivity = true;
      let isReachable = await pingUrl("https://www.google.com");
      if (isReachable) {
        resolve(true);
        isCheckingConnectivity = false;
        return;
      }
      isReachable = await pingAPI();
      if (isReachable) {
        resolve(true);
        isCheckingConnectivity = false;
        return;
      }
      resolve(false);
      isCheckingConnectivity = false;
    } catch (e) {
      isCheckingConnectivity = false;
      return reject(e);
    }
  });

  return connectivityPromise;
}

export function isOnline() {
  return _isOnline;
}

export function clearNetwork() {
  fetchData = false;
  queue = [];
  newHabitQueue = [];
  if (networkInterval) clearInterval(networkInterval);
  networkInterval = null;
  storeNetwork();
}

export function clearNetworkInterval() {
  if (networkInterval) clearInterval(networkInterval);
  networkInterval = null;
}

export function hasPendingDataToSync() {
  return newHabitQueue.length > 0 || queue.length > 0;
}

export async function initNetwork() {
  clearNetworkInterval();

  const storedNetwork = await storage.get("network");
  queue = storedNetwork ? storedNetwork.queue : [];
  newHabitQueue = storedNetwork ? storedNetwork.newHabitQueue : [];

  async function connectivityCheck() {
    const isOnlineNow = await checkConnectivity();
    const state = store.getState();
    const serverLastAction = state.user.server_last_action;
    if (!isOnline() && isOnlineNow) {
      // back online after offline, check if the server has new data.
      _isOnline = true;
      if (state.user.id) {
        const remoteUser = await request({
          url: "users/" + state.user.id,
          method: "GET",
        });
        if (!fetchData)
          fetchData = isAfter(
            new Date(remoteUser.last_action),
            serverLastAction
          );
      }
    }
    if (isOnline() && !isOnlineNow) setOffline();
    if (!isOnline()) return;

    if (newHabitQueue.length > 0) {
      if (isOnline()) {
        emptyingQueue = true;

        try {
          await Promise.all(newHabitQueue.map(request));
        } catch (e) {}
      }
      emptyingQueue = false;
    }

    if (queue.length > 0 && newHabitQueue.length === 0 && !emptyingQueue) {
      if (isOnline()) {
        emptyingQueue = true;
        try {
          await request({
            url: "batch",
            method: "POST",
            action: "BATCH",
            body: { actions: queue },
          });
        } catch (e) {}
      }
      emptyingQueue = false;
    }
    //fetch data if there are no local actions to sync and the server has new data

    if (
      queue.length === 0 &&
      newHabitQueue.length === 0 &&
      fetchData &&
      !emptyingQueue &&
      !state.queue.fetchingHabits
    ) {
      await store.dispatch(fetchHabits());
      fetchData = false;
    }
  }

  networkInterval = setInterval(connectivityCheck, 5000);
  connectivityCheck();
}
export function setOffline(request) {
  _isOnline = false;
  if (request) preRequest(request);
}

function isSpamming(action) {
  const lastRequest = lastRequestByAction[action];
  console.log("checkingSpam...", lastRequestByAction);
  if (lastRequest && isBefore(new Date(), addSeconds(lastRequest, 10))) {
    console.log("Spam prevented!");
    return true;
  }
  lastRequestByAction[action] = new Date();
  return false;
}

// return true if the request has to be performed.
export function preRequest(request) {
  if (!request.action) return request;

  let requestParams = request;

  switch (request.action) {
    case "DELETE_HABIT":
      queue = queue.filter((action) => action.habitId !== request.habitId);
      newHabitQueue = newHabitQueue.filter(
        (action) => action.habitId !== request.habitId
      );

      if (!isOnline()) {
        if (!queue.includes(request)) queue.push(request);
        requestParams = false;
      }
      break;
    case "ADD_HABIT":
      if (!isOnline()) {
        if (!newHabitQueue.includes(request)) newHabitQueue.push(request);

        requestParams = false;
      }
      break;
    case "MARK":
    case "UNMARK":
    case "SKIP":
    case "EDIT_HABIT":
      if (!isOnline()) {
        if (!queue.includes(request)) queue.push(request);
        requestParams = false;
      }
      break;
    case "SORT_HABITS":
      queue = queue.filter((action) => action.action !== "SORT_HABITS");
      queue.push(request);
      requestParams = false;
      break;
    case "AUTOSKIP":
      if (!queue.includes(request)) queue.push(request);
      requestParams = false;
      break;
    case "BATCH":
      if (!isOnline()) {
        queue = request.body.actions;
        requestParams = false;
      }
      break;
    case "FETCH_HABITS":
      if (!isOnline() || isSpamming(request.action)) return false;
      break;

    default:
      if (!isOnline()) return false;
  }

  storeNetwork();
  return requestParams;
}

export function postRequest({ request, response }) {
  switch (request.action) {
    case "ADD_HABIT":
      if (response.id) {
        store.dispatch({
          type: "UPDATE_HABIT_AFTER_REQUEST",
          oldHabit: request.body,
          newHabit: response,
        });
      }
      newHabitQueue = newHabitQueue.filter((action) => action !== request);
      storeNetwork();
      break;
    case "BATCH":
      queue = [];
      storeNetwork();
      break;
    default:
      break;
  }
}
