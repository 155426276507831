import request from "utils/request";

import mixpanel from "utils/mixpanel";
export async function sendEvent(event, props = {}) {
  try {
    // mixpanel.track(event, props);
    // CleverTap.recordEvent(event, props);
  } catch (e) {
    console.log("send event failed", e);
  }
}
