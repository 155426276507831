/* eslint-disable */

const themes = {
  light: {
    "--color-black": "black",
    "--color-white": "white",
    "--background": "#fff",
    "--main-font-color": "#6F7A80",
    "--light-grey": "#A8AFB3",
    "--light-black": "#1C242B",
    "--border": "#DFE3E6",
    "--border-inner": "#fff",
    "--menu-background": "#fff",
    "--color-picker-background": "#fff",
    "--weekend-background": "#f6f6f6",
    "--cell-yearview-background": "#ebedf0",
    "--skip-triangle-svg":
      "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj4NCiAgPHBvbHlnb24gcG9pbnRzPSIwLDAgMTAwLDAgMTAwLDEwMCIgZmlsbD0nI2ZmZmZmZicgLz4NCjwvc3ZnPg0K)",
    "--noweekend-triangle-svg":
      "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPgogIDxwb2x5Z29uIHBvaW50cz0iMCwwIDEwMCwwIDEwMCwxMDAiIGZpbGw9IiNmNmY2ZjYiLz4KPC9zdmc+)",
    "--habit-view-title-color": "#333",
    "--modal-cancel-button": "#999",
    "--main-dark": "#249c03",
    "--main": "#2fcf03"
  },
  dark: {
    "--color-black": "white",
    "--color-white": "black",
    "--background": "#121212",
    "--main-font-color": "#fff",
    "--light-grey": "#A8AFB3",
    "--light-black": "#fff",
    "--border": "#333",
    "--border-inner": "#121212",
    "--weekend-background": "#333",
    "--cell-yearview-background": "#333",
    "--skip-triangle-svg":
      "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPgogIDxwb2x5Z29uIHBvaW50cz0iMCwwIDEwMCwwIDEwMCwxMDAiIGZpbGw9IiMxMjEyMTIiLz4KPC9zdmc+)",
    "--noweekend-triangle-svg":
      "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPgogIDxwb2x5Z29uIHBvaW50cz0iMCwwIDEwMCwwIDEwMCwxMDAiIGZpbGw9IiMzMzMiLz4KPC9zdmc+)",
    "--habit-view-title-color": "#212121",
    "--modal-cancel-button": "white",
    "--color-picker-background": "#333",
    "--menu-background": "#121212",
    "--main-dark": "#249c03",
    "--main": "#2fcf03"
  }
};

export function applyTheme(theme) {
  const nextTheme = themes[theme];
  Object.keys(nextTheme).map(key => {
    const value = nextTheme[key];
    document.documentElement.style.setProperty(key, value);
    return null;
  });
  return null;
}
