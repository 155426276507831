import request from "utils/request";
import * as storage from "utils/storage";
import { updateUserSuccess } from "actions/auth";
import messages from "utils/messages";
import { sendEvent } from "utils/events";
import history from "utils/history";

export function makeSubscription(params) {
  return (dispatch, getState) =>
    request({
      url: "makeWebSubscription",
      method: "POST",
      body: params,
    }).then((res) => {
      if (res.requiresAction || res.requiresPaymentMethod || res.noRedeemsLeft)
        return res;
      dispatch(updateUserSuccess(res));
      const edc_user = storage.get("edc_user");
      const newUser = {
        ...edc_user,
        paid_until: res.paid_until,
        premium_access: res.premium_access,
        last_action: res.last_action,
        subscribed: res.subscribed,
      };
      storage.set({
        key: "edc_user",
        value: newUser,
        persist: true,
      });

      const state = getState();

      sendEvent("action:subscription", params);

      // const premium_access = moment(res.premium_access).format("DD MMMM YYYY");

      dispatch({
        type: "SHOW_BANNER",
        message: messages.S_CONFIRMATION,
      });
      history.replace("/");

      return res;
    });
}

export function confirmSubscription(params) {
  return (dispatch, getState) =>
    request({
      url: "confirmWebSubscription",
      method: "POST",
      body: params,
    }).then((res) => {
      dispatch(updateUserSuccess(res));

      const state = getState();
      sendEvent("action:subscription", params);

      const edc_user = storage.get("edc_user");
      const newUser = {
        ...edc_user,
        paid_until: res.paid_until,
        premium_access: res.premium_access,
        last_action: res.last_action,
        subscribed: res.subscribed,
      };

      dispatch({
        type: "SHOW_BANNER",
        message: messages.S_CONFIRMATION,
      });
      history.replace("/");

      storage.set({
        key: "edc_user",
        value: newUser,
        persist: true,
      });
    });
}

export function updateSubscription(params) {
  return (dispatch) =>
    request({
      url: "subscriptions",
      method: "PUT",
      body: params,
    }).then((user) => {
      // update subscribed in localStorage
      // updates paid_until also in case of resubscription for expired subs
      const edc_user = storage.get("edc_user");
      const newUser = {
        ...edc_user,
        paid_until: user.paid_until,
        premium_access: user.premium_access,
        subscribed: user.subscribed,
      };
      storage.set({
        key: "edc_user",
        value: newUser,
        persist: true,
      });

      dispatch(updateUserSuccess(user));
    });
}
