import React, { useState, useEffect } from "react";
import { unsubscribeReminders } from "actions/users";
import messages from "utils/messages";
import { useParams, useLocation } from "react-router-dom";

export default function UnsubscribeReminders() {
  const [unsubscribed, setUnsubscribed] = useState(false);
  const { token } = useParams();
  const location = useLocation();

  useEffect(() => {
    const params = {
      token,
    };
    switch (location.pathname.split("/")[1]) {
      case "unsubscribe-daily-reminders":
        params.reminder = 0;
        unsubscribeReminders(params).then(() => setUnsubscribed(true));
        break;
      case "unsubscribe-weekly-reminders":
        params.reminder = 1;
        unsubscribeReminders(params).then(() => setUnsubscribed(true));
        break;
      case "unsubscribe-ds2-reminders":
        params.reminder = 2;
        unsubscribeReminders(params).then(() => setUnsubscribed(true));
        break;
      case "unsubscribe-all-reminders":
        params.reminder = 3;
        unsubscribeReminders(params).then(() => setUnsubscribed(true));
        break;
      case "unsubscribe-challenge-reminders":
        params.reminder = 4;
        unsubscribeReminders(params).then(() => setUnsubscribed(true));
        break;
      default:
        break;
    }
  });

  return (
    <div className="unsubscribereminders-page">
      {unsubscribed ? (
        <div className="unsubscribereminders">
          {messages.UNSUBSCRIBED_REMINDERS}
        </div>
      ) : null}
    </div>
  );
}
