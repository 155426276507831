import React from "react";
import { Link } from "react-router-dom";
import messages from "utils/messages";

export default class SubNav extends React.Component {
  render() {
    return (
      <div className="subnav">
        <ul className="left">
          <li>
            <Link to="/" activeClassName="active">
              {messages.SN_BOARD}
            </Link>
          </li>
          <li>
            <Link to="/yearly" activeClassName="active">
              {messages.SN_YEARLY_VIEW}
            </Link>
          </li>
        </ul>
        <ul className="right">
          {this.props.user.name && !this.props.user.premium_access ? (
            <a className="subscribe-button" onClick={this.props.subscribe}>
              {messages.SUBSCRIBE}
            </a>
          ) : null}
          <li>
            <Link to="/archive" activeClassName="active">
              {messages.SN_ARCHIVE}
            </Link>
          </li>
          <li>
            <Link to="/connect" activeClassName="active">
              {messages.SN_CONNECT}
            </Link>
          </li>
          <li>
            <Link to="/invite" activeClassName="active">
              {messages.SN_INVITE}
            </Link>
          </li>
        </ul>
        <div className="clear" />
      </div>
    );
  }
}
