import * as storage from "utils/storage";

export function isExtension() {
  const isIframe = window !== window.top;
  return isIframe;
}

export function isPopupExtension() {
  const isPopup = storage.get("isPopupExtension");
  console.log("isPopup", isPopup);
  return isPopup;
}

export function isChromeExtension() {
  // all but safari

  const isSafari = navigator.vendor.indexOf("Apple") > -1;

  return !isSafari && window.isPopupExtension;
}

export function isSafariExtension() {
  const isSafari = navigator.vendor.includes("Apple");
  const isIframe = window !== window.top;
  console.log("isSafari", isSafari);
  return isSafari && isIframe;
}

export function isSafariPopupExtension() {
  const isSafari = navigator.vendor.includes("Apple");
  const isPopup = storage.get("isPopupExtension");

  return isSafari && isPopup;
}
