import React from "react";
import { connect } from "react-redux";
import { showModal } from "actions/modal";
import messages from "utils/messages";
import { Link } from "react-router-dom";

class Subscribe extends React.Component {
  componentDidMount() {
    this.props.dispatch(
      showModal("subscription", { showBrightModal: true, class: "premium" })
    );
  }

  onSubscribe() {
    this.props.dispatch(
      showModal("subscription", { showBrightModal: true, class: "premium" })
    );
  }

  render() {
    return (
      <div className="subscribe-page">
        <button
          onClick={this.onSubscribe.bind(this)}
          type="submit"
          className="subscribe-button"
        >
          {messages.SUBSCRIBE}
        </button>
        <Link to="/settings" className="settings-link">
          {messages.TOSETTINGS}
        </Link>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(Subscribe);
