import React from "react";
import { connect } from "react-redux";
import messages from "utils/messages";
import { hideModal } from "actions/modal";

import Science from "../Science/Science";
import Streaks from "../Streaks/Streaks";

const steps = [Science, Streaks];

class OnboardingModal extends React.Component {
  state = {
    step: 0,
  };

  render() {
    const Step = steps[this.state.step];
    return (
      <div className="onboarding">
        <div className="onboarding-content">
          <Step />
        </div>
        <div className="onboarding-controls">
          <a onClick={() => this.props.dispatch(hideModal())}>
            {messages.SKIP}
          </a>
          <a
            onClick={() =>
              this.setState((state) => ({
                step: state.step + 1,
              }))
            }
          >
            {messages.NEXT}
          </a>
        </div>
      </div>
    );
  }
}

export default connect()(OnboardingModal);
