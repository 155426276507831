import React from "react";
import messages from "utils/messages";
import { connect } from "react-redux";
import {
  updateDailyReminderSubscription,
  updateWeeklyReminderSubscription,
  updateDontSkipTwiceReminderSubscription,
} from "actions/users";

class EmailReminders extends React.Component {
  handleDailyReminderSubscription(subscribed) {
    this.props.dispatch(updateDailyReminderSubscription({ subscribed }));
  }

  handleWeeklyReminderSubscription(subscribed) {
    this.props.dispatch(updateWeeklyReminderSubscription({ subscribed }));
  }

  handleDontSkipTwiceReminderSubscription(subscribed) {
    this.props.dispatch(
      updateDontSkipTwiceReminderSubscription({ subscribed })
    );
  }

  render() {
    return (
      <div className="email-reminders">
        <div className="block">
          <i className="ion-ios-bell-outline" />
          <h3>{messages.S_EMAIL_REMINDERS}</h3>
          <a className="text">{messages.S_ER_TEXT}</a>
          <div className="line" />
        </div>
        <div className="block">
          {this.props.user.daily_reminder ? (
            <div className="reminders-actions">
              <h4>{messages.S_ER_DAILY}</h4>
              <a>{messages.S_ER_DAILY_TEXT}</a>
              <div className="line" />
              <a>{messages.S_ER_DR_TURNED_ON}</a>
              <button
                type="button"
                onClick={this.handleDailyReminderSubscription.bind(this, false)}
              >
                {messages.S_ER_TURN_OFF}
              </button>
            </div>
          ) : (
            <div className="reminders-actions">
              <h4>{messages.S_ER_DAILY}</h4>
              <a>{messages.S_ER_DAILY_TEXT}</a>
              <div className="line" />
              <a>{messages.S_ER_DR_TURNED_OFF}</a>
              <button
                type="button"
                className="turnon"
                onClick={this.handleDailyReminderSubscription.bind(this, true)}
              >
                {messages.S_ER_TURN_ON}
              </button>
            </div>
          )}
          {this.props.user.weekly_reminder ? (
            <div className="reminders-actions">
              <h4>{messages.S_ER_WEEKLY}</h4>
              <a>{messages.S_ER_WEEKLY_TEXT}</a>
              <div className="line" />
              <a>{messages.S_ER_WR_TURNED_ON}</a>
              <button
                type="button"
                onClick={this.handleWeeklyReminderSubscription.bind(
                  this,
                  false
                )}
              >
                {messages.S_ER_TURN_OFF}
              </button>
            </div>
          ) : (
            <div className="reminders-actions">
              <h4>{messages.S_ER_WEEKLY}</h4>
              <a>{messages.S_ER_WEEKLY_TEXT}</a>
              <div className="line" />
              <a>{messages.S_ER_WR_TURNED_OFF}</a>
              <button
                type="button"
                className="turnon"
                onClick={this.handleWeeklyReminderSubscription.bind(this, true)}
              >
                {messages.S_ER_TURN_ON}
              </button>
            </div>
          )}
          {this.props.user.ds2_reminder ? (
            <div className="reminders-actions">
              <h4>{messages.S_ER_DS2}</h4>
              <a>{messages.S_ER_DS2_TEXT}</a>
              <div className="line" />
              <a>{messages.S_ER_DS2_TURNED_ON}</a>
              <button
                type="button"
                onClick={this.handleDontSkipTwiceReminderSubscription.bind(
                  this,
                  false
                )}
              >
                {messages.S_ER_TURN_OFF}
              </button>
            </div>
          ) : (
            <div className="reminders-actions">
              <h4>{messages.S_ER_DS2}</h4>
              <a>{messages.S_ER_DS2_TEXT}</a>
              <div className="line" />
              <a>{messages.S_ER_DS2_TURNED_OFF}</a>
              <button
                type="button"
                className="turnon"
                onClick={this.handleDontSkipTwiceReminderSubscription.bind(
                  this,
                  true
                )}
              >
                {messages.S_ER_TURN_ON}
              </button>
            </div>
          )}
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default connect()(EmailReminders);
