import React from "react";
import { connect } from "react-redux";
import messages from "utils/messages";
import * as Components from "components";
import { sendEvent } from "utils/events";
import { showModal } from "actions/modal";

class PremiumFeatureModal extends React.Component {
  componentDidMount() {
    switch (this.props.type) {
      case "darkmode":
        this.origin = "DarkModeUpgradeModal";
        break;
      case "archive":
        this.origin = "ArchiveUpgradeModal";
        break;
      default:
        this.origin = "3HabitsUpgradeModal";
    }

    sendEvent({
      type: `Web${this.origin}`,
    });
  }

  onUpgrade() {
    this.props.dispatch(
      showModal("subscription", {
        showBrightModal: true,
        class: "premium",
        origin: this.origin,
      })
    );
  }

  render() {
    let title;
    let text;

    switch (this.props.type) {
      case "darkmode":
        title = messages.MOD_PREMIUM_DARKMODE;
        text = messages.MOD_PREMIUM_DARKMODE_PREMIUM;
        break;
      case "archive":
        title = messages.MOD_PREMIUM_ARCHIVE;
        text = messages.MOD_PREMIUM_ARCHIVE_PREMIUM;
        break;
      default:
        title = messages.MOD_PREMIUM_3HABITS;
        text = messages.MOD_PREMIUM_3HABITS_PREMIUM;
    }

    return (
      <div className="premiumFeatureModal">
        <div className="iconWrapper">
          <Components.Icon name="Star" color="#249c03" height="60" width="60" />
        </div>
        <a className="premium-feature">{title}</a>
        <a className="premium-text">{text}</a>
        <div className="options">
          <a
            href="https://everyday.app/pricing"
            target="_blank"
            rel="noreferrer"
            className="option learn"
          >
            {messages.MOD_LEARN}
          </a>
          <button
            onClick={this.onUpgrade.bind(this)}
            type="submit"
            className="upgrade-button"
          >
            {messages.MOD_UPGRADE}
          </button>
        </div>
        <div className="clear" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(PremiumFeatureModal);
