import React from "react";
import messages from "utils/messages";
import * as Components from "components";

export default class NoHabits extends React.Component {
  render() {
    return !this.props.isArchive ? (
      <div className="nohabits">
        <h2>{messages.EMPTY_TITLE_1}</h2>
        <h2>{messages.EMPTY_TITLE_2}</h2>
        <button onClick={this.props.showNewHabitModal} type="button">
          {messages.EMPTY_BUTTON}
        </button>
        <Components.WebExtensions />
        <Components.Tips />
      </div>
    ) : (
      <div className="nohabits">
        <h2>{messages.EMPTY_ARCHIVE_1}</h2>
        <h2>{messages.EMPTY_ARCHIVE_2}</h2>
      </div>
    );
  }
}
