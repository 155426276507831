import React from "react";
import messages from "utils/messages";
import { connect } from "react-redux";
import { updateMailingSubscription } from "actions/users";

class MailingList extends React.Component {
  handleMailingSubscription(subscribed) {
    this.props.dispatch(updateMailingSubscription({ subscribed }));
  }

  render() {
    return (
      <div className="mailing-list">
        <div className="block">
          <i className="ion-ios-email-outline" />
          <h3>{messages.S_MAILING_LIST}</h3>
          <a className="text">{messages.S_ML_TEXT}</a>
          <div className="line" />
        </div>
        <div className="block">
          {this.props.user.mailing_list ? (
            <div className="mailing-actions">
              <a>{messages.S_SUBS_TEXT}</a>
              <button
                type="button"
                onClick={this.handleMailingSubscription.bind(this, false)}
              >
                {messages.S_UNSUBSCRIBE}
              </button>
            </div>
          ) : (
            <div className="mailing-actions">
              <a>{messages.S_UNSUBS_TEXT}</a>
              <button
                type="button"
                className="subscribe"
                onClick={this.handleMailingSubscription.bind(this, true)}
              >
                {messages.S_SUBSCRIBE}
              </button>
            </div>
          )}
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default connect()(MailingList);
