export function set(params) {
  const value = JSON.stringify(params.value);
  // this.remove(params.key);
  //
  if (params.persist) {
    localStorage.setItem(params.key, value);
  } else {
    sessionStorage.setItem(params.key, value);
  }
}

export function get(key) {
  const value =
    localStorage.getItem(key) && localStorage.getItem(key) !== "null"
      ? localStorage.getItem(key)
      : sessionStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

export function remove(key) {
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
}

export function clear() {
  localStorage.clear();
  sessionStorage.clear();
}
