export default function habits(state = {}, action) {
  const newState = { ...state };
  switch (action.type) {
    case "FETCH_HABITS_SUCCESS":
      // action.habits.forEach(habit => { blankState[habit.id] = habit; });
      // return blankState;
      return action.habitsHash;
    case "FETCH_HABIT_SUCCESS":
    case "ADD_HABIT_SUCCESS":
    case "EDIT_HABIT_SUCCESS":
      newState[action.habit.id] = action.habit;
      return { ...state, ...newState };
    case "UPDATE_HABIT_AFTER_REQUEST":
      newState[action.newHabit.id] = newState[action.oldHabit.id];
      delete newState[action.oldHabit.id];

      newState[action.newHabit.id].tmp = false;
      newState[action.newHabit.id].tmpId = action.oldHabit.id;
      newState[action.newHabit.id].id = action.newHabit.id;
      return newState;
    case "DELETE_HABIT_SUCCESS":
      delete newState[action.habit_id];
      return newState;
    case "MARK_SUCCESS":
      newState[action.habit.id] = {
        ...action.habit,
        totalCount: action.habit.totalCount + 1,
      };
      return { ...state, ...newState };
    case "SKIP_SUCCESS":
      newState[action.habit.id] = {
        ...action.habit,
        totalCount: action.habit.totalCount - 1,
      };
      return { ...state, ...newState };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
}
