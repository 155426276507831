import {
  updateDatesAfterMark,
  updateDatesAfterUnmark,
  updateDatesAfterSkip,
  calculateHabitColors,
} from "../utils/board";

export default function dates(state = {}, action) {
  const newState = { ...state };
  switch (action.type) {
    case "FETCH_DATES_SUCCESS":
      if (action.overwrite) return action.dates;
      return { ...state, ...action.dates };
    case "DELETE_HABIT_SUCCESS":
      delete newState[action.habit_id];
      return newState;
    case "MARK_SUCCESS":
      return updateDatesAfterMark({
        dates: state,
        habit: action.habit,
        dateToMark: action.date,
      });
    case "UNMARK_SUCCESS":
      return updateDatesAfterUnmark({
        dates: state,
        habit: action.habit,
        dateToUnmark: action.date,
      });
    case "SKIP_SUCCESS":
      return updateDatesAfterSkip({
        dates: state,
        habit: action.habit,
        dateToSkip: action.date,
      });
    case "UPDATE_HABIT_COLOR":
      return calculateHabitColors({
        dates: state,
        habit: action.habit,
      });
    case "LOGOUT":
      return {};
    default:
      return state;
  }
}
