import React from "react";
import ReactDOM from "react-dom";
import * as storage from "utils/storage";
import * as Sentry from "@sentry/browser";
import { isBefore } from "date-fns";
import { CrossStorageHub } from "cross-storage";
import config from "config";
import { changeTheme } from "actions/theme";
import { fetchHabits } from "actions/habits";
import { App } from "containers";
import { syncData } from "actions/users";
import { restore, setLoggedUserFromQueryParam } from "actions/auth";
import { initPromo, setSourcePromo } from "actions/promos";
import { sendEvent } from "utils/events";
import { applyTheme } from "utils/themes";
import { isExtension, isSafariExtension } from "utils/extension";
import store from "./stores";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { initMixpanel } from "utils/mixpanel";

require("styles/base.scss");

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
console.log("CURRENT VERSION:", config.version);

console.log(new RegExp(config.externalApp));

//if (!isExtension())
CrossStorageHub.init([
  {
    // origin: config.externalApp,
    origin: new RegExp(config.externalApp),
    allow: ["get", "set", "del", "getKeys", "clear"],
  },
]);

Sentry.init({
  dsn: "https://92521b5c8bcd470cb0298889f39ba259@sentry.io/2068974",
});

initMixpanel();

console.log("restoring.....");
store.dispatch(restore());
const state = store.getState();
applyTheme(state.variables.theme);

const urlParams = new URLSearchParams(window.location.search);
const promo = urlParams.get("promo");
const userParam = urlParams.get("user");

if (promo) {
  storage.set("promo", promo);
}

if (userParam) {
  store.dispatch(setLoggedUserFromQueryParam(userParam));
  const data = { type: "SOCIAL_LOGIN", user: userParam };
  window.postMessage(data, "*");
  if (window.opener) {
    window.opener?.postMessage(
      {
        type: "SOCIAL_LOGIN_REMOTE_WINDOW",
        user: userParam,
      },
      "*"
    );
  }
  window.close();
}

//Safari extension
console.log("isSafariExtension", isSafariExtension());
if (!state.user.id && !userParam && isSafariExtension()) {
  console.log("request user info");
  window.parent.postMessage({ type: "REQUEST_USER_INFO" }, "*");
} else if (state.user.id) {
  console.log("user info already loaded");
  window.parent &&
    window.parent.postMessage(
      { type: "SOCIAL_LOGIN", user: JSON.stringify(state.user) },
      "*"
    );
}

(async () => {
  console.log("init promo");
  await store.dispatch(initPromo());
  const social = urlParams.get("social"); // social login
  if (social) {
    store.dispatch(setSourcePromo());
  }
})();

const version = storage.get("edc_state_version");

if (state.user.id && version !== config.storageVersion) {
  console.log("diferent storage version");
  store.dispatch(fetchHabits());
  storage.set({
    key: "edc_state_version",
    value: config.storageVersion,
    persist: true,
  });
}

var currentUrl = window.location.href;

// Parse the URL to get query parameters
var urlObj = new URL(currentUrl);
var queryParams = new URLSearchParams(urlObj.search);

// Get a specific query parameter
var isPopupExtension = queryParams.get("isPopupExtension");
console.log("isPopupExtension", isPopupExtension);
storage.set({
  key: "isPopupExtension",
  value: isPopupExtension,
  persist: false,
});

//set user from social login. This is used for the extension
window.addEventListener("message", (e) => {
  if (e.data.type === "SOCIAL_LOGIN_REMOTE_WINDOW") {
    let state = store.getState();
    if (!state.user?.id) {
      store.dispatch(setLoggedUserFromQueryParam(e.data.user));
    }

    state = store.getState();

    window.parent?.postMessage(
      {
        type: "SOCIAL_LOGIN",
        user: JSON.stringify(state.user),
      },
      "*"
    );

    // store.dispatch(setLoggedUserFromQueryParam(e.data.user));
  }
});

window.addEventListener("message", (e) => {
  if (e.data.type === "USER_FROM_EXTENSION") {
    store.dispatch(setLoggedUserFromQueryParam(e.data.user));
  }
});

window.addEventListener("storage", (e) => {
  if (e.key === "edc_user" && e.oldValue && !e.newValue) {
    // console.log("reload user");
    window.location.reload();
  }
});

window.addEventListener("visibilitychange", () => {
  if (document.visibilityState === "visible") {
    const currentState = store.getState();
    if (currentState.user.id) {
      console.log("visibilityChange");
      store.dispatch(syncData());
      const storageState = storage.get("edc_state");
      const storageLastAction = storageState?.user?.last_action;

      if (isBefore(currentState.user.last_action, storageLastAction)) {
        store.dispatch({ type: "RESET_APP", state: storageState });
      }
    }
    if (
      !currentState.user.isPremium &&
      currentState.variables.theme === "dark"
    ) {
      store.dispatch(changeTheme());
    }
  }
});
