import React from "react";
import messages from "utils/messages";
import { Helmet } from "react-helmet";
import { sendEvent } from "utils/events";

export default function WebExtensions() {
  function onInstallClick(browser) {
    sendEvent("action:webExtensionIntall");
  }

  return (
    <div className="webextensions-page">
      <Helmet>
        <link
          rel="chrome-webstore-item"
          href="https://chrome.google.com/webstore/detail/kpcjjapfjdgjlmoehhliomplgjnbdcpc"
        />
      </Helmet>
      <h2>{messages.WEBEXTENSIONS_TITLE}</h2>
      <p>{messages.WEBEXTENSIONS_TEXT}</p>
      <p>
        {messages.WEBEXTENSIONS_TEXT_2}
        <a
          className="link"
          href="https://youtu.be/OMbsGBlpP30?t=4m5s"
          target="_blank"
          rel="noreferrer"
        >
          {messages.WEBEXTENSIONS_TEXT_3}
        </a>
        {messages.WEBEXTENSIONS_TEXT_4}
      </p>
      <div className="browsers">
        <div className="left">
          <a>{messages.WEBEXTENSIONS_FOR_CHROME}</a>
          <a
            className="button"
            onClick={onInstallClick.bind(null, "chrome")}
            href="https://chrome.google.com/webstore/detail/everydaycheck-do-it-every/kpcjjapfjdgjlmoehhliomplgjnbdcpc" // eslint-disable-line
            target="_blank"
            rel="noreferrer"
          >
            {messages.WEBEXTENSIONS_CHROME}
          </a>
          <div className="faq">
            <a
              href="https://everyday.app/faq#chrome-webextension-install"
              target="_blank"
              rel="noreferrer"
            >
              {messages.WEBEXTENSIONS_FAQ_CHROME_INSTALL}
            </a>
            <a
              href="https://everyday.app/faq#chrome-webextension-uninstall"
              target="_blank"
              rel="noreferrer"
            >
              {messages.WEBEXTENSIONS_FAQ_CHROME_UNINSTALL}
            </a>
          </div>
        </div>
        <div className="right">
          <a>{messages.WEBEXTENSIONS_FOR_FIREFOX}</a>
          <a
            className="button"
            onClick={onInstallClick.bind(null, "firefox")}
            href="https://addons.mozilla.org/en-US/firefox/addon/everydaycheck-do-it-every-day/?src=ss" // eslint-disable-line
            target="_blank"
            rel="noreferrer"
          >
            {messages.WEBEXTENSIONS_FIREFOX}
          </a>
          <div className="faq">
            <a
              href="https://everyday.app/faq#firefox-webextension-install"
              target="_blank"
              rel="noreferrer"
            >
              {messages.WEBEXTENSIONS_FAQ_FIREFOX_INSTALL}
            </a>
            <a
              href="https://everyday.app/faq#firefox-webextension-uninstall"
              target="_blank"
              rel="noreferrer"
            >
              {messages.WEBEXTENSIONS_FAQ_FIREFOX_UNINSTALL}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
