import { useSelector } from "react-redux";
import { useLocation } from "react-router";

export function useHabits() {
  const location = useLocation();

  return useSelector((state) => {
    const isArchive = location.pathname === "/archive";
    if (isArchive) {
      return state.archive.habits.map((habit) => state.habitsEntities[habit]);
    } else {
      return state.habits.habits.map((habit) => state.habitsEntities[habit]);
    }
  });
}
