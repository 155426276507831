import { isSafari } from "utils/browser";
import { isSafariPopupExtension } from "utils/extension";

export default function GoogleButton() {
  const scopes =
    "https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile";
  const redirectUri = "https://api.everyday.app/signInWithGoogleWeb";
  const clientId =
    "924527963873-4hdkc4e7s76gn07o7l7o0ud29ng6em36.apps.googleusercontent.com";
  const googleLink = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}`;

  function signInWithGoogle() {
    window.open(googleLink, "_blank", "width=500,height=600"); // Open in a new window
  }

  if (isSafariPopupExtension()) return null;

  return (
    <div className="google-button" onClick={signInWithGoogle}>
      <span className="google-logo"></span>
      <span className="google-sign-in-text">Sign in with Google</span>
    </div>
  );
}
