export default function modal(state = {}, action) {
  switch (action.type) {
    case "SHOW_BANNER":
      return {
        message: action.message
      };
    case "LOGOUT":
    case "HIDE_BANNER":
      return {};
    default:
      return state;
  }
}
