import React from "react";
import * as Components from "components";

export default class Board extends React.Component {
  render() {
    const { habits, currentDay, numDaysView, offset, statsType } = this.props;
    return (
      <div className="board not-select">
        <table className="board-table">
          <tbody>
            {habits.map((habit, i) => (
              <Components.Row
                habitId={habit.id}
                currentDay={currentDay}
                numDaysView={numDaysView}
                offset={offset}
                count={i}
              />
            ))}
          </tbody>
        </table>
        <Components.Stats habits={habits} statsType={statsType} />
      </div>
    );
  }
}
